import * as actionConstants from "./ActionConstants";

const uploadsActions = {

    getUploads() {
        return {
            type: actionConstants.START_LOAD_UPLOADS, payload: {}
        }
    },

    getUpload(id) {
        return {
            type: actionConstants.START_LOAD_UPLOAD, payload: {
                id: id
            }
        }
    },

    createUpload(file, fileId, questionId, entityId) {
        return {
            type: actionConstants.START_CREATE_UPLOAD, payload: {
                file: file,
                fileId: fileId,
                questionId: questionId,
                entityId: entityId
            }
        }
    }
}

export default uploadsActions
