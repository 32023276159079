import * as actionConstants from "../actions/ActionConstants";
import {call, takeEvery, put} from "@redux-saga/core/effects";
import {
    getBranchApi,
    getBranchesApi,
    getBranchesForSuperUserApi
} from "../network/api/BranchesApi";
import * as roleConstants from "../constants/RoleConstants";
import {getMyOrganizationApi} from "../network/api/OrganizationsApi";
import {checkLoggedUser} from "./helper";

function* getBranches() {
    try {
        const loggedUser = yield call(checkLoggedUser)

        let responseBranches
        if (loggedUser.role === roleConstants.SUPER_USER)
            responseBranches = yield call(getBranchesForSuperUserApi)
        else
            responseBranches = yield call(getBranchesApi)

        const branches = responseBranches.data

        const organizationResponse = yield call(getMyOrganizationApi)
        const organization = organizationResponse.data

        const result = branches.map(item => { return {
            id: item.id,
            createdAt: new Date(item.created_at),
            displayName: item.display_name,
            organizationId: item.entity_id,
            organization: organization,
            isDeleted: item.is_deleted,
            timezone: item.timezone,
            updatedAt: new Date(item.updated_at),
        }})

        yield put({
            type: actionConstants.SUCCESS_LOAD_BRANCHES, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_BRANCHES, payload: {
                error: error
            }
        })
    }
}

function* getBranch(action) {
    const { id } = action.payload
    try {
        const responseBranch = yield call(getBranchApi, id)
        const branch = responseBranch.data

        const organizationResponse = yield call(getMyOrganizationApi)
        const organization = organizationResponse.data

        const result = {
            id: branch.id,
            createdAt: new Date(branch.created_at),
            displayName: branch.displayName,
            organizationId: branch.entity_id,
            organization: organization,
            isDeleted: branch.is_deleted,
            timezone: branch.timezone,
            updatedAt: new Date(branch.updated_at)
        }

        yield put({
            type: actionConstants.SUCCESS_LOAD_BRANCH, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_BRANCH, payload: {
                error: error
            }
        })
    }
}

export default function* branchesSaga() {
    yield takeEvery(actionConstants.START_LOAD_BRANCHES, getBranches)
    yield takeEvery(actionConstants.START_LOAD_BRANCH, getBranch)
}
