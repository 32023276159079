import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class Answer extends React.Component {
    constructor(props) {
        super(props);

        this.typesField = {
            text: 'text',
            true: 'true',
            createTask: 'create_task',
            nameTask: 'name_task'
        }

        this.state = {
            isVariantAnswerError: false,
            isNameTaskError: false,
            text: this.props.answer.text,
            nameTask: this.props.answer.nameTask
        }

        this.variantAnswerRef = React.createRef()
        this.nameTaskRef = React.createRef()

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dropdownState.check !== this.props.dropdownState.check)
            this.checkFields()
    }

    render() {

        const {answer, questionIndex, answerIndex} = this.props;

        return (
            <div className="row">
                <div className="col">
                    <div className="row answer-block row-input-inspection" style={{marginBottom: 16}}>
                        <div className="col-2">
                            <div className="row h-100 align-items-center justify-content-end">
                                <div className="col-auto">
                                    <span className="label-inspection">{this.getLocale().inspection.user_form.variant_answer}*</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                                <textarea
                                    ref={this.variantAnswerRef}
                                    placeholder={this.getLocale().inspection.user_form.input_name_inspection}
                                    className={ 'form-control ' + (this.state.isVariantAnswerError ? 'input-error' : 'input-inspection')}
                                    defaultValue={this.state.text}
                                    onChange={(e) => {
                                        this.setState({isVariantAnswerError: !e.target.value})}
                                    }
                                    onInput={e =>
                                        this.updateField(e.target.value, this.typesField.text, questionIndex, answerIndex, answer)}
                                    maxLength={100} />
                        </div>
                        <div className="col-2">
                            <div className="row no-gutters select-one">
                                <div className="col-auto">
                                    <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                        <input type="radio" checked={answer.isTrue}
                                               readOnly
                                               onClick={() =>
                                                   this.updateField('', this.typesField.true, questionIndex, answerIndex, answer)}
                                        />
                                    </div>
                                </div>
                                <div className="col-auto select-text">
                                    <div className="row h-100 no-gutters align-items-center">
                                        <span className={answer.isTrue ? "text-select-checked" : "text-select"}>{this.getLocale().common.select_man}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters select-one-red">
                                <div className="col-auto">
                                    <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                        <input type="radio" checked={!answer.isTrue} readOnly onClick={() =>
                                            this.updateField('', this.typesField.true, questionIndex, answerIndex, answer)} />
                                    </div>
                                </div>
                                <div className="col-auto select-text">
                                    <div className="row h-100 no-gutters align-items-center">
                                        <span className={!answer.isTrue ? "text-select-checked" : "text-select"}>{this.getLocale().common.not_select_man}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-input-inspection">
                        <div className="col-2" />
                        <div className="col-7">
                            <div className="row no-gutters select-multi">
                                {
                                    !answer.isTrue
                                        ? <><div className="col-auto">
                                            <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                                <input type="radio"
                                                       checked={!answer.isTrue && answer.isCreateTask}
                                                       readOnly
                                                       onClick={() =>
                                                           this.updateField('', this.typesField.createTask, questionIndex, answerIndex, answer)} />
                                            </div>
                                        </div>
                                            <div className="col-auto select-text">
                                                <div className="row h-100 no-gutters align-items-center">
                                                    <span className={!answer.isTrue && answer.isCreateTask ? "text-select-checked" : "text-select"}>{this.getLocale().common.create_task_for_wrong_answer}</span>
                                                </div>
                                            </div></>
                                        : null
                                }
                                <div className="col">
                                    <div className="row no-gutters justify-content-end">
                                        <div className="col-auto">
                                            <button type="button" className="button-border-alert"
                                                    onClick={() => this.deleteAnswer(answer)}>
                                                {this.getLocale().common.delete_answer}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !answer.isTrue && answer.isCreateTask
                            ? <div className="row align-items-center row-input-inspection">
                                <div className="col-2">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.name_task}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <input
                                        ref={this.nameTaskRef}
                                        type="text"
                                        placeholder={this.getLocale().inspection.user_form.input_name_inspection}
                                        defaultValue={this.state.nameTask}
                                        className={ 'form-control ' + (this.state.isNameTaskError ? 'input-error' : 'input-inspection')}
                                        onChange={(e) => {
                                            this.setState({isNameTaskError: !e.target.value})}
                                        }
                                        onInput={e =>
                                            this.updateField(e.target.value, this.typesField.nameTask, questionIndex, answerIndex, answer)}
                                        maxLength={100} />
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    updateField = (value, type, questionIndex, answerIndex, answer) => {
        if (type === this.typesField.text) {
            answer.text = value
            this.setState({
                text: value
            })
        }

        if (type === this.typesField.true) {
            answer.isTrue = !answer.isTrue
            answer.isCreateTask = !answer.isTrue
        }

        if (type === this.typesField.createTask)
            answer.isCreateTask = !answer.isCreateTask

        if (type === this.typesField.nameTask) {
            answer.nameTask = value
            this.setState({
                nameTask: value
            })
        }

        this.props.updateAnswer(questionIndex, answerIndex, answer)
    }

    checkFields() {
        if (!this.variantAnswerRef.current.value)
            this.setState({
                isVariantAnswerError: true
            })

        if (!this.nameTaskRef.current?.value)
            this.setState({
                isNameTaskError: true
            })
    }

    deleteAnswer(answer) {
        this.props.deleteAnswer(answer, this.props.questionIndex)
    }
}

Answer.propTypes = {
    answer: PropTypes.object.isRequired,
    questionIndex: PropTypes.number.isRequired,
    answerIndex: PropTypes.number.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired
}

export default connect(
    state => ({
        appState: state.app,
        dropdownState: state.dropdown
    })
)(Answer);