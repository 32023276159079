import {spawn} from "@redux-saga/core/effects";
import InspectionsSaga from "./InspectionsSaga"
import TasksSaga from "./TasksSaga"
import UsersSaga from "./UsersSaga"
import UploadsSaga from "./UploadsSaga"
import BranchesSaga from "./BranchesSaga"

export function* rootSaga() {
    yield spawn(InspectionsSaga)
    yield spawn(TasksSaga)
    yield spawn(UsersSaga)
    yield spawn(UploadsSaga)
    yield spawn(BranchesSaga)
}