import React from 'react'
import {connect} from 'react-redux'

import * as actionConstants from "../../actions/ActionConstants";
import CustomFieldsActions from "../../actions/CustomFieldsActions";
import InspectionsActions from "../../actions/InspectionsActions";
import * as roleConstants from "../../constants/RoleConstants";
import FieldDropDown from "../commons/dropdown/FieldDropDown";
import BranchesActions from "../../actions/BranchesActions";
import UsersActions from "../../actions/UsersActions";
import TemplatesActions from "../../actions/TemplatesActions";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";
import * as screenConstants from "../../constants/ScreenConstants";
import DateTimeComponent from "../commons/DateTimeComponent";

class InspectionCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: new Date(),
            currentCustomField: null,

            filterSelect: {
                inspector: {id: -1, displayName: this.getLocale().inspection.user_form.input_inspector},
                isInspectorError: false,
                organisation: {id: -1, displayName: this.getLocale().inspection.user_form.input_org},
                isOrgError: false,
                branch: {id: -1, displayName: this.getLocale().inspection.user_form.input_branch},
                isBranchError: false,
                role: {id: -1, displayName: this.getLocale().users.user_form.input_role},
                isRoleError: false,
                template: {id: -1, displayName: this.getLocale().inspection.user_form.input_template},
                isTemplateError: false,
                customFieldItem: { id: -1, displayName: this.getLocale().inspection.user_form.input_custom_field_item },
                isCustomFieldItemError: false,
            }
        }

        this.isSave = false

        this.props.onGetBranches()
        this.props.onGetUsers()
        this.props.onGetTemplates().then()
    }

    render() {
        return (
            <section className="col white-section">
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-auto" style={{marginLeft: '120px', marginBottom: '30px', marginTop: '20px'}}>
                            <span className="title-text">{this.getLocale().inspections.create_inspection}</span>
                        </div>
                    </div>
                    <div className="form-group row no-gutters" style={{marginLeft: '120px'}}>
                        <div className="col">
                            <div className="row no-gutters">
                                <div className="col-2" />
                                <div className="col-7" style={{marginBottom: '20px'}}>
                                    <span className="title-add-inspection-form" style={{marginBottom: 'min(20px)'}}>{this.getLocale().inspection.verification_form}</span>
                                </div>
                            </div>
                            {
                                this.props.loggedUser?.role === roleConstants.SUPER_USER
                                    ? <div className="row align-items-center row-input-inspection">
                                        <div className="col-2">
                                            <div className="row justify-content-end">
                                                <div className="col-auto">
                                                    <span className="label-inspection">{this.getLocale().inspection.user_form.org}*</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <FieldDropDown type={FieldDropDown.typesFilter.organisation}
                                                           filterSelect={this.state.filterSelect}
                                                           updateFilterSelect={this.updateFilterSelect}/>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="row align-items-center row-input-inspection">
                                <div className="col-2">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.branch}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <FieldDropDown type={FieldDropDown.typesFilter.branch}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}/>
                                </div>
                            </div>
                            <div className="row align-items-center row-input-inspection">
                                <div className="col-2">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.inspector}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <FieldDropDown type={FieldDropDown.typesFilter.inspector}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}/>
                                </div>
                            </div>
                            <div className="row align-items-center row-input-inspection">
                                <div className="col-2">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.date}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 date-picker">
                                    <DateTimeComponent val={this.state.startDate}
                                                       setVal={(date) => {this.setState({startDate: date})}} />
                                </div>
                                {/*<div className="col-3">
                                            <div className="row justify-content-end no-gutters">
                                                <div className="col-auto" style={{marginRight: '15px'}}>
                                                    <span className="download-checklist">{this.getLocale().inspection.load_check_list}</span>
                                                </div>
                                                <div className="col-auto">
                                                    {IconsInspections.getIcnDownloadChecklist()}
                                                </div>
                                            </div>
                                        </div>*/}
                            </div>
                            <div className="row no-gutters">
                                <div className="col-2" />
                                <div className="col-7" style={{marginBottom: '20px'}}>
                                    <span className="title-add-inspection-form" style={{marginBottom: 'min(20px)'}}>{this.getLocale().inspection.check_list_inspection}</span>
                                </div>
                            </div>
                            <div className="row align-items-center row-input-inspection">
                                <div className="col-2">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.template}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <FieldDropDown type={FieldDropDown.typesFilter.template}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}/>
                                </div>
                            </div>
                            {this.state.currentCustomField && this.props.customFieldsState.elementsCustomField.length > 0 && (
                                <div className="row align-items-center row-input-inspection">
                                    <div className="col-2">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <span className="label-inspection">{this.state.currentCustomField?.displayName}*</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-7">
                                        <FieldDropDown
                                          type={FieldDropDown.typesFilter.customFieldItem}
                                          filterSelect={this.state.filterSelect}
                                          updateFilterSelect={this.updateFilterSelect}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-2" />
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col">
                                            <div className="row justify-content-end no-gutters" style={{marginTop: '50px'}}>
                                                <div className="col-auto button-offset-form-new">
                                                    <button type="button" className="button-border-common" onClick={this.backInspections}>
                                                        {this.getLocale().common.cancel}
                                                    </button>
                                                </div>
                                                <div className="col-auto button-offset-form-new">
                                                    <button type="submit" className="button-common">
                                                        {this.getLocale().common.save}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {
                    this.props.inspectionsState.error
                        ? <DialogError isShow={true}
                                       type={errorConstants.ERROR_COMPONENT}/>
                        : null
                }
            </section>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    updateFilterSelect = async (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })

        if (filterSelect.template.currentFormVersion?.formVersionCustomFields?.[0]?.customField?.id) {
            this.setState({
                currentCustomField: filterSelect.template.currentFormVersion.formVersionCustomFields[0].customField
            });

            this.props.onGetElementsCustomField(filterSelect.template.currentFormVersion.formVersionCustomFields[0].customField.id)
        } else {
            this.setState({
                currentCustomField: null,
            });
        }

        if (
            this.isSave
            && filterSelect !== null
            && !filterSelect.isOrgError
            && !filterSelect.isBranchError
            && !filterSelect.isInspectorError
            && !filterSelect.isTemplateError
        ) {
            if (this.state.currentCustomField && filterSelect.isCustomFieldItemError) {
                return;
            }

            this.isSave = false

            const formVersionCustomFieldId = filterSelect.template.currentFormVersion.formVersionCustomFields?.[0]?.id ?? null;
            const customFieldItemId = filterSelect?.customFieldItem?.id ?? null;

            await this.saveInspections(
                filterSelect.branch.id,
                customFieldItemId,
                filterSelect.template.displayName,
                filterSelect.template.organizationId,
                this.state.startDate,
                formVersionCustomFieldId,
                filterSelect.template.id,
                filterSelect.inspector.id,
            )
        } else {
            this.isSave = false
        }
    }

    backInspections() {
        window.location.replace(screenConstants.INSPECTIONS)
    }

    checkField() {
        this.props.onCheckFilterSelect()
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.checkField()
        this.isSave = true
    }

    saveInspections = async (
      branchId,
      customFieldItemId,
      displayName,
      entityId,
      executeAt,
      formVersionCustomFieldId,
      templateId,
      userId,
    ) => {
        await this.props.onCreateInspection(
          branchId,
          customFieldItemId,
          displayName,
          entityId,
          executeAt,
          formVersionCustomFieldId,
          templateId,
          userId,
        )
        window.location.replace(screenConstants.INSPECTIONS)
    }
}

export default connect(
    state => ({
        appState: state.app,
        inspectionsState: state.inspections,
        customFieldsState: state.customFields,
    }),
    dispatch => ({
        onGetBranches: () => {
            dispatch(BranchesActions.getBranches())
        },
        onGetUsers: () => {
            dispatch(UsersActions.getUsers())
        },
        onGetTemplates: async () => {
            await TemplatesActions.getTemplates(dispatch)
        },
        onCreateInspection: async (
          branchId,
          customFieldItemId,
          displayName,
          entityId,
          executeAt,
          formVersionCustomFieldId,
          templateId,
          userId,
        ) => {
            await InspectionsActions.createInspection(
              dispatch,
              branchId,
              customFieldItemId,
              displayName,
              entityId,
              executeAt,
              formVersionCustomFieldId,
              templateId,
              userId,
            )
        },
        onCheckFilterSelect: () => {
            dispatch({
                type: actionConstants.CHECK_FIELD_FILTER_SELECT, payload: {}
            })
        },
        onGetElementsCustomField: async (customFieldId) => {
            await CustomFieldsActions.getElementsCustomFields(dispatch, customFieldId)
        }
    })
)(InspectionCreate);
