import IconsCommon from '../../assets/icons/IconsCommon'

import PropTypes from 'prop-types'

import React from 'react'
import {connect} from 'react-redux'

import DialogDelete from "../commons/dialog/DialogDelete";
import DialogUserEdit from "../users/DialogUserEdit";

import TableDropDown from "../commons/dropdown/TableDropDown"
import * as roleConstants from "../../constants/RoleConstants";
import {minTwoDigits} from "../utils/TableUtil";
import UsersActions from "../../actions/UsersActions";
import BranchesActions from "../../actions/BranchesActions";
import FieldDropDown from "../commons/dropdown/FieldDropDown";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 5,
            isSortId: false,
            isSortName: false,
            isSearchNameShow: true,
            showUserEditDialog: {
                isShowUserEditDialog: false,
                isEdit: false,
                user: null
            },
            showDeleteDialog: {
                isShowDeleteDialog: false,
                userId: -1
            },

            filterSelect: {
                user: { id: -1, displayName: this.getLocale().common.change_user},
                role: TableDropDown.emptyObject,
                organisation: TableDropDown.emptyObject,
                branch: TableDropDown.emptyObject
            },

            users: null,
        }

        this.props.onGetUsers()
        this.props.onGetBranches()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.usersState.users !== prevProps.usersState.users) {
            this.setState({
                users: this.props.usersState.users
            })
        }
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <span className="title-text">{this.getLocale().users.title}</span>
                        </div>
                    </div>
                    <div className="row no-gutters search-block">
                        <div className="col-7">
                            <input
                                type="text"
                                placeholder={this.getLocale().users.search_users}
                                className="form-control users-search"
                                required={true}
                                onChange={this.searchNameUser}
                                maxLength={100} />
                        </div>
                        <div className="col-5">
                            <button type="button" className="button-common-users" onClick={() => this.showDialogUserEdit(false)}>
                                {this.getLocale().users.add_user}
                            </button>
                        </div>
                    </div>
                    {
                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                            ?   <div className="row no-gutters search-block">
                                    <div className="col-2">
                                        <FieldDropDown type={FieldDropDown.typesFilter.user}
                                                       filterSelect={this.state.filterSelect}
                                                       updateFilterSelect={this.updateFilterSelect}/>
                                    </div>
                                </div>
                            : null
                    }
                    <div className="row no-gutters">
                        <div className="col table-block">
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">ID</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">{this.getLocale().users.table_header.name}</span>
                                            </div>
                                            <div className="col-auto" style={{marginLeft: '10px'}}>
                                                <div className="row no-gutters">
                                                    <span className="class-sort-name">{this.getLocale().users.table_header.start_name}</span>
                                                </div>
                                                <div className="row no-gutters">
                                                    <span className="class-sort-name">{this.getLocale().users.table_header.end_name}</span>
                                                </div>
                                            </div>
                                            <div className={ 'col-auto item-user-sort-table' + (this.state.isSortName ? ' item-sort-table-open' : '') }
                                                 onClick={this.sortOnName}>
                                                {IconsCommon.getIcnHeaderArrow()}
                                            </div>
                                        </div>
                                    </th>
                                    <TableDropDown type={TableDropDown.typesFilter.role}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData} />
                                    {
                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                            ? <TableDropDown type={TableDropDown.typesFilter.organisation}
                                                             filterSelect={this.state.filterSelect}
                                                             updateFilterSelect={this.updateFilterSelect}
                                                             updateData={this.updateData} />
                                            : null
                                    }
                                    <TableDropDown type={TableDropDown.typesFilter.branch}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData} />
                                    <th scope="col"><span className="table-header-title">{this.getLocale().users.table_header.phone}</span></th>
                                    <th scope="col"><span className="table-header-title">{this.getLocale().users.table_header.last_activity}</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.users?.map(item => {
                                            return (
                                                <tr className="table-row" key={item.id}>
                                                    <td><span className="table-ceil">{minTwoDigits(item.id)}</span></td>
                                                    <td>
                                                        <div className="row no-gutters">
                                                            {
                                                                item.upload
                                                                    ? <div className="col-auto">
                                                                        <img className="user-avatar" src={item.upload} alt='' />
                                                                     </div>
                                                                    : null
                                                            }
                                                            <div className="col-auto">
                                                                <span className="table-ceil-name-non-pointer">{item.displayName}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><span className="table-ceil">{roleConstants.getRoleForApp(item.role, this.getLocale()).displayName}</span></td>
                                                    {
                                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                                            ? <td><span className="table-ceil">{item.organization?.display_name}</span></td>
                                                            : null
                                                    }
                                                    <td><span className="table-ceil">{item.branch?.display_name}</span></td>
                                                    <td><span className="table-ceil">{item.phone}</span></td>
                                                    <td><span className="table-ceil">{item.updatedAt.toLocaleString()}</span></td>
                                                    <td>
                                                        <div className="table-icn-delete">
                                                            <div className="row no-gutters">
                                                                <div className="col-auto" onClick={() => this.showDialogUserEdit(true, item)}>
                                                                    {IconsCommon.getIcnEdit()}
                                                                </div>
                                                                <div className="col-auto" style={{marginLeft: '16px'}} onClick={() => this.showDialogDelete(item.id)}>
                                                                    {IconsCommon.getIcnDelete()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row no-gutters justify-content-center load-yet-block">
                        {
                            /*this.props.tasksState.isLoadYet
                            && this.props.tasksState.tasks.length >= commonConstants.TASKS_LIMIT_LOAD
                                ? <div className="col-auto" onClick={() => {
                                    this.loadYetTasks()
                                }}>
                                    <span className="clear-date">{this.getLocale().common.load_yet}</span>
                                </div>
                                : null*/
                        }
                    </div>
                    {
                        this.props.usersState.error
                            ? <DialogError isShow={true}
                                           type={errorConstants.ERROR_COMPONENT}/>
                            : null
                    }
                    {
                        this.state.showDeleteDialog.isShowDeleteDialog
                            ? <DialogDelete isShow={true} onHide={this.hideDialogDelete} clickYes={this.deleteUser} />
                            : null
                    }
                    {
                        this.state.showUserEditDialog.isShowUserEditDialog
                            ? <DialogUserEdit edit={this.state.showUserEditDialog.isEdit}
                                              user={this.state.showUserEditDialog.user}
                                              role={this.props.loggedUser?.role}
                                              isShow={true}
                                              onHide={this.hideDialogUserEdit} clickSave={this.saveUser} />
                            : null
                    }
                </section>
        )
    }

    updateFilterSelect = (filterSelect) => {
        if (this.props.loggedUser?.role === roleConstants.SUPER_USER
            && filterSelect.user.id !== -1) {
            this.props.onGetTokenAnotherUser(filterSelect.user.id).then()
        }

        this.setState({
            filterSelect: filterSelect
        })
    }

    showDialogDelete = (userId) => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = true
        newShowDeleteDialog.userId = userId
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    hideDialogDelete = () => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = false
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    showDialogUserEdit = (edit, user) => {
        let newShowUserEditDialog = this.state.showUserEditDialog
        newShowUserEditDialog.isShowUserEditDialog = true
        newShowUserEditDialog.isEdit = edit
        newShowUserEditDialog.user = user
        this.setState({
            showUserEditDialog: newShowUserEditDialog
        })
    }

    hideDialogUserEdit = () => {
        let newShowUserEditDialog = this.state.showUserEditDialog
        newShowUserEditDialog.isShowUserEditDialog = false
        this.setState({
            showUserEditDialog: newShowUserEditDialog
        })
    }

    saveUser = (user, name, family, phone, role, entityId, branchId, uploadId) => {
        if (user) {
            this.props.onUpdateUser(user, name, family, phone, role, entityId, branchId, uploadId)
        } else {
            this.props.onCreateUser(name, family, phone, role, entityId, branchId, uploadId)
        }
    }

    deleteUser = () => {
        this.props.onDeleteUser(this.state.showDeleteDialog.userId)
    }

    getLocale() {
        return this.props.appState.locale
    }

    sortOnName = () => {
        let sortArray
        if (this.state.isSortName) {
            sortArray = this.props.usersState.users.slice().sort((a, b) =>  {
                if (a.displayName > b.displayName)
                    return 1
                if (a.displayName < b.displayName)
                    return -1;
                return 0
            } )
        } else {
            sortArray = this.props.usersState.users.slice().sort((a, b) => {
                if (a.displayName > b.displayName)
                    return -1
                if (a.displayName < b.displayName)
                    return 1;
                return 0
            } )
        }
        this.setState({
            isSortName: !this.state.isSortName,
            users: sortArray
        })
    }

    searchNameUser = (e) => {
        const text = e.target.value
        let filterArray
        if (text)
            filterArray = this.props.usersState.users.slice().filter(value => {
                return value.displayName.match(new RegExp(text, 'i'))
            })
        else
            filterArray = this.props.usersState.users.slice()

        this.setState({
            users: filterArray
        })
    }

    updateData = () => {
        let newFiltered = this.props.usersState.users.slice()
        if (this.state.filterSelect.role.id !== -1) {
            newFiltered = newFiltered.filter(value => roleConstants.getRoleForApp(value.role, this.getLocale()).id === this.state.filterSelect.role.id)
        }
        if (this.state.filterSelect.organisation.id !== -1) {
            newFiltered = newFiltered.filter(value => value.organisation?.id === this.state.filterSelect.organisation.id)
        }
        if (this.state.filterSelect.branch.id !== -1) {
            newFiltered = newFiltered.filter(value => value.branch?.id === this.state.filterSelect.branch.id)
        }

        this.setState({
            users: newFiltered
        })
    }
}

Users.propTypes = {
    page: PropTypes.number,   // Page number
}

export default connect(
    state => ({
        usersState: state.users,
        loggedUser: state.users.loggedUser,
        appState: state.app
    }),
    dispatch => ({
        onGetUsers: () => {
            dispatch(UsersActions.getUsers())
        },
        onGetBranches: () => {
            dispatch(BranchesActions.getBranches())
        },
        onCreateUser: (name, family, phone, role, entityId, branchId, uploadId) => {
            dispatch(UsersActions.createUser(name, family, phone, role, entityId, branchId, uploadId))
        },
        onUpdateUser: (user, name, family, phone, role, entityId, branchId, uploadId) => {
            dispatch(UsersActions.updateUser(user, name, family, phone, role, entityId, branchId, uploadId))
        },
        onDeleteUser: (id) => {
            dispatch(UsersActions.deleteUser(id))
        },
        onGetTokenAnotherUser: async (id) => {
            await UsersActions.getTokenAnotherUser(dispatch, id)
        }
    })
)(Users);