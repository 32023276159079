import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import * as actionConstants from "../actions/ActionConstants";
import {getBranchesApi} from "../network/api/BranchesApi";
import {getMyOrganizationApi} from "../network/api/OrganizationsApi";
import {getUsersApi} from "../network/api/UsersApi";
import {getTasksApi} from "../network/api/TasksApi";

function* getTasks(action) {
    const {limit, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill} = action.payload
    try {
        const result = yield getTasksFromServer(limit, 0, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill)

        yield put({
            type: actionConstants.SUCCESS_LOAD_TASKS, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_TASKS, payload: {
                error: error
            }
        })
    }
}

function* loadYetTasks(action) {
    const {limit, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill} = action.payload
    const tasksState = yield select(state => state.tasks)
    const offset = tasksState.tasks.length
    try {
        const data = yield getTasksFromServer(limit, offset, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill)
        const result = data.concat(tasksState.tasks)

        yield put({
            type: actionConstants.SUCCESS_LOAD_TASKS, payload: {
                data: result
            }
        })

        if (data.length === 0) {
            yield put({
                type: actionConstants.END_LOAD_YET_TASKS, payload: {}
            })
        }

    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_TASKS, payload: {
                error: error
            }
        })
    }
}

function* getTasksFromServer(limit,
                             offset,
                             filterBranch,
                             filterState,
                             filterExecutor,
                             displayName,
                             createdSince,
                             createdTill,
                             finishedSince,
                             finishedTill) {
    const responseTasks = yield call(getTasksApi, limit, offset, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill)
    const tasks = responseTasks.data

    const responseBranch = yield call(getBranchesApi)
    const branches = responseBranch.data

    const organizationResponse = yield call(getMyOrganizationApi)
    const organization = organizationResponse.data

    const responseUsers = yield call(getUsersApi)
    const users = responseUsers.data

    return tasks.map(item => { return {
        id: item.id,
        select: false,
        displayName: item.display_name,
        branchId: item.branch_id,
        branch: branches.find(branch => branch.id === item.branch_id),
        createdAt: new Date(item.created_at),
        organizationId: item.entity_id,
        organization: organization,
        isDeleted: item.is_deleted,
        state: item.state,
        updatedAt: new Date(item.updated_at),
        userId: item.user_id,
        user: users.find(user => user.id === item.user_id)
    }})
}

export default function* tasksSaga() {
    yield takeEvery(actionConstants.START_LOAD_TASKS, getTasks)
    yield takeEvery(actionConstants.LOAD_YET_TASKS, loadYetTasks)
}