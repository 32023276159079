import client from "../client";

export async function getTemplateApi(id) {
    return client.get(
        '/api/forms/' + id)
}

export async function getTemplatesApi() {
    return client.get(
        '/api/forms')
}

export async function createTemplateApi(nameTemplate) {
    return client.post(
        '/api/forms',
        {
            display_name: nameTemplate
        }
    )
}

export async function updateTemplateApi(id, nameTemplate) {
    return client.put(
        '/api/forms/' + id,
        {
            display_name: nameTemplate
        }
    )
}

export async function updateTemplateCustomFieldsApi(id, custom_fields) {
    return client.put(
        `/api/forms/${id}/custom_fields`,
        {
            custom_fields,
        },
    );
}

export async function updateTemplateSectionsApi(id, sections) {
    return client.put(
        '/api/forms/' + id + '/sections',
        {
            sections: sections
        }
    )
}

export async function activateVersionTemplate(id, formVersionId) {
    return client.patch(
        '/api/forms/' + id + '/activate_version',
        {
            form_version_id: formVersionId
        }
    )
}
