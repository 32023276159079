import React from "react";
import PropTypes from "prop-types";
import TemplatesActions from "../../../actions/TemplatesActions";
import CustomFieldsActions from "../../../actions/CustomFieldsActions";
import FieldDropDown from "../../commons/dropdown/FieldDropDown";
import Question from "./Question";
import IconsCommon from "../../../assets/icons/IconsCommon";
import {arrayMax} from "../../utils/ArrayUtil";
import {connect} from "react-redux";
import * as actionConstants from "../../../actions/ActionConstants";

class TemplateInspectionCreate extends React.Component {
    constructor(props) {
        super(props);

        this.beginAnswer = {
            id: 0,
            text: '',
            isTrue: false,
            isCreateTask: false,
            nameTask: ''
        }

        this.defaultQuestion = {
            id: -1,
            name: '',
            photos: [],
            prompt: '',
            section: {id: -1, displayName: this.getLocale().inspection.user_form.input_section},
            multiSelect: true,
            answers: [],
            isRequiredPhoto: false,
            isRequiredComment: false
        }

        this.state = {
            isNameInspectionError: false,
            nameInspection: '',

            filterSelect: {
                customFields: { id: -1, displayName: this.getLocale().inspection.user_form.input_custom_fields },
                isCustomFieldsError: false,
            },
        }

        this.nameInspectionRef = React.createRef()

        this.props.onGetCustomFields()
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.nameForm !== nextProps.nameForm
            && !this.state.nameForm) {
            this.setState({
                nameInspection: nextProps.nameForm
            })
        }

        return true
    }

    render() {

        const { title, cancel } = this.props;

        return (
            <form onSubmit={this.handleSubmit} style={{marginBottom: '50px'}}>
                <div className="row">
                    <div className="col-auto" style={{marginLeft: '120px', marginBottom: '30px', marginTop: '20px'}}>
                        <span className="title-text">{title}</span>
                    </div>
                </div>
                <div className="form-group row no-gutters" style={{marginLeft: '120px'}}>
                    <div className="col">
                        <div className="row align-items-center row-input-inspection">
                            <div className="col-2">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <span className="label-inspection">{this.getLocale().inspection.user_form.name_inspection}*</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-7">
                                <input
                                    ref={this.nameInspectionRef}
                                    type="text"
                                    value={this.state.nameInspection}
                                    placeholder={this.getLocale().inspection.user_form.input_name_inspection}
                                    className={ 'form-control ' + (this.state.isNameInspectionError ? 'input-error' : 'input-inspection') }
                                    onChange={(e) => {
                                        this.setState({
                                            nameInspection: e.target.value,
                                            isNameInspectionError: !e.target.value
                                        })}
                                    }
                                    maxLength={100} />
                            </div>
                        </div>

                        {this.props.templatesState.template?.id && (
                            <div className="row align-items-center row-input-inspection">
                                <div className="col-2">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.custom_fields}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <FieldDropDown
                                        filterSelect={this.state.filterSelect}
                                        type={FieldDropDown.typesFilter.customFields}
                                        updateFilterSelect={this.updateFilterSelect}
                                    />
                                </div>
                            </div>
                        )}

                        {
                            this.props.templatesState.questions?.map((question, index) => {
                                return <Question key={question.id}
                                                 question={question}
                                                 index={index}
                                                 updateQuestions={this.updateQuestions}
                                                 deleteQuestion={this.deleteQuestion}/>
                            })
                        }
                        {
                            <div className="row align-items-center row-input-inspection">
                                <div className="col-2">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">
                                                {this.getLocale().inspection.user_form.question} № {this.props.templatesState.questions?.length + 1}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col">
                                            <div className="row h-100 align-items-center">
                                                <div className="col-auto">
                                                            <span className="text-role">
                                                                {this.getLocale().inspection.user_form.add_question}
                                                            </span>
                                                </div>
                                                <div className="col-auto" style={{marginRight: '15px'}}>
                                                    <div
                                                        className="row justify-content-center align-items-center add-role-rect"
                                                        onClick={this.addQuestion}>
                                                        {IconsCommon.getIcnAddRole()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-2" />
                            <div className="col-7">
                                <div className="row">
                                    <div className="col">
                                        <div className="row justify-content-end no-gutters" style={{marginTop: '50px'}}>
                                            <div className="col-auto button-offset-form-new">
                                                <button type="button" className="button-border-common" onClick={cancel}>
                                                    {this.getLocale().common.cancel}
                                                </button>
                                            </div>
                                            <div className="col-auto button-offset-form-new">
                                                <button type="submit" className="button-common">
                                                    {this.getLocale().common.save}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    checkFiledQuestion(questions) {
        const notSections = questions.filter(question => question.section.id === -1)
        const notAnswers = questions.filter(question => question.answers.filter(answer => answer.text === '').length !== 0)
        return {isCompleteSections: notSections.length === 0, isCompleteAnswers: notAnswers.length === 0}
    }

    addQuestion = () => {
        let maxId = arrayMax(this.props.templatesState.questions)
        if (maxId || maxId === 0) {
            maxId = maxId + 1
        }

        const question = {...this.defaultQuestion, id: maxId, photos: [], answers: []}
        question.answers.push({...this.beginAnswer})
        const questions = this.props.templatesState.questions.slice()
        questions.push(question)

        this.props.onUpdateQuestions(questions)
    }

    updateQuestions = (index, question) => {
        const newQuestions = this.props.templatesState.questions.slice()
        newQuestions[index] = question

        this.props.onUpdateQuestions(newQuestions)
    }

    deleteQuestion = (removeQuestion) => {
        const newQuestions = this.props.templatesState.questions.slice()

        this.props.onUpdateQuestions(newQuestions.filter(question => question.id !== removeQuestion.id))
    }

    updateFilterSelect = async (filterSelect) => {
        this.setState({
            filterSelect: filterSelect,
        });

        if (filterSelect.customFields.id !== -1) {
            await this.saveCustomFields(this.props.templatesState.template.id, filterSelect.customFields.id);
        }
    }

    saveCustomFields = async (id, customFields) => {
        await this.props.onUpdateCustomFields(id, customFields);
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.checkField()
    }

    checkField() {
        if (!this.nameInspectionRef.current.value)
            this.setState({
                isNameInspectionError: true
            })

        const completeInside = this.checkFiledQuestion(this.props.templatesState.questions)

        if (completeInside.isCompleteSections && completeInside.isCompleteAnswers) {
            this.props.save(
                this.nameInspectionRef.current.value
            )
        }

        this.props.onCheckFilterSelect()
    }
}

TemplateInspectionCreate.propTypes = {
    title: PropTypes.string.isRequired,
    nameForm: PropTypes.string,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired
};

export default connect(
    state => ({
        loggedUser: state.users.loggedUser,
        appState: state.app,
        templatesState: state.templates
    }),
    dispatch => ({
        onUpdateQuestions: (questions) => {
            dispatch({
                type: actionConstants.UPDATE_TEMPLATE_QUESTIONS, payload: {
                    data: questions
                }
            })
        },
        onCheckFilterSelect: () => {
            dispatch({
                type: actionConstants.CHECK_FIELD_FILTER_SELECT, payload: {}
            })
        },
        onGetCustomFields: async () => {
            await CustomFieldsActions.getCustomFields(dispatch)
        },
        onUpdateCustomFields: async (id, customFields) => {
            await TemplatesActions.updateTemplateCustomFields(dispatch, id, customFields);
        },
    })
)(TemplateInspectionCreate);
