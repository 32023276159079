import React from "react";

const iconsInspections = {
    getIcnDownloadChecklist() {
        return  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id=" file-add">
                        <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M14.0002 13H13.0002V12C13.0002 11.448 12.5532 11 12.0002 11C11.4472 11 11.0002 11.448 11.0002 12V13H10.0002C9.44719 13 9.00019 13.448 9.00019 14C9.00019 14.552 9.44719 15 10.0002 15H11.0002V16C11.0002 16.552 11.4472 17 12.0002 17C12.5532 17 13.0002 16.552 13.0002 16V15H14.0002C14.5532 15 15.0002 14.552 15.0002 14C15.0002 13.448 14.5532 13 14.0002 13ZM17.4445 20H6.55549C6.24949 20 6.00049 19.776 6.00049 19.5V4.5C6.00049 4.224 6.24949 4 6.55549 4H12.0005V7.15C12.0005 8.722 13.2175 10 14.7145 10H18.0005V19.5C18.0005 19.776 17.7515 20 17.4445 20ZM14.0005 4.978L16.7425 8H14.7145C14.3205 8 14.0005 7.619 14.0005 7.15V4.978ZM19.7405 8.328L14.2965 2.328C14.1065 2.119 13.8385 2 13.5555 2H6.55549C5.14649 2 4.00049 3.122 4.00049 4.5V19.5C4.00049 20.878 5.14649 22 6.55549 22H17.4445C18.8535 22 20.0005 20.878 20.0005 19.5V9C20.0005 8.751 19.9075 8.512 19.7405 8.328Z" fill="#42AAFF"/>
                    </g>
                </svg>
    },
    getIcnArrowQuestionCollapse(isRotate) {
        return  <svg style={{transform: isRotate ? 'rotate(180deg)' : ''}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 7.5L10 12.5L15 7.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    },
    getIcnQuestionDelete() {
        return  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 14C14 14.55 13.55 15 13 15C12.45 15 12 14.55 12 14V10C12 9.45 12.45 9 13 9C13.55 9 14 9.45 14 10V14ZM8 2.328C8 2.173 8.214 2 8.5 2H11.5C11.786 2 12 2.173 12 2.328V4H8V2.328ZM8 14C8 14.55 7.55 15 7 15C6.45 15 6 14.55 6 14V10C6 9.45 6.45 9 7 9C7.55 9 8 9.45 8 10V14ZM19 4H18H14V2.328C14 1.044 12.879 0 11.5 0H8.5C7.121 0 6 1.044 6 2.328V4H2H1C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6H2V17C2 18.654 3.346 20 5 20H15C16.654 20 18 18.654 18 17V6H19C19.55 6 20 5.55 20 5C20 4.45 19.55 4 19 4Z" fill="currentColor"/>
        </svg>
    },
}

export default iconsInspections