import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    locale: null,
    timeLocale: null,
    dateFnsLocale: null
};

export default function app(state = initialState, action) {
    if (action.type === actionConstants.CHECK_LOCAL) {
        return {
            ...state,
            locale: action.payload.data.locale,
            timeLocale: action.payload.data.timeLocale,
            dateFnsLocale: action.payload.data.dateFnsLocale
        };
    }
    return {...state};
}