import React from "react";

const iconsCommon = {
    getIcnRemove() {
        return  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" width="16" height="16" rx="4" fill="#707683"/>
                    <path d="M11.4667 5.01866L10.7147 4.26666L7.73333 7.248L4.752 4.26666L4 5.01866L6.98133 8L4 10.9813L4.752 11.7333L7.73333 8.752L10.7147 11.7333L11.4667 10.9813L8.48533 8L11.4667 5.01866Z" fill="white"/>
                </svg>
    },
    getIcnHeaderArrow() {
        return  <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.11013 3L0.742258 -9.41288e-08L7.47801 4.35844e-07L4.11013 3Z" fill="#707683"/>
        </svg>
    },
    getIcnSearch() {
        return  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8417 10.4917 13.3333 9.25833 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.25833 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z" fill="#707683"/>
        </svg>
    },
    getIcnPopoverArrow(isColor) {
        if (!isColor)
            return '<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.3359 1.49615C9.12754 0.308683 10.8725 0.308685 11.6641 1.49615L20 14H0L8.3359 1.49615Z" fill="white"/></svg>';
        else
            return '<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.3359 1.49615C9.12754 0.308683 10.8725 0.308685 11.6641 1.49615L20 14H0L8.3359 1.49615Z" fill="#42AAFF"/></svg>'
    },
    getIcnEdit() {
        return  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id=" edit-2" opacity="0.7">
                        <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M17.9654 6.72867L15.2714 4.03467L13.3234 5.98367L16.0184 8.67867L17.9654 6.72867ZM5.09038 17.9957C5.06038 17.9987 5.03038 17.9997 5.00038 17.9997C4.73638 17.9997 4.48138 17.8957 4.29238 17.7067C4.08338 17.4967 3.97738 17.2047 4.00338 16.9097L4.38238 12.7397C4.42438 12.2827 4.62638 11.8507 4.95238 11.5257L13.9484 2.52967C14.6504 1.82467 15.9234 1.85967 16.6644 2.59867L19.4024 5.33667L19.4034 5.33767C20.1684 6.10367 20.1994 7.32167 19.4714 8.05167L10.4744 17.0487C10.1494 17.3737 9.71838 17.5757 9.26038 17.6177L5.09038 17.9957ZM4.99988 20H18.9999C19.5499 20 19.9999 20.45 19.9999 21C19.9999 21.55 19.5499 22 18.9999 22H4.99988C4.44988 22 3.99988 21.55 3.99988 21C3.99988 20.45 4.44988 20 4.99988 20Z" fill="#707683"/>
                    </g>
                </svg>
    },
    getIcnDelete() {
        return  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14 14C14 14.55 13.55 15 13 15C12.45 15 12 14.55 12 14V10C12 9.45 12.45 9 13 9C13.55 9 14 9.45 14 10V14ZM8 2.328C8 2.173 8.214 2 8.5 2H11.5C11.786 2 12 2.173 12 2.328V4H8V2.328ZM8 14C8 14.55 7.55 15 7 15C6.45 15 6 14.55 6 14V10C6 9.45 6.45 9 7 9C7.55 9 8 9.45 8 10V14ZM19 4H18H14V2.328C14 1.044 12.879 0 11.5 0H8.5C7.121 0 6 1.044 6 2.328V4H2H1C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6H2V17C2 18.654 3.346 20 5 20H15C16.654 20 18 18.654 18 17V6H19C19.55 6 20 5.55 20 5C20 4.45 19.55 4 19 4Z" fill="#707683"/>
                </svg>
    },
    getIcnCancel() {
        return  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.707 13.293C15.098 13.684 15.098 14.316 14.707 14.707C14.512 14.902 14.256 15 14 15C13.744 15 13.488 14.902 13.293 14.707L12 13.414L10.707 14.707C10.512 14.902 10.256 15 10 15C9.744 15 9.488 14.902 9.293 14.707C8.902 14.316 8.902 13.684 9.293 13.293L10.586 12L9.293 10.707C8.902 10.316 8.902 9.684 9.293 9.293C9.684 8.902 10.316 8.902 10.707 9.293L12 10.586L13.293 9.293C13.684 8.902 14.316 8.902 14.707 9.293C15.098 9.684 15.098 10.316 14.707 10.707L13.414 12L14.707 13.293ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z" fill="#9B9FA8"/>
                </svg>
    },
    getIcnAddRole() {
        return  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="8" width="4" height="20" rx="2" fill="currentColor"/>
                    <rect x="20" y="8" width="4" height="20" rx="2" transform="rotate(90 20 8)" fill="currentColor"/>
                </svg>
    },
    getPaginateArrowLeft() {
        return  <svg width="18" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrrow-left">
                        <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M13.544 18.0008C13.073 18.0008 12.609 17.8438 12.241 17.5398L7.142 13.3258C6.734 12.9898 6.5 12.5058 6.5 11.9998C6.5 11.4938 6.734 11.0098 7.141 10.6738L12.241 6.46076C12.849 5.95676 13.716 5.85576 14.45 6.20076C15.098 6.50676 15.5 7.11376 15.5 7.78676V16.2128C15.5 16.8858 15.098 17.4928 14.45 17.7988C14.161 17.9348 13.851 18.0008 13.544 18.0008Z" fill="currentColor"/>
                    </g>
                </svg>
    },
    getPaginateArrowRight() {
        return  <svg width="18" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrrow-right">
                        <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M10.956 18.0028C10.649 18.0028 10.339 17.9368 10.05 17.8008C9.402 17.4948 9 16.8878 9 16.2148V7.78878C9 7.11578 9.402 6.50878 10.05 6.20278C10.782 5.85778 11.65 5.95878 12.259 6.46178L17.358 10.6758C17.767 11.0118 18 11.4958 18 12.0018C18 12.5078 17.767 12.9918 17.358 13.3278L12.259 17.5408C11.891 17.8458 11.427 18.0028 10.956 18.0028Z" fill="currentColor"/>
                    </g>
                </svg>
    },
    getIcnDeleteImage() {
        return  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="black"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.6667 6.26666L13.7334 5.33333L10 9.06666L6.26671 5.33333L5.33337 6.26666L9.06671 9.99999L5.33337 13.7333L6.26671 14.6667L10 10.9333L13.7334 14.6667L14.6667 13.7333L10.9334 9.99999L14.6667 6.26666Z" fill="white"/>
                </svg>
    }
}

export default iconsCommon