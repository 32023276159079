import CancelReasonsService from "../network/service/CancelReasonsService";

const cancelReasonsActions = {

    async getCancelReasons(dispatch) {
        await CancelReasonsService.getCancelReasons(dispatch)
    },

    async getCancelReason(dispatch, id) {
        await CancelReasonsService.getCancelReason(dispatch, id)
    },

    async createCancelReason(dispatch, name) {
        await CancelReasonsService.createCancelReason(dispatch, name)
        await CancelReasonsService.getCancelReasons(dispatch)
    },

    async deleteCancelReason(dispatch, id) {
        await CancelReasonsService.deleteCancelReason(dispatch, id)
        await CancelReasonsService.getCancelReasons(dispatch)
    }
}

export default cancelReasonsActions
