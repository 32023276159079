import PropTypes from 'prop-types'

import IconsNavigation from '../assets/icons/IconsNavigation'

import {
    NavLink
} from "react-router-dom";

import * as screenConstants from '../constants/ScreenConstants'
import * as roleConstants from '../constants/RoleConstants'

import React from 'react';
import {Nav, Collapse} from "react-bootstrap";
import {connect} from "react-redux";
import AuthService from "../network/service/AuthService";

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenBranch: false,
            activeScreen: screenConstants.INSPECTIONS
        }
    }

    render() {
        return (
            <div className="col-auto col-sidebar">
                <div className="sidebar-block">
                    <Nav className="flex-column">
                        <span className="item-logo">{this.getLocale().title}</span>
                        <div className="row align-items-center no-gutters avatar-nav">
                            <div className="col-auto">
                                <img src={this.props.loggedUser?.avatar} alt=""/>
                            </div>
                            <div className="col" style={{marginLeft: '10px'}}>
                                <div className="row no-gutters avatar-name">
                                    <span>{this.props.loggedUser
                                        ? this.props.loggedUser.name + " " + this.props.loggedUser.family
                                        : ''}</span>
                                </div>
                                <div className="row no-gutters avatar-profession">
                                    <span>{this.props.loggedUser
                                        ? roleConstants.getRoleForApp(this.props.loggedUser.role, this.getLocale()).displayName
                                        : ''}</span>
                                </div>
                            </div>
                            <div className="col-auto"
                                 style={{cursor: 'pointer'}}
                                 onClick={() => AuthService.loggedOut()}>
                                {IconsNavigation.getIcnLogOut()}
                            </div>
                        </div>
                        <ul className="nav navbar-nav" id="navbarNav">
                            {
                                this.props.loggedUser?.role !== roleConstants.SUPER_USER
                                    ? <li className={
                                        this.isActiveClassName(new RegExp("^\\" + screenConstants.INSPECTIONS + "|" + screenConstants.INSPECTION)) ? 'active-nav' : 'nav-item'}>
                                        <div className="row justify-content-center align-items-center no-gutters">
                                            <div className="col">
                                                <NavLink to={screenConstants.INSPECTIONS}
                                                         onClick={() => {this.setState({activeScreen: screenConstants.INSPECTIONS})}}>
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-auto icn-color">
                                                            {IconsNavigation.getIcnInspection()}
                                                        </div>
                                                            <div className="col-auto">
                                                                {this.getLocale().inspections.title}
                                                            </div>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                      </li>
                                    : null
                            }
                            {
                                this.props.loggedUser?.role !== roleConstants.SUPER_USER
                                    ? <li className={
                                        this.isActiveClassName(new RegExp("^\\" + screenConstants.TASKS + "|" + screenConstants.TASK)) ? 'active-nav' : 'nav-item'}>
                                        <div className="row justify-content-center align-items-center no-gutters">
                                            <div className="col">
                                                <NavLink to={screenConstants.TASKS}
                                                         onClick={() => {this.setState({activeScreen: screenConstants.TASKS})}}>
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-auto icn-color">
                                                            {IconsNavigation.getIcnTask()}
                                                        </div>
                                                        <div className="col-auto">
                                                            {this.getLocale().tasks.title}
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                      </li>
                                    : null
                            }
                            {
                                <li className={ this.isActiveClassName(new RegExp("^\\" + screenConstants.USERS)) ? 'active-nav' : 'nav-item'}>
                                    <div className="row justify-content-center align-items-center no-gutters">
                                        <div className="col">
                                            <NavLink to={screenConstants.USERS}
                                                     onClick={() => {this.setState({activeScreen: screenConstants.USERS})}}>
                                                <div className="row align-items-center no-gutters">
                                                    <div className="col-auto icn-color">
                                                        {IconsNavigation.getIcnUser()}
                                                    </div>
                                                    <div className="col-auto">
                                                        {this.getLocale().users.title}
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </li>
                            }
                            {
                                /*<li className={ this.isActiveClassName(new RegExp("^\\" + screenConstants.STATISTICS)) ? 'active-nav' : 'nav-item'}>
                                    <div className="row justify-content-center align-items-center no-gutters">
                                        <div className="col">
                                            <NavLink to={screenConstants.STATISTICS}
                                                     onClick={() => {this.setState({activeScreen: screenConstants.STATISTICS})}}>
                                                <div className="row align-items-center no-gutters">
                                                    <div className="col-auto icn-color">
                                                        {IconsNavigation.getIcnStatistics()}
                                                    </div>
                                                    <div className="col-auto">
                                                        {this.getLocale().statistics.title}
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </li>*/
                            }
                            {
                                <li className={this.state.isOpenBranch ? 'nav-item-dropdowns-active-color' : 'nav-item-dropdowns'}
                                      onClick={ () => this.setState({
                                          isOpenBranch: !this.state.isOpenBranch
                                      })}
                                      aria-controls="branch_nav"
                                      aria-expanded={this.state.isOpenBranch}>
                                    <div className="row justify-content-center align-items-center no-gutters">
                                        <div className="col-auto">
                                            {IconsNavigation.getIcnDirectory(this.state.isOpenBranch)}
                                        </div>
                                        <div className="col">
                                            <span style={{cursor: 'pointer'}}>{this.getLocale().common.directories}</span>
                                        </div>
                                        <div className="col-auto">
                                            {IconsNavigation.getIcnArrowCollapse(this.state.isOpenBranch)}
                                        </div>
                                    </div>
                                </li>
                            }
                            <Collapse
                                in={this.state.isOpenBranch}>
                                <div id="branch_nav">
                                    <ul className="nav navbar-nav" id="navbarNav">
                                        {
                                            this.props.loggedUser?.role !== roleConstants.SUPER_USER
                                                ? <li className={ (this.isActiveClassName(new RegExp("^\\" + screenConstants.CANCEL_REASONS)) ? 'active-nav' : 'nav-item') + ' nav-item-li-dropdowns'}>
                                                    <div className="row justify-content-center align-items-center no-gutters">
                                                        <div className="col-auto">
                                                            <div className="image-nav" style={{width: '20px', height: '20px'}} />
                                                        </div>
                                                        <div className="col">
                                                            <NavLink to={screenConstants.CANCEL_REASONS}
                                                                     onClick={() => {this.setState({activeScreen: screenConstants.CANCEL_REASONS})}}>{this.getLocale().cancel_reasons.title}</NavLink>
                                                        </div>
                                                    </div>
                                                  </li>
                                                : null
                                        }
                                        {
                                            this.props.loggedUser?.role === roleConstants.SUPER_USER
                                                ? <li className={ (this.isActiveClassName(new RegExp("^\\" + screenConstants.ORGANIZATIONS)) ? 'active-nav' : 'nav-item') + ' nav-item-li-dropdowns'}>
                                                    <div className="row justify-content-center align-items-center no-gutters">
                                                        <div className="col-auto">
                                                            <div className="image-nav" style={{width: '20px', height: '20px'}} />
                                                        </div>
                                                        <div className="col">
                                                            <NavLink to={screenConstants.ORGANIZATIONS}
                                                                     onClick={() => {this.setState({activeScreen: screenConstants.ORGANIZATIONS})}}>{this.getLocale().organisations.title}</NavLink>
                                                        </div>
                                                    </div>
                                                </li>
                                                : null

                                        }
                                        {
                                            this.props.loggedUser?.role !== roleConstants.SUPER_USER
                                                ? <>
                                                    <li className={ (this.isActiveClassName(new RegExp("^\\" + screenConstants.BRANCHES)) ? 'active-nav' : 'nav-item') + ' nav-item-li-dropdowns'}>
                                                        <div className="row justify-content-center align-items-center no-gutters">
                                                            <div className="col-auto">
                                                                <div className="image-nav" style={{width: '20px', height: '20px'}} />
                                                            </div>
                                                            <div className="col">
                                                                <NavLink to={screenConstants.BRANCHES}
                                                                         onClick={() => {this.setState({activeScreen: screenConstants.BRANCHES})}}>{this.getLocale().branches.title}</NavLink>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className={ (this.isActiveClassName(new RegExp("^\\" + screenConstants.SECTIONS)) ? 'active-nav' : 'nav-item') + ' nav-item-li-dropdowns'}>
                                                        <div className="row justify-content-center align-items-center no-gutters">
                                                            <div className="col-auto">
                                                                <div className="image-nav" style={{width: '20px', height: '20px'}} />
                                                            </div>
                                                            <div className="col">
                                                                <NavLink to={screenConstants.SECTIONS}
                                                                         onClick={() => {this.setState({activeScreen: screenConstants.SECTIONS})}}>{this.getLocale().sections.title}</NavLink>
                                                            </div>
                                                        </div>
                                                    </li>
                                                  </>
                                                : null
                                        }

                                    </ul>
                                </div>
                            </Collapse>
                            {
                                this.props.loggedUser?.role !== roleConstants.SUPER_USER
                                    ? <li className={
                                        this.isActiveClassName(new RegExp("^\\" + screenConstants.TEMPLATES + "|" + screenConstants.TEMPLATE)) ? 'active-nav' : 'nav-item'}>
                                        <div className="row justify-content-center align-items-center no-gutters">
                                            <div className="col">
                                                <NavLink to={screenConstants.TEMPLATES}
                                                         onClick={() => {this.setState({activeScreen: screenConstants.TEMPLATES})}}>
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col-auto icn-color">
                                                            {IconsNavigation.getIcnTemplate()}
                                                        </div>
                                                        <div className="col-auto">
                                                            {this.getLocale().templates.title}
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                      </li>
                                    : null
                            }
                        </ul>
                    </Nav>

                </div>
            </div>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    isActiveClassName(activeRegexp) {
        if (document.location.pathname.match(activeRegexp)) {
            return 'active'
        } else {
            return null;
        }
    }
}

Navigation.propTypes = {
    screen: PropTypes.string
}

export default connect(
    state => ({
        loggedUser: state.users.loggedUser,
        appState: state.app
    })
)(Navigation);
