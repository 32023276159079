import React from 'react';
import PropTypes from 'prop-types'

import {Modal} from "react-bootstrap";
import {connect} from "react-redux";

class DialogDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: props.isShow
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isShow !== prevProps.isShow)
            this.setState({
                isShow: this.state.isShow
            })
    }

    render() {
        return (
            <Modal dialogClassName="modal-dialog-delete" show={this.state.isShow} onHide={this.hideModal}>
                <Modal.Header className="modal-dialog-header">
                    <span className="modal-dialog-header-body">
                        {
                            !this.props.cancel
                                ? this.getLocale().common.what_delete_element
                                : this.getLocale().common.what_cancel_element
                        }
                    </span>
                </Modal.Header>
                <Modal.Footer className="modal-dialog-delete-footer">
                    <button type="button" className="button-border-common" onClick={this.hideModal}>
                        {this.getLocale().common.no}
                    </button>
                    <button type="button" className="button-common" onClick={this.clickYes}>
                        {this.getLocale().common.yes}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    clickYes = () => {
        this.props.clickYes()
        this.hideModal()
    }

    hideModal = () => {
        this.setState({
            isShow: false
        })
        this.props.onHide()
    }

}

DialogDelete.propTypes = {
    isShow: PropTypes.bool,
    clickYes: PropTypes.func
}

export default connect(
    state => ({
        appState: state.app
    })
)(DialogDelete);
