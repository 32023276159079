import React from "react";

import * as screenConstants from "../constants/ScreenConstants"
import {connect} from "react-redux";
import AppActions from "../actions/AppActions";
import UsersActions from "../actions/UsersActions";
import * as roleConstants from "../constants/RoleConstants";
import * as errorConstants from "../constants/ErrorConstants";
import AuthActions from "../actions/AuthActions";
import AuthService from "../network/service/AuthService";

class App extends React.Component {
    constructor(props) {
        super(props);
        // Add app version to meta tag
        let meta = document.createElement('meta');
        meta.name = "version";
        //meta.content = VERSION;
        document.getElementsByTagName('head')[0].appendChild(meta);

        this.props.onCheckLocale()
        this.props.onGetLoggedUser()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (document.location.pathname === "/"
            && this.props.loggedUser !== null) {

            if (this.props.loggedUser.role !== roleConstants.SUPER_USER &&
                this.props.loggedUser.role !== roleConstants.ADMIN) {
                AuthService.loggedOut()
                return
            }

            if (this.props.loggedUser.role !== roleConstants.SUPER_USER)
                window.location.replace(screenConstants.INSPECTIONS)
            else
                window.location.replace(screenConstants.USERS)
        }

        if (this.props.errorGlobalState.error !== prevProps.errorGlobalState.error) {
            switch (this.props.errorGlobalState.error?.response?.status) {
                case errorConstants.NOT_AUTH:
                    this.props.onRenewToken().then()
                    break
                default: break
            }
        }
    }

    render() {
        return <></>
    }
}

export default connect(
    state => ({
        loggedUser: state.users.loggedUser,
        errorGlobalState: state.globalError
    }),
    dispatch => ({
        onCheckLocale:() => {
            AppActions.locale(dispatch)
        },
        onGetLoggedUser: () => {
            dispatch(UsersActions.getLoggedUser())
        },
        onRenewToken: async () => {
            await AuthActions.renewToken(dispatch)
        }
    })
)(App);
