import React from "react";
import PropTypes from "prop-types";

import Tippy from "@tippyjs/react";
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/dist/tippy.css';

import IconsCommon from "../../../assets/icons/IconsCommon";
import {ListGroup} from "react-bootstrap";
import {connect} from "react-redux";
import * as roleConstants from "../../../constants/RoleConstants";
import * as sortTypeConstants from "../../../constants/SortTypeConstants";

class FieldDropDown extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isPopoverOpen: {
                user: false,
                inspector: false,
                organisation: false,
                branch: false,
                role: false,
                section: false,
                template: false,
                typeDate: false,
                customFields: false,
                customFieldItem: false,
            },

            isArrowFilter: {
                user: false,
                inspector: false,
                organisation: false,
                branch: false,
                role: false,
                section: false,
                template: false,
                typeDate: false,
                customFields: false,
                customFieldItem: false,
            },
            
            filterSelect: this.props.filterSelect
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dropdownState.check !== this.props.dropdownState.check)
            this.checkFields()
    }
    
    render() {
        return this.getSpinnerPopover(this.props.type)
    }

    getLocale() {
        return this.props.appState.locale
    }
    
    getSpinnerPopover(typesFilter) {
        return <Tippy
            arrow={IconsCommon.getIcnPopoverArrow(this.getArrowFilter(typesFilter))}
            content={
                <div
                    className={this.props.classNamePopover ? this.props.classNamePopover : "popover-container-form"}
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                    <ListGroup>
                        {
                            this.getPopoverList(typesFilter)?.map((value, index) => {
                                if (index === 0)
                                    return (<ListGroup.Item
                                        key={value.id}
                                        className="list-item-custom"
                                        onMouseEnter={() => {this.setArrowFilter(!this.getArrowFilter(typesFilter) ? true : this.getArrowFilter(typesFilter) , typesFilter)}}
                                        onMouseLeave={() => {this.setArrowFilter(this.getArrowFilter(typesFilter)  ? false : this.getArrowFilter(typesFilter) , typesFilter)}}
                                        onClick={() => {this.setFilterSelect(value, typesFilter)}}>
                                        {value.displayName}
                                    </ListGroup.Item>)
                                else
                                    return (
                                        <ListGroup.Item
                                            key={value.id}
                                            className="list-item-custom"
                                            onClick={() => {this.setFilterSelect(value, typesFilter)}}>
                                            {value.displayName}
                                        </ListGroup.Item>
                                    )

                            })
                        }
                    </ListGroup>
                </div>
            }
            interactive={true}
            visible={this.getPopoverOpen(typesFilter)}
            placement={this.props.placement ? this.props.placement : 'bottom-end'}
            onHide={() => this.setArrowFilter(false, typesFilter)}
            onClickOutside={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter) }>
            {
                this.getSpinner(typesFilter, this)
            }
        </Tippy>
    }

    getSpinner(typesFilter) {
        switch (typesFilter) {
            case FieldDropDown.typesFilter.user:
                return (<span
                    className={ this.state.filterSelect.isOrgError ? 'input-spinner-error ' : 'input-spinner '
                        + (this.state.filterSelect.organisation.id !== -1 ? 'input-result-spinner' : '')
                    }
                    style={{border: 0, borderRadius: '4px'}}
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                            {this.getFilterSelect(typesFilter)}
                        </span>)
            case FieldDropDown.typesFilter.organisation:
                return (<span
                    className={ this.state.filterSelect.isOrgError ? 'input-spinner-error ' : 'input-spinner '
                        + (this.state.filterSelect.organisation.id !== -1 ? 'input-result-spinner' : '')
                    }
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                            {this.getFilterSelect(typesFilter)}
                        </span>)
            case FieldDropDown.typesFilter.branch:
                return (<span
                    className={ this.state.filterSelect.isBranchError ? 'input-spinner-error ' : 'input-spinner '
                        + (this.state.filterSelect.branch.id !== -1 ? 'input-result-spinner' : '')
                    }
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                            {this.getFilterSelect(typesFilter)}
                        </span>)
            case FieldDropDown.typesFilter.role:
                return (<div
                    className={ 'row justify-content-center align-items-center ' + (this.state.filterSelect.isRoleError ? 'add-role-rect-error' : 'add-role-rect') }
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                    {IconsCommon.getIcnAddRole()}
                </div>)
            case FieldDropDown.typesFilter.inspector:
                return (<span
                    className={ this.state.filterSelect.isInspectorError ? 'input-spinner-error ' : 'input-spinner '
                        + (this.state.filterSelect.inspector.id !== -1 ? 'input-result-spinner' : '')
                    }
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                                {this.getFilterSelect(typesFilter)}
                        </span>)
            case FieldDropDown.typesFilter.section:
                return (<span
                    className={ this.state.filterSelect.isSectionError ? 'input-spinner-error ' : 'input-spinner '
                        + (this.state.filterSelect.section.id !== -1 ? 'input-result-spinner' : '')
                    }
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                                {this.getFilterSelect(typesFilter)}
                        </span>)
            case FieldDropDown.typesFilter.template:
                return (<span
                    className={ this.state.filterSelect.isTemplateError ? 'input-spinner-error ' : 'input-spinner '
                        + (this.state.filterSelect.template.id !== -1 ? 'input-result-spinner' : '')
                    }
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                                {this.getFilterSelect(typesFilter)}
                        </span>)
            case FieldDropDown.typesFilter.customFields:
                return (<span
                    className={ this.state.filterSelect.isCustomFieldsError ? 'input-spinner-error ' : 'input-spinner '
                        + (this.state.filterSelect.customFields.id !== -1 ? 'input-result-spinner' : '')
                    }
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                                {this.getFilterSelect(typesFilter)}
                        </span>)
            case FieldDropDown.typesFilter.customFieldItem:
                return (<span
                    className={ this.state.filterSelect.isCustomFieldItemError ? 'input-spinner-error ' : 'input-spinner '
                        + (this.state.filterSelect.customFieldItem.id !== -1 ? 'input-result-spinner' : '')
                    }
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                                {this.getFilterSelect(typesFilter)}
                        </span>)
            case FieldDropDown.typesFilter.typeDate:
                return (<span
                    className="type-date-field"
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                                {this.getFilterSelect(typesFilter)}
                        </span>)
            default: return null
        }
    }

    getPopoverOpen(typesFilter) {
        switch (typesFilter) {
            case FieldDropDown.typesFilter.user:
                return this.state.isPopoverOpen.user
            case FieldDropDown.typesFilter.inspector:
                return this.state.isPopoverOpen.inspector
            case FieldDropDown.typesFilter.organisation:
                return this.state.isPopoverOpen.organisation
            case FieldDropDown.typesFilter.branch:
                return this.state.isPopoverOpen.branch
            case FieldDropDown.typesFilter.role:
                return this.state.isPopoverOpen.role
            case FieldDropDown.typesFilter.section:
                return this.state.isPopoverOpen.section
            case FieldDropDown.typesFilter.template:
                return this.state.isPopoverOpen.template
            case FieldDropDown.typesFilter.typeDate:
                return this.state.isPopoverOpen.typeDate
            case FieldDropDown.typesFilter.customFields:
                return this.state.isPopoverOpen.customFields
            case FieldDropDown.typesFilter.customFieldItem:
                return this.state.isPopoverOpen.customFieldItem
            default: return null
        }
    }

    setPopoverOpen(isOpen, typesFilter) {
        let popoverState = this.state.isPopoverOpen
        switch (typesFilter) {
            case FieldDropDown.typesFilter.user:
                popoverState.user = isOpen
                break
            case FieldDropDown.typesFilter.inspector:
                popoverState.inspector = isOpen
                break
            case FieldDropDown.typesFilter.organisation:
                popoverState.organisation = isOpen
                break
            case FieldDropDown.typesFilter.branch:
                popoverState.branch = isOpen
                break
            case FieldDropDown.typesFilter.role:
                popoverState.role = isOpen
                break
            case FieldDropDown.typesFilter.section:
                popoverState.section = isOpen
                break
            case FieldDropDown.typesFilter.template:
                popoverState.template = isOpen
                break
            case FieldDropDown.typesFilter.typeDate:
                popoverState.typeDate = isOpen
                break
            case FieldDropDown.typesFilter.customFields:
                popoverState.customFields = isOpen
                break
            case FieldDropDown.typesFilter.customFieldItem:
                popoverState.customFieldItem = isOpen
                break
            default: break
        }

        this.setState({isPopoverOpen: popoverState})

    }

    getPopoverList(typesFilter) {
        switch (typesFilter) {
            case FieldDropDown.typesFilter.user:
                return this.props.usersState.users.filter(user => user.role === roleConstants.ADMIN)
            case FieldDropDown.typesFilter.inspector:
                return this.props.usersState.users.filter(user => user.role === roleConstants.INSPECTOR)
            case FieldDropDown.typesFilter.organisation:
                return this.props.organisationState.organizations
            case FieldDropDown.typesFilter.branch:
                return this.props.branchesState.branches
            case FieldDropDown.typesFilter.role:
                return this.getLocale().roles
            case FieldDropDown.typesFilter.section:
                return this.props.sectionsState.sections
            case FieldDropDown.typesFilter.template:
                return this.props.templatesState.templates
            case FieldDropDown.typesFilter.typeDate:
                return sortTypeConstants.getTypeDates(this.getLocale())
            case FieldDropDown.typesFilter.customFields:
                return this.props.customFieldsState.customFields
            case FieldDropDown.typesFilter.customFieldItem:
                return this.props.customFieldsState.elementsCustomField
            default: return null
        }
    }

    getArrowFilter(typesFilter) {
        switch (typesFilter) {
            case FieldDropDown.typesFilter.user:
                return this.state.isArrowFilter.user
            case FieldDropDown.typesFilter.inspector:
                return this.state.isArrowFilter.inspector
            case FieldDropDown.typesFilter.organisation:
                return this.state.isArrowFilter.organisation
            case FieldDropDown.typesFilter.branch:
                return this.state.isArrowFilter.branch
            case FieldDropDown.typesFilter.role:
                return this.state.isArrowFilter.role
            case FieldDropDown.typesFilter.section:
                return this.state.isArrowFilter.section
            case FieldDropDown.typesFilter.template:
                return this.state.isArrowFilter.template
            case FieldDropDown.typesFilter.typeDate:
                return this.state.isArrowFilter.typeDate
            case FieldDropDown.typesFilter.customFields:
                return this.state.isArrowFilter.customFields
            case FieldDropDown.typesFilter.customFieldItem:
                return this.state.isArrowFilter.customFieldItem
            default: return null
        }
    }

    setArrowFilter(filter, typesFilter) {
        let arrowFilterState = this.state.isArrowFilter
        switch (typesFilter) {
            case FieldDropDown.typesFilter.user:
                arrowFilterState.user = filter
                break
            case FieldDropDown.typesFilter.inspector:
                arrowFilterState.inspector = filter
                break
            case FieldDropDown.typesFilter.organisation:
                arrowFilterState.organisation = filter
                break
            case FieldDropDown.typesFilter.branch:
                arrowFilterState.branch = filter
                break
            case FieldDropDown.typesFilter.role:
                arrowFilterState.role = filter
                break
            case FieldDropDown.typesFilter.section:
                arrowFilterState.section = filter
                break
            case FieldDropDown.typesFilter.template:
                arrowFilterState.template = filter
                break
            case FieldDropDown.typesFilter.typeDate:
                arrowFilterState.typeDate = filter
                break
            case FieldDropDown.typesFilter.customFields:
                arrowFilterState.customFields = filter
                break
            case FieldDropDown.typesFilter.customFieldItem:
                arrowFilterState.customFieldItem = filter
                break
            default: break
        }

        this.setState({isArrowFilter: arrowFilterState})

    }

    getFilterSelect(typesFilter) {
        switch (typesFilter) {
            case FieldDropDown.typesFilter.user:
                return this.state.filterSelect.user.displayName
            case FieldDropDown.typesFilter.inspector:
                return this.state.filterSelect.inspector.displayName
            case FieldDropDown.typesFilter.organisation:
                return this.state.filterSelect.organisation.displayName
            case FieldDropDown.typesFilter.branch:
                return this.state.filterSelect.branch.displayName
            case FieldDropDown.typesFilter.role:
                return this.state.filterSelect.role.displayName
            case FieldDropDown.typesFilter.section:
                return this.state.filterSelect.section.displayName
            case FieldDropDown.typesFilter.template:
                return this.state.filterSelect.template.displayName
            case FieldDropDown.typesFilter.typeDate:
                return this.state.filterSelect.typeDate.displayName
            case FieldDropDown.typesFilter.customFields:
                return this.state.filterSelect.customFields.displayName
            case FieldDropDown.typesFilter.customFieldItem:
                return this.state.filterSelect.customFieldItem.displayName
            default: return null
        }
    }

    setFilterSelect(filterSelect, typesFilter) {
        let filterSelectState = this.state.filterSelect
        switch (typesFilter) {
            case FieldDropDown.typesFilter.user:
                filterSelectState.user = filterSelect
                filterSelectState.isUserError = this.state.filterSelect.user.id === -1
                break
            case FieldDropDown.typesFilter.inspector:
                filterSelectState.inspector = filterSelect
                filterSelectState.isInspectorError = this.state.filterSelect.inspector.id === -1
                break
            case FieldDropDown.typesFilter.organisation:
                filterSelectState.organisation = filterSelect
                filterSelectState.isOrgError = this.state.filterSelect.organisation.id === -1
                break
            case FieldDropDown.typesFilter.branch:
                filterSelectState.branch = filterSelect
                filterSelectState.isBranchError = this.state.filterSelect.branch.id === -1
                break
            case FieldDropDown.typesFilter.role:
                filterSelectState.role = filterSelect
                filterSelectState.isRoleError = this.state.filterSelect.role.id === -1
                break
            case FieldDropDown.typesFilter.section:
                filterSelectState.section = filterSelect
                filterSelectState.isSectionError = this.state.filterSelect.section.id === -1
                break
            case FieldDropDown.typesFilter.template:
                filterSelectState.template = filterSelect
                filterSelectState.isTemplateError = this.state.filterSelect.template.id === -1
                break
            case FieldDropDown.typesFilter.typeDate:
                filterSelectState.typeDate = filterSelect
                filterSelectState.isTypeDateError = this.state.filterSelect.typeDate.id === -1
                break
            case FieldDropDown.typesFilter.customFields:
                filterSelectState.customFields = filterSelect
                filterSelectState.isCustomFieldsError = this.state.filterSelect.customFields.id === -1
                break
            case FieldDropDown.typesFilter.customFieldItem:
                filterSelectState.customFieldItem = filterSelect
                filterSelectState.isCustomFieldItemError = this.state.filterSelect.customFieldItem.id === -1
                break
            default: break
        }

        this.setState({filterSelect: filterSelectState})
        this.props.updateFilterSelect(filterSelectState)

    }
    
    checkFields() {
        if (this.state.filterSelect?.organisation
            && this.state.filterSelect.organisation.id === -1
            && this.props.loggedUser?.role === roleConstants.SUPER_USER) {
            let newFilterSelect = this.state.filterSelect
            newFilterSelect.isOrgError = true
            this.setState({
                filterSelect: newFilterSelect
            })
        }

        if (this.state.filterSelect?.branch
            && this.state.filterSelect.branch.id === -1) {
            let newFilterSelect = this.state.filterSelect
            newFilterSelect.isBranchError = true
            this.setState({
                filterSelect: newFilterSelect
            })
        }

        if (this.state.filterSelect?.inspector
            && this.state.filterSelect.inspector.id === -1) {
            let newFilterSelect = this.state.filterSelect
            newFilterSelect.isInspectorError = true
            this.setState({
                filterSelect: newFilterSelect
            })
        }

        if (this.state.filterSelect?.template
            && this.state.filterSelect.template.id === -1) {
            let newFilterSelect = this.state.filterSelect
            newFilterSelect.isTemplateError = true
            this.setState({
                filterSelect: newFilterSelect
            })
        }

        if (this.state.filterSelect?.role
            && this.state.filterSelect.role.id === -1) {
            let newFilterSelect = this.state.filterSelect
            newFilterSelect.isRoleError = true
            this.setState({
                filterSelect: newFilterSelect
            })
        }

        if (this.state.filterSelect?.section
            && this.state.filterSelect.section.id === -1) {
            let newFilterSelect = this.state.filterSelect
            newFilterSelect.isSectionError = true
            this.setState({
                filterSelect: newFilterSelect
            })
        }

        if (this.state.filterSelect?.customFieldItem
            && this.state.filterSelect.customFieldItem.id === -1) {
            let newFilterSelect = this.state.filterSelect
            newFilterSelect.isCustomFieldItemError = true
            this.setState({
                filterSelect: newFilterSelect
            })
        }

        this.props.updateFilterSelect(this.state.filterSelect)
    }
    
}

FieldDropDown.typesFilter = {
    user: 'user',
    inspector: 'inspector',
    organisation: 'organisation',
    branch: 'branch',
    role: 'role',
    section: 'section',
    template: 'template',
    typeDate: 'type_date',
    customFields: 'custom_fields',
    customFieldItem: 'custom_field_item',
}

FieldDropDown.propTypes = {
    type: PropTypes.string.isRequired,
    filterSelect: PropTypes.object.isRequired,
    updateFilterSelect: PropTypes.func.isRequired,
    classNamePopover: PropTypes.string,
    placement: PropTypes.string
};

FieldDropDown.defaultProps = {};

export default connect(
    state => ({
        appState: state.app,
        loggedUser: state.users.loggedUser,
        usersState: state.users,
        branchesState: state.branches,
        sectionsState: state.sections,
        organisationState: state.organizations,
        templatesState: state.templates,
        customFieldsState: state.customFields,
        dropdownState: state.dropdown
    })
)(FieldDropDown);
