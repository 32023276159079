import CustomFieldsService from "../network/service/CustomFieldsService";

const customFieldsActions = {
  async getCustomFields(dispatch, name = null) {
    await CustomFieldsService.getCustomFields(dispatch, name);
  },

  async getElementsCustomFields(dispatch, customFieldId) {
    await CustomFieldsService.getElementsCustomFields(dispatch, customFieldId);
  },
};

export default customFieldsActions;
