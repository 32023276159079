import {put, call, takeEvery} from "@redux-saga/core/effects";
import * as actionConstants from "../actions/ActionConstants";
import {
    createUploadApi,
    createUploadForSuperUserApi,
    getUploadApi,
    getUploadForSuperUserApi,
    getUploadsApi,
    getUploadsForSuperUserApi
} from "../network/api/UploadsApi";
import * as roleConstants from "../constants/RoleConstants";
import {checkLoggedUser} from "./helper";

function* getUploads() {
    try {
        const loggedUser = yield call(checkLoggedUser)

        let responseUpload
        if (loggedUser.role === roleConstants.SUPER_USER)
            responseUpload = yield call(getUploadsForSuperUserApi)
        else
            responseUpload = yield call(getUploadsApi)

        const uploads = responseUpload.data

        const result = uploads.map(upload => {return {
            id: upload.id,
            createdAt: new Date(upload.created_at),
            organizationId: upload.entity_id,
            updatedAt: new Date(upload.updated_at),
            isDeleted: upload.is_deleted,
            subjectId: upload.subject_id,
            subjectType: upload.subject_type,
            url: process.env.REACT_APP_HOST_URL + upload.url,
            userId: upload.user_id
        }})

        yield put({
            type: actionConstants.SUCCESS_LOAD_UPLOADS, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_UPLOADS, payload: {
                error: error
            }
        })
    }
}

function* getUpload(action) {
    const { id } = action.payload
    try {
        const loggedUser = yield call(checkLoggedUser)

        let responseUpload
        if (loggedUser.role === roleConstants.SUPER_USER)
            responseUpload = yield call(getUploadForSuperUserApi, id)
        else
            responseUpload = yield call(getUploadApi, id)

        const upload = responseUpload.data

        const result = {
            id: upload.id,
            createdAt: new Date(upload.created_at),
            organizationId: upload.entity_id,
            updatedAt: new Date(upload.updated_at),
            isDeleted: upload.is_deleted,
            subjectId: upload.subject_id,
            subjectType: upload.subject_type,
            url: process.env.REACT_APP_HOST_URL + upload.url,
            userId: upload.user_id
        }

        yield put({
            type: actionConstants.SUCCESS_LOAD_UPLOAD, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_UPLOAD, payload: {
                error: error
            }
        })
    }
}

function* createUpload(action) {
    const { file, fileId, questionId, entityId } = action.payload
    let formData = new FormData()
    formData.append('file', file)
    try {
        const loggedUser = yield call(checkLoggedUser)

        let response
        if (loggedUser.role === roleConstants.SUPER_USER) {
            formData.append('entity_id', entityId)
            response = yield call(createUploadForSuperUserApi, formData)
        } else
            response = yield call(createUploadApi, formData)

        const upload = response.data.model

        const result = {
            id: upload.id,
            createdAt: new Date(upload.created_at),
            organizationId: upload.entity_id,
            updatedAt: new Date(upload.updated_at),
            isDeleted: upload.is_deleted,
            subjectId: upload.subject_id,
            subjectType: upload.subject_type,
            url: process.env.REACT_APP_HOST_URL + upload.url,
            userId: upload.user_id
        }

        yield put({
            type: actionConstants.SUCCESS_CREATE_UPLOAD, payload: {
                data: {file: result, fileId: fileId, questionId: questionId}
            }
        })
    } catch (error) {
        yield put({
            type: actionConstants.ERROR_CREATE_UPLOAD, payload: {
                error: error
            }
        })
    }
}

export default function* uploadsSaga() {
    yield takeEvery(actionConstants.START_LOAD_UPLOADS, getUploads)
    yield takeEvery(actionConstants.START_LOAD_UPLOAD, getUpload)
    yield takeEvery(actionConstants.START_CREATE_UPLOAD, createUpload)
}