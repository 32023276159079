import IconsCommon from '../../assets/icons/IconsCommon'

import PropTypes from 'prop-types'

import React from 'react'
import {connect} from 'react-redux'

import DialogDelete from "../commons/dialog/DialogDelete";

import TableDropDown from "../commons/dropdown/TableDropDown"
import DialogBranchesEdit from "./DialogBranchesEdit";
import * as roleConstants from "../../constants/RoleConstants";
import {minTwoDigits} from "../utils/TableUtil";
import BranchesActions from "../../actions/BranchesActions";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";

class Branches extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageCount: 5,
            isSortId: false,
            showBranchesEditDialog: {
                isShowBranchesEditDialog: false,
                isEdit: false,
                branch: null
            },
            showDeleteDialog: {
                isShowDeleteDialog: false,
                branchId: -1
            },

            filterSelect: {
                organisation: ''
            },

            branches: null,

            filterData: {
                filterOrganisation:[
                    {
                        id: 0,
                        type: 'Первичная',

                    },
                    {
                        id: 1,
                        type: 'Вторичная',
                    }
                ]
            }
        }

        this.props.onGetBranches()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.branchesState.branches !== prevProps.branchesState.branches) {
            this.setState({
                branches: this.props.branchesState.branches
            })
        }
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <span className="title-text">{this.getLocale().branches.title}</span>
                        </div>
                        <div className="col">
                            <div className="row h-100 align-items-center no-gutters">
                                <button className="directory-button" onClick={this.showBranchesEditDialog}>
                                    {this.getLocale().branches.add_branch}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col table-block">
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" style={{width: '25%'}}>
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">ID</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" style={{width: '15%'}}>
                                        <span className="table-header-title">{this.getLocale().branches.table_header.name}</span>
                                    </th>
                                    {
                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                            ? <TableDropDown type={TableDropDown.typesFilter.organisation}
                                                             customStyle={{width: '20%'}}
                                                             filterData={this.state.filterData}
                                                             filterSelect={this.state.filterSelect}
                                                             updateFilterSelect={this.updateFilterSelect}
                                                             updateData={this.updateData} />
                                            : null
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.branches?.map(item => {
                                            return (
                                                <tr className="table-row" key={item.id}>
                                                    <td><span className="table-ceil">{minTwoDigits(item.id)}</span></td>
                                                    <td onClick={() => this.onRowClick(item.id)}><span className="table-ceil-name-non-pointer">{item.displayName}</span></td>
                                                    {
                                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                                            ? <td><span className="table-ceil">{item.organization}</span></td>
                                                            : null
                                                    }
                                                    <td>
                                                        <div className="table-icn-delete">
                                                            <div className="row">
                                                                <div className="col-auto" onClick={() => this.showDialogDelete(item.id)}>
                                                                    {IconsCommon.getIcnDelete()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        this.state.showDeleteDialog.isShowDeleteDialog
                            ? <DialogDelete isShow={true} onHide={this.hideDialogDelete} clickYes={this.deleteBranch} />
                            : null
                    }
                    {
                        this.state.showBranchesEditDialog.isShowBranchesEditDialog
                            ? <DialogBranchesEdit role={this.props.loggedUser?.role}
                                                  isShow={true}
                                                  onHide={this.hideBranchesEditDialog}
                                                  clickSave={this.saveBranches} />
                            : null
                    }
                    {
                        this.props.branchesState.error
                            ? <DialogError isShow={true}
                                           type={errorConstants.ERROR_COMPONENT}/>
                            : null
                    }
                </section>
        )
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })
    }

    showDialogDelete = (branchId) => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = true
        newShowDeleteDialog.branchId = branchId
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    hideDialogDelete = () => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = false
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    showBranchesEditDialog = (edit) => {
        let newShowBranchesEditDialog = this.state.showBranchesEditDialog
        newShowBranchesEditDialog.isShowBranchesEditDialog = true
        newShowBranchesEditDialog.isEdit = !!edit
        this.setState({
            showBranchesEditDialog: newShowBranchesEditDialog
        })
    }

    hideBranchesEditDialog = () => {
        let newShowBranchesEditDialog = this.state.showBranchesEditDialog
        newShowBranchesEditDialog.isShowBranchesEditDialog = false
        this.setState({
            showBranchesEditDialog: newShowBranchesEditDialog
        })
    }

    deleteBranch = async () => {
        await this.props.onDeleteBranch(this.state.showDeleteDialog.branchId)
    }

    saveBranches = (name) => {
        this.props.onCreateBranch(name).then()
    }

    downloadInspections() {

    }

    onRowClick = () => {}

    getLocale() {
        return this.props.appState.locale
    }

    updateData = () => {
        let newFiltered = this.props.branchesState.branches.slice()

        if (this.state.filterSelect.organisation) {
            newFiltered = newFiltered.filter(value => value.organisation === this.state.filterSelect.organisation)
        }

        this.setState({
            branches: newFiltered
        })
    }

}

Branches.propTypes = {
    page: PropTypes.number,   // Page number
}

export default connect(
    state => ({
        branchesState: state.branches,
        loggedUser: state.users.loggedUser,
        appState: state.app
    }),
    dispatch => ({
        onGetBranches: () => {
            dispatch(BranchesActions.getBranches())
        },
        onDeleteBranch: async(id) => {
            await BranchesActions.deleteBranch(dispatch, id)
        },
        onCreateBranch: async (name) => {
            await BranchesActions.createBranch(dispatch, name)
        }
    })
)(Branches);