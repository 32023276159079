import client from "../client";

export async function getDirItemsApi() {
    return client.get(
        '/api/dir_items')
}

export async function getDirItemsWithKindApi(kind) {
    return client.get(
        '/api/dir_items?kind=' + kind)
}

export async function getDirItemApi(id) {
    return client.get(
        '/api/dir_items/' + id)
}

export async function createDirItemApi() {
    return client.post(
        '/api/dir_items')
}

export async function updateDirItemApi(id) {
    return client.put(
        '/api/dir_items/' + id)
}

export async function deleteDirItemApi(id) {
    return client.delete(
        '/api/dir_items/' + id
    )
}
