import InspectionsService from "../network/service/InspectionsService";
import * as actionConstants from "./ActionConstants";
import * as commonConstants from "../constants/CommonConstants";

const inspectionsActions = {

    getInspections(limit, filterType, filterBranch, filterState, filterInspector, displayName, executeSince, executeTill) {
        return {
            type: actionConstants.START_LOAD_INSPECTIONS, payload: {
                limit: limit,
                filterType: filterType,
                filterBranch: filterBranch,
                filterState: filterState,
                filterInspector: filterInspector,
                displayName: displayName,
                executeSince: executeSince,
                executeTill: executeTill
            }
        }
    },

    loadYetInspections(limit, filterType, filterBranch, filterState, filterInspector, displayName, executeSince, executeTill) {
        return {
            type: actionConstants.LOAD_YET_INSPECTIONS, payload: {
                limit: limit,
                filterType: filterType,
                filterBranch: filterBranch,
                filterState: filterState,
                filterInspector: filterInspector,
                displayName: displayName,
                executeSince: executeSince,
                executeTill: executeTill
            }
        }
    },

    async getInspection(dispatch, id, type) {
        await InspectionsService.getInspection(dispatch, id, type)
    },

    async createInspection(
      dispatch,
      branchId,
      customFieldItemId,
      displayName,
      entityId,
      executeAt,
      formVersionCustomFieldId,
      templateId,
      userId,
    ) {
        await InspectionsService.createInspection(
          dispatch,
          branchId,
          customFieldItemId,
          displayName,
          entityId,
          executeAt,
          formVersionCustomFieldId,
          templateId,
          userId,
        )
        dispatch(this.getInspections(commonConstants.INSPECTIONS_LIMIT_LOAD))
    },

    async getInspectionAnswers(dispatch, id) {
        await InspectionsService.getInspectionAnswers(dispatch, id)
    },

    async cancelInspection(dispatch, id, type) {
        await InspectionsService.cancelInspection(dispatch, id, type)
        dispatch(this.getInspections(commonConstants.INSPECTIONS_LIMIT_LOAD))
    },

    async addComment(dispatch, id, type, text) {
        await InspectionsService.addComment(dispatch, id, type, text)
    }
}

export default inspectionsActions
