import React from 'react';
import PropTypes from 'prop-types'

import IconsButton from "../../assets/icons/IconsButton";

import * as buttonConstants from "../../constants/ButtonConstants"

class ButtonImage extends React.Component {
    render() {
        return (
            <div onClick={this.props.isNotEnabled ? null : this.props.click}
                 style={{cursor: 'pointer', marginTop: '5px', marginBottom: '5px'}}
                 className={'row justify-content-center align-items-center no-gutters buttons ' + (this.props.isNotEnabled ? 'buttons-opacity' : '' )}>
                <div className="col-auto">
                    {this.getIcon()}
                </div>
                <div className="col-auto">
                    <span className="buttons-text">{this.props.text}</span>
                </div>
            </div>
        )
    }

    getIcon() {
        switch (this.props.icon) {
            case buttonConstants.ADD:
                return IconsButton.getIcnAdd()
            case buttonConstants.EXCEL:
                return IconsButton.getIcnExcel()
            default: return null
        }
    }
}

ButtonImage.propTypes = {
    text: PropTypes.string.isRequired,
    isNotEnabled: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired
}

export default ButtonImage;
