import React from 'react';
import PropTypes from 'prop-types'

import {Modal} from "react-bootstrap";

import {calculateAspectRatioFit} from "../../utils/ImageUtil"

class DialogDelete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShow: props.isShow,
            width: null,
            height: null
        }

        let img = new Image();
        img.onload = () => {
            this.sizeImage = calculateAspectRatioFit(img.width, img.height, 750, 750)
            this.setState({
                width: this.sizeImage.width,
                height: this.sizeImage.height
            })
        }
        img.src = props.path

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isShow !== prevProps.isShow)
            this.setState({
                isShow: this.state.isShow
            })
    }

    render() {
        return (
            this.state.width && this.state.height
                ? <Modal dialogClassName="modal-dialog-image" show={this.state.isShow} onHide={this.hideModal}>
                    <Modal.Body style={{minWidth: this.state.width, minHeight: this.state.height, padding: 0}}>
                        {
                            <img src={this.props.path}
                                 className="modal-image-img"
                                 width={this.state.width}
                                 height={this.state.height}  alt=""/>
                        }
                    </Modal.Body>
                </Modal>


                : null
        )
    }

    hideModal = () => {
        this.setState({
            isShow: false
        })
        this.props.onHide()
    }

}

DialogDelete.propTypes = {
    isShow: PropTypes.bool,
    path: PropTypes.string
}

export default DialogDelete;
