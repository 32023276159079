import React from "react";

const iconsInspections = {
    getIcnLogin() {
        return  <svg width="460" height="460" viewBox="0 0 460 460" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="322.383" y="3.5" width="113.033" height="163.419" stroke="white"/>
            <rect x="342.273" y="23.3896" width="73.2538" height="58.6682" stroke="white"/>
            <path d="M343.1 101.784H388.845M416.027 101.784H402.768" stroke="white"/>
            <line x1="343.1" y1="121.836" x2="416.027" y2="121.836" stroke="white"/>
            <line x1="343.1" y1="141.726" x2="416.027" y2="141.726" stroke="white"/>
            <path d="M193.264 457.585V73.2759H377.572V457.585H193.264Z" fill="white"/>
            <rect x="214.98" y="120.158" width="48.0605" height="57.309" fill="#FF3D71" stroke="#FF3D71"/>
            <path d="M221.508 170.282H219.783V162.725H221.508V170.282Z" fill="white"/>
            <path d="M229.35 168.335C229.35 168.003 229.195 167.747 228.885 167.567C228.579 167.387 228.025 167.206 227.222 167.022C226.418 166.839 225.78 166.635 225.305 166.41C224.397 165.978 223.943 165.414 223.943 164.718C223.943 164.109 224.269 163.608 224.922 163.213C225.579 162.819 226.43 162.622 227.475 162.622C228.168 162.622 228.787 162.718 229.33 162.912C229.873 163.106 230.299 163.383 230.61 163.743C230.92 164.099 231.075 164.495 231.075 164.931H229.35C229.35 164.537 229.186 164.229 228.857 164.007C228.533 163.782 228.068 163.67 227.461 163.67C226.895 163.67 226.455 163.762 226.14 163.945C225.83 164.128 225.675 164.384 225.675 164.713C225.675 164.99 225.843 165.222 226.181 165.408C226.519 165.592 227.075 165.772 227.851 165.948C228.627 166.121 229.25 166.32 229.72 166.545C230.19 166.766 230.534 167.022 230.753 167.313C230.972 167.6 231.082 167.938 231.082 168.325C231.082 168.955 230.763 169.456 230.124 169.83C229.489 170.2 228.627 170.385 227.536 170.385C226.815 170.385 226.151 170.285 225.545 170.084C224.942 169.88 224.472 169.6 224.135 169.244C223.801 168.887 223.635 168.472 223.635 167.998H225.367C225.367 168.427 225.554 168.759 225.928 168.995C226.302 169.23 226.838 169.347 227.536 169.347C228.139 169.347 228.59 169.256 228.892 169.072C229.197 168.886 229.35 168.64 229.35 168.335Z" fill="white"/>
            <path d="M238.315 168.335C238.315 168.003 238.16 167.747 237.849 167.567C237.544 167.387 236.989 167.206 236.186 167.022C235.383 166.839 234.744 166.635 234.27 166.41C233.362 165.978 232.908 165.414 232.908 164.718C232.908 164.109 233.234 163.608 233.886 163.213C234.543 162.819 235.394 162.622 236.439 162.622C237.133 162.622 237.751 162.718 238.294 162.912C238.837 163.106 239.264 163.383 239.574 163.743C239.885 164.099 240.04 164.495 240.04 164.931H238.315C238.315 164.537 238.151 164.229 237.822 164.007C237.498 163.782 237.033 163.67 236.426 163.67C235.86 163.67 235.42 163.762 235.105 163.945C234.794 164.128 234.639 164.384 234.639 164.713C234.639 164.99 234.808 165.222 235.146 165.408C235.483 165.592 236.04 165.772 236.816 165.948C237.592 166.121 238.215 166.32 238.685 166.545C239.155 166.766 239.499 167.022 239.718 167.313C239.937 167.6 240.047 167.938 240.047 168.325C240.047 168.955 239.727 169.456 239.088 169.83C238.454 170.2 237.592 170.385 236.501 170.385C235.78 170.385 235.116 170.285 234.509 170.084C233.907 169.88 233.437 169.6 233.099 169.244C232.766 168.887 232.6 168.472 232.6 167.998H234.331C234.331 168.427 234.518 168.759 234.893 168.995C235.267 169.23 235.803 169.347 236.501 169.347C237.103 169.347 237.555 169.256 237.856 169.072C238.162 168.886 238.315 168.64 238.315 168.335Z" fill="white"/>
            <path d="M249.36 162.725V167.775C249.36 168.578 249.02 169.214 248.34 169.685C247.665 170.152 246.764 170.385 245.637 170.385C244.496 170.385 243.59 170.155 242.92 169.695C242.249 169.232 241.913 168.59 241.913 167.77V162.725H243.638V167.78C243.638 168.285 243.807 168.671 244.145 168.937C244.482 169.204 244.98 169.337 245.637 169.337C246.969 169.337 247.635 168.804 247.635 167.739V162.725H249.36Z" fill="white"/>
            <path d="M257.545 166.908H253.452V169.233H258.236V170.282H251.72V162.725H258.188V163.784H253.452V165.87H257.545V166.908Z" fill="white"/>
            <path d="M221.508 170.282H219.783V162.725H221.508V170.282Z" stroke="white"/>
            <path d="M229.35 168.335C229.35 168.003 229.195 167.747 228.885 167.567C228.579 167.387 228.025 167.206 227.222 167.022C226.418 166.839 225.78 166.635 225.305 166.41C224.397 165.978 223.943 165.414 223.943 164.718C223.943 164.109 224.269 163.608 224.922 163.213C225.579 162.819 226.43 162.622 227.475 162.622C228.168 162.622 228.787 162.718 229.33 162.912C229.873 163.106 230.299 163.383 230.61 163.743C230.92 164.099 231.075 164.495 231.075 164.931H229.35C229.35 164.537 229.186 164.229 228.857 164.007C228.533 163.782 228.068 163.67 227.461 163.67C226.895 163.67 226.455 163.762 226.14 163.945C225.83 164.128 225.675 164.384 225.675 164.713C225.675 164.99 225.843 165.222 226.181 165.408C226.519 165.592 227.075 165.772 227.851 165.948C228.627 166.121 229.25 166.32 229.72 166.545C230.19 166.766 230.534 167.022 230.753 167.313C230.972 167.6 231.082 167.938 231.082 168.325C231.082 168.955 230.763 169.456 230.124 169.83C229.489 170.2 228.627 170.385 227.536 170.385C226.815 170.385 226.151 170.285 225.545 170.084C224.942 169.88 224.472 169.6 224.135 169.244C223.801 168.887 223.635 168.472 223.635 167.998H225.367C225.367 168.427 225.554 168.759 225.928 168.995C226.302 169.23 226.838 169.347 227.536 169.347C228.139 169.347 228.59 169.256 228.892 169.072C229.197 168.886 229.35 168.64 229.35 168.335Z" stroke="white"/>
            <path d="M238.315 168.335C238.315 168.003 238.16 167.747 237.849 167.567C237.544 167.387 236.989 167.206 236.186 167.022C235.383 166.839 234.744 166.635 234.27 166.41C233.362 165.978 232.908 165.414 232.908 164.718C232.908 164.109 233.234 163.608 233.886 163.213C234.543 162.819 235.394 162.622 236.439 162.622C237.133 162.622 237.751 162.718 238.294 162.912C238.837 163.106 239.264 163.383 239.574 163.743C239.885 164.099 240.04 164.495 240.04 164.931H238.315C238.315 164.537 238.151 164.229 237.822 164.007C237.498 163.782 237.033 163.67 236.426 163.67C235.86 163.67 235.42 163.762 235.105 163.945C234.794 164.128 234.639 164.384 234.639 164.713C234.639 164.99 234.808 165.222 235.146 165.408C235.483 165.592 236.04 165.772 236.816 165.948C237.592 166.121 238.215 166.32 238.685 166.545C239.155 166.766 239.499 167.022 239.718 167.313C239.937 167.6 240.047 167.938 240.047 168.325C240.047 168.955 239.727 169.456 239.088 169.83C238.454 170.2 237.592 170.385 236.501 170.385C235.78 170.385 235.116 170.285 234.509 170.084C233.907 169.88 233.437 169.6 233.099 169.244C232.766 168.887 232.6 168.472 232.6 167.998H234.331C234.331 168.427 234.518 168.759 234.893 168.995C235.267 169.23 235.803 169.347 236.501 169.347C237.103 169.347 237.555 169.256 237.856 169.072C238.162 168.886 238.315 168.64 238.315 168.335Z" stroke="white"/>
            <path d="M249.36 162.725V167.775C249.36 168.578 249.02 169.214 248.34 169.685C247.665 170.152 246.764 170.385 245.637 170.385C244.496 170.385 243.59 170.155 242.92 169.695C242.249 169.232 241.913 168.59 241.913 167.77V162.725H243.638V167.78C243.638 168.285 243.807 168.671 244.145 168.937C244.482 169.204 244.98 169.337 245.637 169.337C246.969 169.337 247.635 168.804 247.635 167.739V162.725H249.36Z" stroke="white"/>
            <path d="M257.545 166.908H253.452V169.233H258.236V170.282H251.72V162.725H258.188V163.784H253.452V165.87H257.545V166.908Z" stroke="white"/>
            <rect x="220.283" y="125.597" width="37.4529" height="30.0553" fill="white" stroke="white"/>
            <path d="M231.218 135.44C231.218 136.225 230.563 136.881 229.729 136.881C228.895 136.881 228.24 136.225 228.24 135.44C228.24 134.656 228.895 134 229.729 134C230.563 134 231.218 134.656 231.218 135.44Z" fill="#FF3D71" stroke="#FF3D71"/>
            <path d="M249.507 135.44C249.507 136.225 248.852 136.881 248.018 136.881C247.184 136.881 246.529 136.225 246.529 135.44C246.529 134.656 247.184 134 248.018 134C248.852 134 249.507 134.656 249.507 135.44Z" fill="#FF3D71" stroke="#FF3D71"/>
            <path d="M227.74 148.388C230.141 146.051 234.292 144.506 239.011 144.506C243.729 144.506 247.88 146.051 250.282 148.388" stroke="#FF3D71" strokeLinecap="round"/>
            <rect x="285.256" y="120.158" width="70.6019" height="59.9594" fill="#D9E4FF" stroke="#D9E4FF"/>
            <line y1="-1" x2="47.7346" y2="-1" transform="matrix(1 0 0 -1 296.688 150.138)" stroke="#0A1E46" strokeWidth="2"/>
            <line y1="-1" x2="27.8452" y2="-1" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 316.578 139.536)" stroke="#0A1E46" strokeWidth="2"/>
            <line y1="-1" x2="27.8452" y2="-1" transform="matrix(1 -8.73729e-08 -8.74727e-08 -1 316.578 160.739)" stroke="#0A1E46" strokeWidth="2"/>
            <rect x="214.98" y="89.6782" width="142.204" height="13.5772" fill="#D9E4FF" stroke="#D9E4FF"/>
            <path d="M118.894 456.698V438.381C118.894 438.232 118.861 438.085 118.797 437.951L104.787 408.516C104.525 407.966 104.811 407.312 105.392 407.131L129.617 399.574C130.052 399.438 130.524 399.614 130.765 400.002L146.957 426.126L146.96 426.129C147.005 426.2 154.574 438.166 164.405 441.913C172.655 445.057 172.941 453.988 172.814 456.823C172.792 457.326 172.371 457.698 171.867 457.698H119.894C119.342 457.698 118.894 457.25 118.894 456.698Z" fill="#0A1E46" stroke="#0A1E46"/>
            <path d="M118.254 453.892L172.834 453.892" stroke="#D9E4FF"/>
            <path d="M118.889 439.938C130.722 444.172 130.947 453.892 130.947 453.892H118.889V439.938Z" stroke="#D9E4FF"/>
            <path d="M22.8945 456.698V438.155L24.8499 408.16C24.8768 407.747 25.1551 407.393 25.55 407.27L49.6621 399.748C50.306 399.547 50.96 400.028 50.96 400.703L50.9597 425.84C50.9597 426.029 51.0085 426.207 51.1124 426.364C52.2301 428.062 59.3605 438.466 68.4056 441.913C76.655 445.057 76.9412 453.988 76.8145 456.823C76.792 457.326 76.3714 457.698 75.8675 457.698H23.8945C23.3422 457.698 22.8945 457.25 22.8945 456.698Z" fill="#0A1E46" stroke="#0A1E46"/>
            <path d="M22.2539 453.892L76.8337 453.892" stroke="#D9E4FF"/>
            <path d="M22.8887 439.938C34.7222 444.172 34.947 453.892 34.947 453.892H22.8887V439.938Z" stroke="#D9E4FF"/>
            <path d="M101.894 295.978H38.2479L19.0215 435.786H65.4301L76.7008 342.356L114.491 438.437L162.103 430.622L101.894 295.978Z" fill="#D9E4FF"/>
            <path d="M76.7008 342.356L114.491 438.437L162.103 430.622L101.894 295.978H38.2479L19.0215 435.786H65.4301L76.7008 342.356ZM76.7008 342.356L83.8718 327.86" stroke="#0A1E46"/>
            <rect x="285.256" y="197.02" width="70.6019" height="30.8049" fill="#D9E4FF" stroke="#D9E4FF"/>
            <line x1="298.016" y1="207.446" x2="345.75" y2="207.446" stroke="#0A1E46" strokeWidth="2"/>
            <line x1="317.906" y1="218.048" x2="345.751" y2="218.048" stroke="#0A1E46" strokeWidth="2"/>
            <rect x="285.256" y="244.728" width="70.6019" height="30.8049" fill="#D9E4FF" stroke="#D9E4FF"/>
            <line x1="298.016" y1="255.154" x2="345.75" y2="255.154" stroke="#0A1E46" strokeWidth="2"/>
            <line x1="317.906" y1="265.756" x2="345.751" y2="265.756" stroke="#0A1E46" strokeWidth="2"/>
            <rect x="285.256" y="292.435" width="70.6019" height="30.8049" fill="#D9E4FF" stroke="#D9E4FF"/>
            <rect x="232.217" y="197.021" width="30.8231" height="30.8049" fill="#D9E4FF" stroke="#D9E4FF"/>
            <path d="M239.01 209.772L246.966 217.723L269.507 195.195" stroke="#0A1E46" strokeWidth="2"/>
            <rect x="232.217" y="244.728" width="30.8231" height="30.8049" fill="#D9E4FF" stroke="#D9E4FF"/>
            <path d="M239.01 257.48L246.966 265.431L269.507 242.902" stroke="#0A1E46" strokeWidth="2"/>
            <rect x="232.217" y="292.435" width="30.8231" height="30.8049" fill="#D9E4FF" stroke="#D9E4FF"/>
            <path d="M109.73 116.398C112.817 111.156 121.816 101.788 133.122 106.252C147.254 111.832 139.767 133.404 131.172 126.036C122.888 118.934 145.792 104.73 152.127 120.456C158.462 136.182 144.817 136.689 144.817 130.095C144.817 126.036 157.975 116.905 166.747 138.211" stroke="#0A1E46" strokeWidth="2"/>
            <rect x="214.98" y="341.467" width="140.878" height="99.7155" fill="#FF3D71" stroke="#FF3D71"/>
            <path d="M253.706 385.915C243.448 385.445 233.89 409.645 230.393 421.804H336.469C333.361 403.173 323.414 365.206 308.493 362.382C289.842 358.852 282.848 400.624 271.774 401.212C260.7 401.801 266.529 386.504 253.706 385.915Z" fill="white"/>
            <rect x="397.963" y="341.467" width="59.9942" height="18.8781" fill="#FF3D71" stroke="#FF3D71"/>
            <path d="M409.855 355.42H408.07V346.392H409.855V355.42Z" fill="white"/>
            <path d="M417.967 353.095C417.967 352.698 417.807 352.392 417.486 352.177C417.169 351.962 416.596 351.745 415.765 351.526C414.934 351.307 414.273 351.063 413.783 350.794C412.843 350.277 412.373 349.604 412.373 348.773C412.373 348.045 412.711 347.446 413.386 346.974C414.066 346.503 414.946 346.268 416.027 346.268C416.745 346.268 417.384 346.383 417.946 346.615C418.508 346.846 418.949 347.177 419.27 347.607C419.591 348.033 419.752 348.506 419.752 349.027H417.967C417.967 348.556 417.797 348.188 417.457 347.923C417.122 347.654 416.641 347.52 416.013 347.52C415.428 347.52 414.972 347.63 414.646 347.849C414.325 348.068 414.165 348.374 414.165 348.767C414.165 349.097 414.34 349.374 414.689 349.597C415.038 349.817 415.614 350.031 416.417 350.242C417.219 350.449 417.863 350.687 418.35 350.955C418.836 351.22 419.192 351.526 419.419 351.873C419.645 352.216 419.759 352.619 419.759 353.082C419.759 353.835 419.428 354.434 418.767 354.881C418.111 355.323 417.219 355.544 416.091 355.544C415.345 355.544 414.658 355.424 414.03 355.184C413.407 354.94 412.921 354.606 412.572 354.18C412.227 353.754 412.055 353.258 412.055 352.692H413.846C413.846 353.204 414.04 353.601 414.427 353.882C414.814 354.163 415.369 354.304 416.091 354.304C416.714 354.304 417.181 354.194 417.493 353.975C417.809 353.752 417.967 353.458 417.967 353.095Z" fill="white"/>
            <path d="M427.241 353.095C427.241 352.698 427.081 352.392 426.76 352.177C426.443 351.962 425.87 351.745 425.039 351.526C424.208 351.307 423.547 351.063 423.056 350.794C422.117 350.277 421.647 349.604 421.647 348.773C421.647 348.045 421.985 347.446 422.66 346.974C423.34 346.503 424.22 346.268 425.301 346.268C426.018 346.268 426.658 346.383 427.22 346.615C427.782 346.846 428.223 347.177 428.544 347.607C428.865 348.033 429.025 348.506 429.025 349.027H427.241C427.241 348.556 427.071 348.188 426.731 347.923C426.396 347.654 425.915 347.52 425.287 347.52C424.701 347.52 424.246 347.63 423.92 347.849C423.599 348.068 423.439 348.374 423.439 348.767C423.439 349.097 423.613 349.374 423.963 349.597C424.312 349.817 424.888 350.031 425.69 350.242C426.493 350.449 427.137 350.687 427.623 350.955C428.11 351.22 428.466 351.526 428.693 351.873C428.919 352.216 429.032 352.619 429.032 353.082C429.032 353.835 428.702 354.434 428.041 354.881C427.385 355.323 426.493 355.544 425.365 355.544C424.619 355.544 423.932 355.424 423.304 355.184C422.681 354.94 422.195 354.606 421.846 354.18C421.501 353.754 421.329 353.258 421.329 352.692H423.12C423.12 353.204 423.314 353.601 423.701 353.882C424.088 354.163 424.642 354.304 425.365 354.304C425.988 354.304 426.455 354.194 426.767 353.975C427.083 353.752 427.241 353.458 427.241 353.095Z" fill="white"/>
            <path d="M438.667 346.392V352.425C438.667 353.384 438.316 354.145 437.612 354.707C436.914 355.265 435.981 355.544 434.815 355.544C433.635 355.544 432.698 355.269 432.004 354.719C431.311 354.165 430.964 353.399 430.964 352.419V346.392H432.748V352.431C432.748 353.035 432.923 353.496 433.272 353.814C433.621 354.132 434.136 354.291 434.815 354.291C436.194 354.291 436.883 353.655 436.883 352.382V346.392H438.667Z" fill="white"/>
            <path d="M447.134 351.389H442.9V354.167H447.849V355.42H441.108V346.392H447.8V347.657H442.9V350.149H447.134V351.389Z" fill="white"/>
            <path d="M368.293 368.797L390.834 351.569H406.083" stroke="#FF3D71" strokeWidth="2"/>
            <path d="M371.27 370.121C371.27 372.496 369.343 374.422 366.966 374.422C364.588 374.422 362.662 372.496 362.662 370.121C362.662 367.746 364.588 365.82 366.966 365.82C369.343 365.82 371.27 367.746 371.27 370.121Z" fill="#FF3D71" stroke="#FF3D71" strokeWidth="2"/>
            <path d="M6.30194 238.927C21.6831 216.663 52.0476 205.797 65.3072 203.146C74.3236 211.097 83.2077 207.784 86.5226 205.134C92.8873 203.544 103.76 208.889 108.401 211.76L134.92 228.988C144.644 217.282 164.887 193.075 168.069 189.894C172.047 185.918 176.025 185.256 179.34 183.931C182.655 182.605 195.251 183.268 199.229 185.918C202.412 188.039 200.113 189.894 198.566 190.557C199.008 190.557 200.025 190.954 200.555 192.544C201.086 194.135 198.566 196.299 197.24 197.183C197.682 197.183 198.434 197.713 197.903 199.833C197.373 201.953 194.146 202.484 192.599 202.484C193.262 203.146 194.456 204.736 193.925 205.797C193.395 208.977 187.517 208.447 184.644 207.784C174.478 221.478 152.688 250.323 146.854 256.154C141.02 261.985 132.931 260.793 129.616 259.467L99.1193 242.902L101.771 296.573H38.1251V271.394C33.0422 271.615 20.3571 270.864 10.2798 266.093C0.202522 261.323 0.335118 253.946 1.66108 250.853C1.66108 249.97 2.58925 246.348 6.30194 238.927Z" fill="white"/>
            <path d="M23.541 221.699C41.0437 208.977 58.679 204.029 65.3088 203.146C74.3253 211.097 83.6512 207.784 87.1871 205.134C94.0821 203.013 106.856 210.435 112.38 214.41L130.281 226.337L114.369 251.516L99.1208 242.902L101.773 295.91H38.7896L39.4525 248.203L46.0823 242.902L23.541 221.699Z" fill="#0A1E46" stroke="#0A1E46"/>
            <rect x="0.683181" y="-0.183058" width="10.9285" height="50.7051" rx="0.5" transform="matrix(0.500214 -0.865902 0.866149 0.499786 156.283 184.82)" fill="#0A1E46" stroke="#0A1E46"/>
            <path d="M199.525 208.984L204.992 199.521L206.698 200.506C206.75 200.536 206.796 200.575 206.834 200.621L215.82 211.538C216.114 211.894 215.808 212.424 215.352 212.348L201.399 210.029C201.34 210.019 201.283 209.999 201.232 209.969L199.525 208.984Z" fill="white" stroke="#0A1E46"/>
            <rect x="0.683181" y="-0.183058" width="8.27775" height="2.97731" rx="0.5" transform="matrix(0.500214 -0.865902 0.866149 0.499786 154.648 182.347)" fill="white" stroke="#0A1E46"/>
            <path d="M10.2544 266.093C0.177131 261.323 0.309727 253.946 1.63569 250.854L28.818 242.902L83.1824 253.504L87.8232 256.154L90.4751 258.805L91.8011 264.106C91.8011 265.21 91.5359 267.551 90.4751 268.081C89.1492 268.744 87.8232 265.431 86.4973 263.443C85.4365 261.853 81.6354 261.013 79.8675 260.793C83.4034 265.21 90.21 274.442 89.1492 276.032C88.0884 277.623 81.6354 272.278 78.5415 269.406C80.9724 272.057 85.4365 277.755 83.8453 279.345C82.2542 280.936 73.4586 272.94 69.2598 268.744C71.9117 272.057 76.9503 279.08 75.8896 280.671C74.8288 282.261 64.8399 275.149 59.9781 271.394H38.0997C33.0168 271.615 20.3317 270.864 10.2544 266.093Z" fill="white"/>
            <path d="M187.619 190.847C189.68 193.913 181.86 199.943 178.787 201.832C178.787 206.509 177.014 209.363 174.723 211.097L170.723 196.486C175.498 194.24 184.604 188.602 186.915 190.163C187.22 190.369 187.452 190.598 187.619 190.847Z" fill="white"/>
            <path d="M99.1209 242.24C99.1209 242.24 121.121 256.885 132.392 260.198C147.426 264.617 164.878 235.019 184.767 207.852M195.375 196.588C186.756 196.588 191.397 190.624 191.397 190.624M195.375 196.588C200.983 196.588 201.557 193.136 200.015 191.484C199.531 190.965 198.027 190.624 198.027 189.962M195.375 196.588C198.768 196.588 200.094 202.551 192.723 202.551C190.292 202.772 185.695 202.286 186.756 198.576C187.324 196.588 190.071 195.925 191.397 195.925M191.535 202.547C194.664 202.547 195.887 208.47 189.089 208.47C186.846 208.69 182.607 208.207 183.585 204.521C184.109 202.547 186.643 201.889 187.866 201.889M87.1873 205.135C97.7949 203.147 116.358 217.061 116.358 217.061L134.922 228.988C134.922 228.988 162.56 193.804 168.856 189.299C175.151 184.794 179.463 183.826 186.229 183.826C193.631 183.826 202.703 185.719 200.016 189.299C198.027 191.95 188.082 190.624 188.082 190.624M65.3089 203.147C46.0825 207.343 6.42544 223.755 1.1216 252.909C-0.74328 265.583 19.5231 270.262 38.7899 271.462M77.8136 268.444C81.1406 272.008 84.3744 276.47 83.9941 278.751C83.4637 281.931 74.0494 273.008 68.7455 268.149C71.3975 271.904 78.5201 279.691 75.3753 280.738C73.3864 281.401 68.0826 276.1 60.7898 271.462C54.5591 271.855 46.9211 271.968 38.7899 271.462M77.8136 268.444C76.5013 267.039 75.1746 265.773 74.0494 264.836C75.0879 265.874 76.4004 267.14 77.8136 268.444ZM77.8136 268.444C82.1141 272.415 87.3476 276.743 88.6349 276.1C91.2869 274.775 83.7731 265.94 79.3532 260.86C82.2261 261.302 86.1156 261.258 86.646 262.848C87.309 264.836 88.6349 268.149 90.6239 268.149C92.6128 268.149 91.9498 261.523 89.9609 258.21C89.1891 256.924 85.32 254.234 79.3532 252.909L42.2263 245.773M42.2263 245.773L27.6408 242.97M42.2263 245.773L54.16 237.669M38.7899 271.462V295.978H101.895L98.5796 233.694M171.57 196.066C176.244 193.898 185.157 188.455 187.419 189.962C191.397 192.612 182.715 199.255 179.463 201.226C179.463 205.741 177.728 208.497 175.485 210.171" stroke="#0A1E46"/>
            <path d="M65.3108 203.146V192.545C58.9461 191.484 59.123 186.36 60.0069 183.931L65.3108 163.39L88.5151 161.402C90.725 164.053 95.2775 171.606 95.8078 180.618C96.3382 189.629 89.841 193.207 86.5261 193.87V205.797C76.4488 211.098 68.1837 206.238 65.3108 203.146Z" fill="white"/>
            <path d="M64.7676 187.973C64.9886 186.869 65.4306 185.323 66.0935 184.66" stroke="#0A1E46"/>
            <path d="M83.9941 184.66C84.8781 185.323 87.1764 186.517 89.298 185.986" stroke="#0A1E46"/>
            <path d="M86.6465 171.408C87.0885 173.175 88.4597 176.625 89.9614 177.372C92.4916 178.63 91.8286 181.28 89.9614 181.347H88.6354" stroke="#0A1E46"/>
            <path d="M93.2751 154.843C94.8662 164.385 78.4685 165.445 70.0708 164.782C71.662 171.143 69.065 180.176 68.623 183.268C63.9821 178.63 60.568 182.452 60.1261 183.998C49.5184 170.746 56.1482 162.132 60.789 159.481C69.4078 146.229 91.2861 142.916 93.2751 154.843Z" fill="#0A1E46" stroke="#0A1E46"/>
            <path d="M86.6467 193.937V205.864C82.6688 208.073 72.8567 210.635 65.4313 203.213V192.612M80.6799 194.6C105.078 194.07 95.0444 169.862 86.6467 158.156C61.7186 151.265 60.3483 170.967 62.7792 182.01M65.4313 192.612C62.1162 192.612 58.0059 189.431 60.1275 184.661C62.249 179.89 66.7573 181.789 68.7462 183.335M65.4313 192.612C66.5363 192.612 68.3482 192.214 69.409 190.624" stroke="#0A1E46"/>
            <ellipse cx="80.0154" cy="175.383" rx="1.32596" ry="1.3252" fill="#0A1E46"/>
            <ellipse cx="90.6248" cy="172.733" rx="1.32596" ry="1.3252" fill="#0A1E46"/>
            <rect width="5.30384" height="1.32596" rx="0.66298" transform="matrix(0.847167 -0.531327 0.531762 0.846894 76.0391 172.901)" fill="#0A1E46"/>
            <rect width="4.75717" height="1.32596" rx="0.66298" transform="matrix(-0.998871 -0.0475089 -0.047563 0.998868 91.502 167.943)" fill="#0A1E46"/>
            <path d="M86.6452 199.238L87.3082 193.274L83.9933 193.937H78.6895C80.811 197.648 85.0983 199.238 86.6452 199.238Z" fill="#0A1E46"/>
            <path d="M62.7793 185.323C63.6633 184.66 66.7572 183.997 68.7461 186.649" stroke="#0A1E46"/>
        </svg>

    }
}

export default iconsInspections