import React from 'react'
import {connect} from 'react-redux'

import Chart from 'chart.js'

import IconsStatistics from '../../assets/icons/IconsStatistics'
import {ProgressBar} from "react-bootstrap";

import StatisticsDropDown from "../commons/dropdown/StatisticsDropDown";
import InspectionsActions from "../../actions/InspectionsActions";

class Statistics extends React.Component {
    constructor(props) {
        super(props);

        this.isFullWindow = false;
        this.isResizeWindow = false;

        this.state = {

            width: null,
            height: null,

            filterSelect: {
                organisation: '',
                branch: '',
                inspectionList: this.getLocale().statistics.during_week,
                lineChart: this.getTodayMonth(),
                circleChart: this.getTodayMonth(),
                barChart: this.getTodayYear()
            },

            inspections: null,

            filterData: {
                filterOrganisation:[
                    {
                        id: 0,
                        type: 'Перекресток',

                    },
                    {
                        id: 1,
                        type: 'Пятерочка',
                    }
                ],
                filterBranch:[
                    {
                        id: 0,
                        type: 'Тульская',

                    },
                    {
                        id: 1,
                        type: 'Новокузнецкая',
                    }
                ],
                filterInspectionList:[
                    {
                        id: 0,
                        type: this.getLocale().statistics.during_week,

                    },
                    {
                        id: 1,
                        type: this.getLocale().statistics.during_month,
                    },
                    {
                        id: 2,
                        type: this.getLocale().statistics.for_year,
                    },
                ],
                filterLineChart:[
                    {
                        id: 0,
                        type: 'Апрель',

                    },
                    {
                        id: 1,
                        type: 'Май',
                    },
                    {
                        id: 2,
                        type: 'Июнь',
                    }
                ],
                filterCircleChart:[
                    {
                        id: 0,
                        type: 'Апрель',

                    },
                    {
                        id: 1,
                        type: 'Май',
                    },
                    {
                        id: 2,
                        type: 'Июнь',
                    }
                ],
                filterBarChart:[
                    {
                        id: 0,
                        type: '2020',

                    },
                    {
                        id: 1,
                        type: '2021',
                    }
                ]
            }
        }

        this.props.onGetInspection()
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.inspectionsState.inspections !== nextProps.inspectionsState.inspections
            || this.state.inspections === null) {
            this.setState({
                inspections: nextProps.inspectionsState.inspections
            })
        }

        return true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.isFullWindow && this.state.width > 1700) {
            this.isFullWindow = true
            this.isResizeWindow = false
            this.createLineChart()
            this.createCircleChart()
            this.createBarChart()
        }

        if (!this.isResizeWindow && this.state.width < 1700) {
            this.isResizeWindow = true
            this.isFullWindow = false
            this.createLineChart()
            this.createCircleChart()
            this.createBarChart()
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <div className="row no-gutters">
                                <div className="col">
                                    <span className="title-text">{this.getLocale().statistics.title}</span>
                                </div>
                            </div>
                            <div className="row" style={{marginLeft: '0', marginTop: '20px', marginBottom: '15px'}}>
                                <div className="col-auto filter-title">
                                    <div className="row">
                                        <StatisticsDropDown type={StatisticsDropDown.typesFilter.organisation}
                                                            customStyle={{}}
                                                            filterData={this.state.filterData}
                                                            filterSelect={this.state.filterSelect}
                                                            updateFilterSelect={this.updateFilterSelect}
                                                            updateData={this.updateData}/>
                                    </div>
                                </div>
                                <div className="col-auto filter-title">
                                    <div className="row">
                                        <StatisticsDropDown type={StatisticsDropDown.typesFilter.branch}
                                                            customStyle={{}}
                                                            filterData={this.state.filterData}
                                                            filterSelect={this.state.filterSelect}
                                                            updateFilterSelect={this.updateFilterSelect}
                                                            updateData={this.updateData}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.width > 1700
                            ? <div className="row no-gutters block-diagrams">
                                <div className="col-5">
                                    <div className="row no-gutters">
                                        <div className="col list-inspections-block">
                                            <div className="row title-chart-block no-gutters">
                                                <div className="col chart-line">
                                                    <div className="row">
                                                        <div className="col" style={{marginLeft: '24px'}}>
                                                            <span className="title-chart-text">18 проверок из 20</span>
                                                        </div>
                                                        <div className="col" style={{marginRight: '24px'}}>
                                                            <div className="row no-gutters justify-content-end">
                                                                <StatisticsDropDown type={StatisticsDropDown.typesFilter.inspectionList}
                                                                                    customStyle={{}}
                                                                                    filterData={this.state.filterData}
                                                                                    filterSelect={this.state.filterSelect}
                                                                                    updateFilterSelect={this.updateFilterSelect}
                                                                                    updateData={this.updateData}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <ProgressBar className="progress-bar-statistics" variant="success" now={60} min={0} max={100} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row list-inspections">
                                                <div className="col" style={{marginLeft: '24px', marginRight: '24px'}}>
                                                    {
                                                        this.state.inspections?.map( inspection => {
                                                            return this.getInspectionView(inspection)
                                                        })
                                                    }
                                                    <div className="row justify-content-center">
                                                        <div className="col-auto show-more">
                                                            <span>{this.getLocale().common.show_more}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="row no-gutters">
                                        <div className="col line-inspections">
                                            <div className="row title-chart-block">
                                                <div className="col" style={{marginLeft: '24px'}}>
                                                    <span className="title-chart-text">{this.getLocale().inspections.title}</span>
                                                </div>
                                                <div className="col" style={{marginRight: '24px'}}>
                                                    <div className="row no-gutters justify-content-end">
                                                        <StatisticsDropDown type={StatisticsDropDown.typesFilter.lineChart}
                                                                            customStyle={{}}
                                                                            filterData={this.state.filterData}
                                                                            filterSelect={this.state.filterSelect}
                                                                            updateFilterSelect={this.updateFilterSelect}
                                                                            updateData={this.updateData}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row divide-chart-block" style={{paddingTop: '16px'}}>
                                                <div className="col-auto" style={{marginLeft: '24px'}}>
                                                    <div className="row no-gutters">
                                                        <div className="col-auto">
                                                            {IconsStatistics.getIcnCompleted()}
                                                        </div>
                                                        <div className="col-auto">
                                                            <span className="legend-text">{this.getLocale().statistics.completed_1}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="row no-gutters">
                                                        <div className="col-auto">
                                                            {IconsStatistics.getIcnCanceled()}
                                                        </div>
                                                        <div className="col-auto">
                                                            <span className="legend-text">{this.getLocale().statistics.completed_1}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <canvas className="line-chart" height="200" id="lineChart" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col circle-inspections">
                                            <div className="row title-chart-block">
                                                <div className="col" style={{marginLeft: '24px'}}>
                                                    <span className="title-chart-text">{this.getLocale().inspections.title}</span>
                                                </div>
                                                <div className="col" style={{marginRight: '24px'}}>
                                                    <div className="row no-gutters justify-content-end">
                                                        <StatisticsDropDown type={StatisticsDropDown.typesFilter.circleChart}
                                                                            customStyle={{}}
                                                                            filterData={this.state.filterData}
                                                                            filterSelect={this.state.filterSelect}
                                                                            updateFilterSelect={this.updateFilterSelect}
                                                                            updateData={this.updateData}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center divide-chart-block">
                                                <div className="col">
                                                    <canvas className="circle-chart" height="400" id="circleChart" />
                                                </div>
                                                <div className="col-auto">
                                                    <div className="row h-100 no-gutters">
                                                        <div className="col">
                                                            <div className="row no-gutters">
                                                                <div className="col-auto">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-auto">
                                                                            {IconsStatistics.getIcnCompleted()}
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <span className="legend-text">{this.getLocale().statistics.completed}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters">
                                                                <div className="col-auto">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-auto">
                                                                            {IconsStatistics.getIcnCanceled()}
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <span className="legend-text">{this.getLocale().statistics.canceled}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters" style={{marginTop: '30px'}}>
                                        <div className="col bar-inspections" style={{marginBottom: '80px'}}>
                                            <div className="row title-chart-block">
                                                <div className="col" style={{marginLeft: '24px'}}>
                                                    <div className="row">
                                                        <div className="col-auto" style={{marginRight: '24px'}}>
                                                            <span className="title-chart-text">{this.getLocale().inspections.title}</span>
                                                        </div>
                                                        <div className="row no-gutters">
                                                            <div className="col-auto" style={{marginLeft: '16px', paddingLeft: 0}}>
                                                                <div className="row no-gutters">
                                                                    <div className="col-auto">
                                                                        {IconsStatistics.getIcnCompleted()}
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <span className="legend-text">{this.getLocale().statistics.completed_1}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto" style={{marginLeft: '24px'}}>
                                                                <div className="row no-gutters">
                                                                    <div className="col-auto">
                                                                        {IconsStatistics.getIcnCanceled()}
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <span className="legend-text">{this.getLocale().statistics.canceled_1}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col" style={{marginRight: '24px'}}>
                                                    <div className="row no-gutters justify-content-end">
                                                        <StatisticsDropDown type={StatisticsDropDown.typesFilter.barChart}
                                                                            customStyle={{}}
                                                                            filterData={this.state.filterData}
                                                                            filterSelect={this.state.filterSelect}
                                                                            updateFilterSelect={this.updateFilterSelect}
                                                                            updateData={this.updateData}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row divide-chart-block">
                                                <div className="col">
                                                    <canvas className="bar-chart" id="barChart" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div className="row no-gutters block-diagrams">
                                <div className="col">
                                    <div className="row no-gutters">
                                        <div className="col">
                                            <div className="row no-gutters">
                                                <div className="col list-inspections-block">
                                                    <div className="row title-chart-block">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col" style={{marginLeft: '24px'}}>
                                                                    <span className="title-chart-text">18 проверок из 20</span>
                                                                </div>
                                                                <div className="col" style={{marginRight: '24px'}}>
                                                                    <div className="row no-gutters justify-content-end">
                                                                        <StatisticsDropDown type={StatisticsDropDown.typesFilter.inspectionList}
                                                                                            customStyle={{}}
                                                                                            filterData={this.state.filterData}
                                                                                            filterSelect={this.state.filterSelect}
                                                                                            updateFilterSelect={this.updateFilterSelect}
                                                                                            updateData={this.updateData}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <ProgressBar className="progress-bar-statistics" variant="success" now={60} min={0} max={100} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row list-inspections">
                                                        <div className="col" style={{marginLeft: '24px', marginRight: '24px'}}>
                                                            {
                                                                this.state.inspections && this.state.inspections.map( inspection => {
                                                                    return this.getInspectionView(inspection)
                                                                })
                                                            }
                                                            <div className="row justify-content-center">
                                                                <div className="col-auto show-more">
                                                                    <span>{this.getLocale().common.show_more}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="row no-gutters">
                                                <div className="col line-inspections">
                                                    <div className="row title-chart-block">
                                                        <div className="col" style={{marginLeft: '24px'}}>
                                                            <span className="title-chart-text">{this.getLocale().inspections.title}</span>
                                                        </div>
                                                        <div className="col" style={{marginRight: '24px'}}>
                                                            <div className="row no-gutters justify-content-end">
                                                                <StatisticsDropDown type={StatisticsDropDown.typesFilter.lineChart}
                                                                                    customStyle={{}}
                                                                                    filterData={this.state.filterData}
                                                                                    filterSelect={this.state.filterSelect}
                                                                                    updateFilterSelect={this.updateFilterSelect}
                                                                                    updateData={this.updateData}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row divide-chart-block" style={{paddingTop: '16px'}}>
                                                        <div className="col-auto" style={{marginLeft: '24px'}}>
                                                            <div className="row no-gutters">
                                                                <div className="col-auto">
                                                                    {IconsStatistics.getIcnCompleted()}
                                                                </div>
                                                                <div className="col-auto">
                                                                    <span className="legend-text">{this.getLocale().statistics.completed_1}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="row no-gutters">
                                                                <div className="col-auto">
                                                                    {IconsStatistics.getIcnCanceled()}
                                                                </div>
                                                                <div className="col-auto">
                                                                    <span className="legend-text">{this.getLocale().statistics.canceled_1}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <canvas className="line-chart" height="150" id="lineChart" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col circle-inspections">
                                                    <div className="row title-chart-block">
                                                        <div className="col" style={{marginLeft: '24px'}}>
                                                            <span className="title-chart-text">{this.getLocale().inspections.title}</span>
                                                        </div>
                                                        <div className="col" style={{marginRight: '24px'}}>
                                                            <div className="row no-gutters justify-content-end">
                                                                <StatisticsDropDown type={StatisticsDropDown.typesFilter.circleChart}
                                                                                    customStyle={{}}
                                                                                    filterData={this.state.filterData}
                                                                                    filterSelect={this.state.filterSelect}
                                                                                    updateFilterSelect={this.updateFilterSelect}
                                                                                    updateData={this.updateData}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center divide-chart-block">
                                                        <div className="col">
                                                            <canvas className="circle-chart" height="250" id="circleChart" />
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="row h-100 no-gutters">
                                                                <div className="col">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-auto">
                                                                            <div className="row no-gutters">
                                                                                <div className="col-auto">
                                                                                    {IconsStatistics.getIcnCompleted()}
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <span className="legend-text">{this.getLocale().statistics.completed}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row no-gutters">
                                                                        <div className="col-auto">
                                                                            <div className="row no-gutters">
                                                                                <div className="col-auto">
                                                                                    {IconsStatistics.getIcnCanceled()}
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <span className="legend-text">{this.getLocale().statistics.canceled}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col">
                                            <div className="row no-gutters" style={{marginTop: '30px'}}>
                                                <div className="col bar-inspections" style={{marginBottom: '80px'}}>
                                                    <div className="row title-chart-block">
                                                        <div className="col" style={{marginLeft: '24px'}}>
                                                            <div className="row">
                                                                <div className="col-auto" style={{marginRight: '24px'}}>
                                                                    <span className="title-chart-text">{this.getLocale().inspections.title}</span>
                                                                </div>
                                                                <div className="row no-gutters">
                                                                    <div className="col-auto" style={{marginLeft: '16px', paddingLeft: 0}}>
                                                                        <div className="row no-gutters">
                                                                            <div className="col-auto">
                                                                                {IconsStatistics.getIcnCompleted()}
                                                                            </div>
                                                                            <div className="col-auto">
                                                                                <span className="legend-text">{this.getLocale().statistics.completed_1}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-auto" style={{marginLeft: '24px'}}>
                                                                        <div className="row no-gutters">
                                                                            <div className="col-auto">
                                                                                {IconsStatistics.getIcnCanceled()}
                                                                            </div>
                                                                            <div className="col-auto">
                                                                                <span className="legend-text">{this.getLocale().statistics.canceled_1}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col" style={{marginRight: '24px'}}>
                                                            <div className="row no-gutters justify-content-end">
                                                                <StatisticsDropDown type={StatisticsDropDown.typesFilter.barChart}
                                                                                    customStyle={{}}
                                                                                    filterData={this.state.filterData}
                                                                                    filterSelect={this.state.filterSelect}
                                                                                    updateFilterSelect={this.updateFilterSelect}
                                                                                    updateData={this.updateData}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row divide-chart-block">
                                                        <div className="col">
                                                            <canvas className="bar-chart" height="100" id="barChart" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </section>
        )
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    getLocale() {
        return this.props.appState.locale
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })
    }

    getTodayMonth() {
        const date = new Date();
        return this.capitalizeFirstLetter(date.toLocaleString('default', {month: 'long'}))
    }

    getTodayYear() {
        const date = new Date();
        return date.getFullYear()
    }

    getInspectionView(inspection) {
        return  <div key={inspection.id} className="row inspections-list-inspection">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <span className="inspections-list-title">{inspection.name}</span>
                            </div>
                            <div className="col">
                                <div className="row no-gutters justify-content-end">
                                    <div className="col-auto">
                                        <span className="inspections-list-type">{inspection.type}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <span className="inspections-list-text"><span className="inspections-list-type">{inspection.status}:</span> {inspection.date}</span>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '30px'}}>
                            <div className="col">
                                <div className="row no-gutters">
                                    <div className="col-auto">
                                        <img width="24" height="24" alt=""/>
                                    </div>
                                    <div className="col-auto" style={{marginLeft: '10px'}}>
                                        <span className="inspections-list-text">Валетов Георгий</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row justify-content-end" style={{marginLeft: 0, marginRight: 0}}>
                                    <div className={"col-auto " + this.getColorStatus(inspection.status)}>
                                        <span>{inspection.status}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }

    //TODO убрать кириллицу
    getColorStatus(status) {
        switch (status) {
            case 'Выполнена': return 'status-inspection-completed'
            case 'Назначена': return 'status-inspection-assigned'
            case 'Отменена': return 'status-inspection-canceled'
            default: return ''
        }
    }

    createLineChart() {

        let lineData = {
            labels: ['1 Апр', '4 Апр', '8 Апр', '10 Апр', '16 Апр', '31 Апр', '43 Апр'],
            datasets: [{
                borderColor: '#00D68F',
                backgroundColor: 'transparent',
                pointBackgroundColor: 'white',
                pointHoverBackgroundColor: 'white',
                data: [
                    15,
                    17,
                    10,
                    14,
                    9,
                    18,
                    20
                ]
            }, {
                borderColor: '#FF3D71',
                backgroundColor: 'transparent',
                pointBackgroundColor: 'white',
                pointHoverBackgroundColor: 'white',
                data: [
                    11,
                    12,
                    19,
                    15,
                    10,
                    18,
                    13
                ]
            }]
        };

        let lineChart = document.getElementById('lineChart');

        new Chart(lineChart, {
            type: 'line',
            data: lineData,
            options: {
                legend: {
                    display: false,
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 46
                    }
                },
                elements: {
                    point: {
                        radius: 6,
                        borderWidth: 2,
                        hoverRadius: 6,
                        hoverBorderWidth: 2
                    }
                },
                tooltips: {
                    // Disable the on-canvas tooltip
                    enabled: false,
                    custom: this.getLineAndCircleTooltip()
                },
                responsive: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            padding: 18,
                            fontSize: 13,
                            //labelOffset: 16
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            borderDash: [10, 8],
                            color: '#C4C4C4',
                            zeroLineColor: 'transparent',
                            drawBorder: false,
                            drawTicks: false
                        },
                        ticks: {
                            padding: 28,
                            tickMarkLength: 10,
                            fontSize: 13,
                        }
                    }],
                }
            }
        });

        Chart.pluginService.register({
            beforeDraw: function(chart) {
                if (chart.ctx.canvas.id === 'lineChart' && chart.tooltip._active && chart.tooltip._active.length) {
                    const activePoint = chart.controller.tooltip._active[0];
                    const ctx = chart.ctx;
                    const x = activePoint.tooltipPosition().x;
                    const topY = chart.scales['y-axis-0'].top;
                    const bottomY = chart.scales['y-axis-0'].bottom;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#C2CFE0';
                    ctx.stroke();
                    ctx.restore();
                }
            }
        });
    }

    createCircleChart() {

        let circleData = {
            labels: ['', ''],
            datasets: [{
                borderColor: ['#00D68F', '#FF3D71'],
                backgroundColor: ['#00D68F', '#FF3D71'],
                pointBackgroundColor: 'white',
                pointHoverBackgroundColor: 'white',
                data: [
                    15,
                    17,
                ]
            }]
        };

        let circleChart = document.getElementById('circleChart');

        new Chart(circleChart, {
            type: 'doughnut',
            data: circleData,
            options: {
                legend: {
                    display: false,
                },
                cutoutPercentage: 92,
                layout: {
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                elements: {
                    center: {
                        text: '70%',
                        color: '#00D68F', // Default is #000000
                        sidePadding: 20, // Default is 20 (as a percentage)
                        minFontSize: 50, // Default is 20 (in px), set to false and text will not wrap.
                        lineHeight: 25 // Default is 25 (in px), used for when text wraps
                    },
                    point: {
                        radius: 6,
                        borderWidth: 2,
                        hoverRadius: 6,
                        hoverBorderWidth: 2
                    },
                    arc: {
                        borderWidth: 0
                    }
                },
                tooltips: {
                    // Disable the on-canvas tooltip
                    enabled: false,
                    custom: this.getLineAndCircleTooltip()
                },
                responsive: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            display: false
                        }
                    }],
                }
            }
        });

        Chart.pluginService.register({
            beforeDraw: function(chart) {
                if (chart.config.options.elements.center) {
                    // Get ctx from string
                    var ctx = chart.chart.ctx;

                    // Get options from the center object in options
                    var centerConfig = chart.config.options.elements.center;
                    var fontStyle = centerConfig.fontStyle || 'Arial';
                    var txt = centerConfig.text;
                    var color = centerConfig.color || '#000';
                    var maxFontSize = centerConfig.maxFontSize || 75;
                    var sidePadding = centerConfig.sidePadding || 20;
                    var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                    // Start with a base font of 30px
                    ctx.font = "30px " + fontStyle;

                    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    var stringWidth = ctx.measureText(txt).width;
                    var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                    // Find out how much the font can grow in width.
                    var widthRatio = elementWidth / stringWidth;
                    var newFontSize = Math.floor(30 * widthRatio);
                    var elementHeight = (chart.innerRadius * 2);

                    // Pick a new font size so it will not be larger than the height of label.
                    var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                    var minFontSize = centerConfig.minFontSize;
                    var lineHeight = centerConfig.lineHeight || 25;
                    var wrapText = false;

                    if (minFontSize === undefined) {
                        minFontSize = 20;
                    }

                    if (minFontSize && fontSizeToUse < minFontSize) {
                        fontSizeToUse = minFontSize;
                        wrapText = true;
                    }

                    // Set font settings to draw it correctly.
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    ctx.font = fontSizeToUse + "px " + fontStyle;
                    ctx.fillStyle = color;

                    if (!wrapText) {
                        ctx.fillText(txt, centerX, centerY);
                        return;
                    }

                    var words = txt.split(' ');
                    var line = '';
                    var lines = [];

                    // Break words up into multiple lines if necessary
                    for (var n = 0; n < words.length; n++) {
                        var testLine = line + words[n] + ' ';
                        var metrics = ctx.measureText(testLine);
                        var testWidth = metrics.width;
                        if (testWidth > elementWidth && n > 0) {
                            lines.push(line);
                            line = words[n] + ' ';
                        } else {
                            line = testLine;
                        }
                    }

                    // Move the center up depending on line height and number of lines
                    centerY -= (lines.length / 2) * lineHeight;

                    for (let n = 0; n < lines.length; n++) {
                        ctx.fillText(lines[n], centerX, centerY);
                        centerY += lineHeight;
                    }
                    //Draw text in center
                    ctx.fillText(line, centerX, centerY);
                }
            }
        });
    }

    createBarChart() {

        let barData = {
            labels: ['1 Апр', '4 Апр', '8 Апр', '10 Апр', '16 Апр', '31 Апр', '43 Апр'],
            datasets: [{
                borderColor: '#00D68F',
                backgroundColor: '#00D68F',
                pointBackgroundColor: 'white',
                pointHoverBackgroundColor: 'white',
                stack: '0',
                data: [
                    15,
                    17,
                    10,
                    14,
                    9,
                    18,
                    20
                ]
            }, {
                borderColor: '#FF3D71',
                backgroundColor: '#FF3D71',
                pointBackgroundColor: 'white',
                pointHoverBackgroundColor: 'white',
                stack: '0',
                data: [
                    11,
                    12,
                    19,
                    15,
                    10,
                    18,
                    13
                ]
            }]
        };

        let barChart = document.getElementById('barChart');

        new Chart(barChart, {
            type: 'bar',
            data: barData,
            options: {
                legend: {
                    display: false,
                },
                tooltips: {
                    // Disable the on-canvas tooltip
                    enabled: false,
                    custom: this.getBarTooltip()
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 40
                    }
                },
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    },
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            padding: 18,
                            fontSize: 13,
                            //labelOffset: 16
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            borderDash: [10, 8],
                            zeroLineBorderDash: [10, 8],
                            color: '#C4C4C4',
                            drawBorder: false,
                            drawTicks: false
                        },
                        ticks: {
                            padding: 28,
                            tickMarkLength: 10,
                            fontSize: 13,
                        }
                    }],
                }
            }
        });
    }

    getLineAndCircleTooltip() {
        return function(tooltipModel) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table/>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                let bodyLines = tooltipModel.body.map(getBody);
                let innerHtml = '<thead>';
                innerHtml += '</thead><tbody>';
                bodyLines.forEach(function (body, i) {

                    let style = 'color: white; font-size: 13px;'
                    let span = '<span style="' + style + '">' + body.toString().replace( /^\D+/g, '') + '</span>';
                    let styleTd = "border-radius: 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px; " +
                        "padding-bottom: 5px; background-color: " + tooltipModel.labelColors[i].borderColor
                    innerHtml += '<tr><td style="' + styleTd + '">' + span + '</td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            let position = this._chart.canvas.getBoundingClientRect();
            let sizeElement = tableRoot.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - sizeElement.height + 5 + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'cursor';
        }
    }

    getBarTooltip() {
        return function(tooltipModel) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table/>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                let bodyLines = tooltipModel.body.map(getBody);
                let innerHtml = '<thead>';
                innerHtml += '</thead><tbody>';
                bodyLines.forEach(function (body, i) {

                    let style = 'color: white; font-size: 13px;'
                    let span = '<span style="' + style + '">' + body.toString().replace( /^\D+/g, '') + '</span>';
                    let styleTd = "border: 1px solid white; border-radius: 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px; " +
                        "padding-bottom: 5px; background-color: " + tooltipModel.labelColors[i].borderColor
                    let div = '<div style="' + styleTd + '">' + span + '</div>'
                    innerHtml += '<tr><td>' + div + '</td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            let position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + (tooltipModel.height / 2) + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.pointerEvents = 'cursor';
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    updateData = () => {
        let newFiltered = this.props.inspectionsState.inspections.slice()
        if (this.state.filterSelect.organisation) {
            newFiltered = newFiltered.filter(value => value.organisation === this.state.filterSelect.organisation)
        }
        if (this.state.filterSelect.branch) {
            newFiltered = newFiltered.filter(value => value.branch === this.state.filterSelect.branch)
        }

        this.setState({
            inspections: newFiltered
        })
    }

}

export default connect(
    state => ({
        inspectionsState: state.inspections,
        appState: state.app
    }),
    dispatch => ({
        onGetInspection: () => {
            InspectionsActions.getInspections(dispatch)
        }
    })
)(Statistics);