import React from 'react'
import {connect} from 'react-redux'
import DialogComment from '../commons/dialog/DialogComment';
import DialogImage from '../commons/dialog/DialogImage'

import {calculateAspectRatioFit} from "../utils/ImageUtil"
import InspectionsActions from "../../actions/InspectionsActions";
import * as typeConstants from "../../constants/TypeConstants";
import * as statusConstants from "../../constants/StatusConstants";

class RecheckSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowDialogComment: false,
            isShowDialogImage: false,
            pathImage: '',
            comment: '',
            images: []
        }

        this.commentData = new Date().toLocaleString();

        this.props.onGetInspection(this.props.match.params.id).then()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.inspectionsState.inspection !== prevProps.inspectionsState.inspection
            && this.props.inspectionsState.inspection !== null)
            this.props.inspectionsState.inspection.uploads.forEach(upload => {
                this.reconstructImage(upload.id, upload.url)
            })
    }

    render() {
        return (
            <section className="col inspection-select">
                <div className="row inspection-select-offset">
                    <div className="col-7">
                        <div className="row inspection-select-title-block">
                            <span className="inspection-select-title">{this.getLocale().inspection.title}</span>
                        </div>
                        {
                            this.props.inspectionsState.inspection?.state === statusConstants.FINISHED
                            || this.props.inspectionsState.inspection?.state === statusConstants.REJECTED
                                ? <div className="row h-100">
                                    <div className="col">
                                        {
                                            <div className="row task-card-block">
                                                <div className="col">
                                                    <div className="row title-card-block">
                                                        <div className="col">
                                                            <span>{this.getLocale().inspection.recheck_question}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row select-block">
                                                        <div className="col">
                                                            <div className="row no-gutters select-one">
                                                                <div className="col-auto">
                                                                    <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                                                        <input type="radio"
                                                                               defaultChecked={this.props.inspectionsState.inspection?.state === statusConstants.FINISHED}
                                                                               disabled />
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto select-text">
                                                                    <div className="row h-100 no-gutters align-items-center">
                                                                        <span className={this.props.inspectionsState.inspection?.state === statusConstants.FINISHED
                                                                            ? "text-select-checked"
                                                                            : "text-select"}>
                                                                            {this.getLocale().inspection.approved}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters select-one">
                                                                <div className="col-auto">
                                                                    <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                                                        <input type="radio"
                                                                               defaultChecked={this.props.inspectionsState.inspection?.state === statusConstants.REJECTED}
                                                                               disabled />
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto select-text">
                                                                    <div className="row h-100 no-gutters align-items-center">
                                                                        <span className={this.props.inspectionsState.inspection?.state === statusConstants.REJECTED
                                                                            ? "text-select-checked"
                                                                            : "text-select"}>
                                                                            {this.getLocale().inspection.rejected}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row comment-block">
                                                        {
                                                            this.props.inspectionsState.inspection?.comment
                                                                ?   <div className="col">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <span className="comment-title-text">{this.getLocale().common.comment}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col divide-comment">
                                                                            <span className="comment-text">{this.props.inspectionsState.inspection?.comment}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>

                                                    <div className="row select-image-block">
                                                        {
                                                            this.props.inspectionsState.inspection?.uploads.map(upload => {
                                                                return <div key={upload.id} className="col-auto">
                                                                    <div className="row">
                                                                        {
                                                                            this.state.images.find((i) => i.id === upload.id.toString())
                                                                                ? this.state.images.find((i) => i.id === upload.id.toString()).elements.map( (image, index) => {
                                                                                    return  <div key={index} className="col-auto">
                                                                                        <img onClick={
                                                                                            () => this.showDialogImage(image.src)
                                                                                        }
                                                                                             className="select-image"
                                                                                             src={image.src}
                                                                                             width={image.width}
                                                                                             height={image.height} alt="" />
                                                                                    </div>
                                                                                })
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div className="col-5">
                        <div className="block-info">
                            <div className="col-auto">
                                <div className="row align-items-center info-row-offset">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <span className="info-name-label">{this.getLocale().inspections.table_header.name}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="info-name">{this.props.inspectionsState.inspection?.displayName}</span>
                                    </div>
                                </div>
                                <div className="row align-items-center info-row-offset">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <span className="info-name-label">{this.getLocale().inspections.table_header.branch}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="info-name">{this.props.inspectionsState.inspection?.branch.display_name}</span>
                                    </div>
                                </div>
                                <div className="row align-items-center info-row-offset">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <span className="info-name-label">{this.getLocale().inspections.table_header.type}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="info-name">
                                            {typeConstants.getTypeForApp(this.props.inspectionsState.inspection?.type, this.getLocale()).displayName }
                                        </span>
                                    </div>
                                </div>
                                <div className="row align-items-center info-row-offset">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <span className="info-name-label">{this.getLocale().inspections.table_header.inspector}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="info-name">{this.props.inspectionsState.inspection?.user.display_name}</span>
                                    </div>
                                </div>
                                <div className="row align-items-center info-row-offset">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <span className="info-name-label">{this.getLocale().inspections.table_header.phone}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="info-name">{this.props.inspectionsState.inspection?.user.phone}</span>
                                    </div>
                                </div>
                                <div className="row info-row-offset">
                                    <div className="col-3">
                                        <div className="row justify-content-end" style={{marginTop: '3px'}}>
                                            <span className="info-name-label">{this.getLocale().inspections.table_header.history_status}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        {
                                            this.props.inspectionsState.inspection?.historyStatus.map(value => {
                                                return  <div className="row" key={value.id}>
                                                    <div className="col-auto">
                                                        <span className="info-name">{`${value.time} - ${statusConstants.getStatusForApp(value.status, this.getLocale()).displayName}`}</span>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="row justify-content-center align-items-center info-row-offset">
                                    {
                                        this.props.inspectionsState.inspection?.comments.length > 0 || this.state.comment
                                            ?
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-3" />
                                                    <div className="col-auto">
                                                        <div className="row" style={{marginTop: '30px', marginBottom: '10px'}}>
                                                            <div className="col-auto">
                                                                <span className="info-title-comment">{this.getLocale().common.comment}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row no-gutters divide-comment-info" style={{paddingBottom: '10px'}}>
                                                            <div className="col-auto">
                                                                <span className="info-comment">
                                                                    {this.props.inspectionsState.inspection.comments.length <= 0
                                                                        ? this.state.comment : this.props.inspectionsState.inspection.comments[0].text}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{paddingTop: '20px'}}>
                                                            <div className="col-auto">
                                                                <span className="info-article-comment">
                                                                    {this.commentData + " " + this.props.loggedUser?.family + ' ' + this.props.loggedUser?.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                                <div className="col-auto">
                                                    <button type="button"
                                                        className="button-add-comment"
                                                        onClick={this.showDialogComment}>
                                                        {this.getLocale().common.add_comment}
                                                    </button>
                                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isShowDialogComment
                        ? <DialogComment isShow={true} onHide={this.hideDialogComment} clickSave={this.addComment} />
                        : null
                }
                {
                    this.state.isShowDialogImage
                        ? <DialogImage path={this.state.pathImage} isShow={true} onHide={this.hideDialogImage}/>
                        : null
                }
            </section>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    showDialogComment = () => {
        this.setState({
            isShowDialogComment: true
        })
    }

    hideDialogComment = () => {
        this.setState({
            isShowDialogComment: false
        })
    }

    showDialogImage = (path) => {
        this.setState({
            isShowDialogImage: true,
            pathImage: path
        })
    }

    hideDialogImage = () => {
        this.setState({
            isShowDialogImage: false
        })
    }

    addComment = (comment) => {
        this.setState({
            comment: comment
        })

        this.props.onAddComment(this.props.match.params.id, comment).then()
    }

    reconstructImage(id, image) {
        let img = new Image();
        img.onload = (g) => {
            let imageSrc = null

            if (g.hasOwnProperty('path') && g.path.length > 0) {
                imageSrc = g.path[0]
            }

            if (g.target) {
                imageSrc = g.target
            }

            if (imageSrc) {
                let sizeImage = calculateAspectRatioFit(imageSrc.width, imageSrc.height, 160, 160)
                let newImages = this.state.images.slice()
                let idEmpty = {id: imageSrc.tag.toString(), elements: []}

                if (!newImages.find((i) => i.id === imageSrc.tag.toString())) {
                    newImages.push(idEmpty)
                }

                newImages.find((i) => i.id === imageSrc.tag.toString()).elements.push(
                    {
                        src: imageSrc.src,
                        width: sizeImage.width,
                        height: sizeImage.height
                    }
                )

                this.setState({
                    images: newImages
                })
            }
        }
        img.src = image
        img.tag = id
    }
}

export default connect(
    state => ({
        appState: state.app,
        inspectionsState: state.inspections,
        loggedUser: state.users.loggedUser
    }),
    dispatch => ({
        onGetInspection: async (id) => {
            await InspectionsActions.getInspection(dispatch, id, typeConstants.SECONDARY)
        },
        onAddComment: async (id, text) => {
            await InspectionsActions.addComment(dispatch, id, typeConstants.SECONDARY, text)
        }
    })
)(RecheckSelect);