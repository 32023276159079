//type inside error
export const ERROR_COMPONENT = 'error_component'
export const ERROR_COMMON = 'error_common'

//type error
export const NOT_AUTH = 401
export const NOT_FOUND = 404
export const INTERNAL_SERVER_ERROR = 500
export const UNPROCESSABLE_ENTITY = 422

export const NOT_FOUND_PHONE = 'not_found_phone'
export const WRONG_CODE = 'wrong_code'