import React from "react"
import {DateRangePicker} from "@material-ui/pickers"
import {Box, InputLabel, TextField} from "@material-ui/core";
import {LocalizationProvider} from '@material-ui/pickers';
import DateFns from "@date-io/date-fns";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class DateRangeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dateRange: this.props.val,
            minDate: this.props.minDate,
            maxDate: this.props.maxDate
        }
    }

    handleDateChange = (e) => {
        if (e[0] !== null && e[1] !== null) {
            this.setState({
                dateRange: [e[0], e[1]]
            })
            this.props.setVal([e[0] || new Date(), e[1] || new Date()])
        }
    }

    render() {
        return <LocalizationProvider dateAdapter={DateFns} locale={this.props.appState.dateFnsLocale}>
            <DateRangePicker
                className="date-popper"
                startText={this.getLocale().common.period_from}
                endText={this.getLocale().common.period_to}
                value={this.state.dateRange}
                minDate={this.state.minDate}
                maxDate={this.state.maxDate}
                onChange={this.handleDateChange}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <Box className="date-range-text-field-box" style={{marginLeft: 0}}>
                            <InputLabel shrink id="select-cities-statistic-field"
                                        className="date-range-label">
                                {this.getLocale().common.period_from}
                            </InputLabel>
                            <TextField className="date-range-text-field" inputProps={startProps.inputProps}/>
                        </Box>
                        <Box className="date-range-text-field-box">
                            <InputLabel shrink id="select-cities-statistic-field"
                                        className="date-range-label">
                                {this.getLocale().common.period_to}
                            </InputLabel>
                            <TextField className="date-range-text-field" inputProps={endProps.inputProps}/>
                        </Box>
                    </React.Fragment>
                )}
            />
        </LocalizationProvider>
    }

    getLocale() {
        return this.props.appState.locale
    }

    componentDidMount() {
        const { childRef } = this.props;
        childRef && childRef(this);
    }
    componentWillUnmount() {
        const { childRef } = this.props;
        childRef && childRef(undefined);
    }
}

DateRangeComponent.propTypes = {
    val: PropTypes.array.isRequired,
    setVal: PropTypes.func.isRequired,
    minDate: PropTypes.any,
    maxDate: PropTypes.any
}

export default connect(
    state => ({
        appState: state.app
    })
)(DateRangeComponent);