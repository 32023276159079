import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import * as actionConstants from "../actions/ActionConstants";
import {getInspectionsApi} from "../network/api/InspectionsApi";
import {getRechecksApi} from "../network/api/RechecksApi";
import {getBranchesApi} from "../network/api/BranchesApi";
import {getMyOrganizationApi} from "../network/api/OrganizationsApi";
import {getUsersApi} from "../network/api/UsersApi";
import * as typeConstants from "../constants/TypeConstants";

function* getInspections(action) {
    const {limit, filterType, filterBranch, filterState, filterInspector, displayName, executeSince, executeTill} = action.payload
    try {
        const result = yield getInspectionsFromServer(limit, 0, 0, filterType, filterBranch, filterState, filterInspector, displayName, executeSince, executeTill)

        yield put({
            type: actionConstants.SUCCESS_LOAD_INSPECTIONS, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_INSPECTIONS, payload: {
                error: error
            }
        })
    }
}

function* loadYetInspections(action) {
    const {limit, filterType, filterBranch, filterState, filterInspector, displayName, executeSince, executeTill} = action.payload
    const inspectionsState = yield select(state => state.inspections)
    const offsetPrimary = inspectionsState.inspectionsPrimary.length
    const offsetRecheck = inspectionsState.inspectionsRecheck.length
    try {
        const data = yield getInspectionsFromServer(limit, offsetPrimary, offsetRecheck, filterType, filterBranch, filterState, filterInspector, displayName, executeSince, executeTill)
        const result = {
            inspectionsPrimary: inspectionsState.inspectionsPrimary.concat(data.inspectionsPrimary),
            inspectionsRecheck: inspectionsState.inspectionsRecheck.concat(data.inspectionsRecheck),
            inspections: inspectionsState.inspections.concat(data.inspections)
        }

        yield put({
            type: actionConstants.SUCCESS_LOAD_INSPECTIONS, payload: {
                data: result
            }
        })

        if (data.inspections.length === 0) {
            yield put({
                type: actionConstants.END_LOAD_YET_INSPECTIONS, payload: {}
            })
        }

    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_INSPECTIONS, payload: {
                error: error
            }
        })
    }
}

function* getInspectionsFromServer(limit,
                                   offsetPrimary,
                                   offsetRecheck,
                                   filterType,
                                   filterBranch,
                                   filterState,
                                   filterInspector,
                                   displayName,
                                   executeSince,
                                   executeTill) {
    const responseInspections = yield call(getInspectionsApi, limit, offsetPrimary, filterBranch, filterState, filterInspector, displayName, executeSince, executeTill)
    const inspections = responseInspections.data

    const responseRechecks = yield call(getRechecksApi, limit, offsetRecheck, filterBranch, filterState, filterInspector, displayName, executeSince, executeTill)
    const rechecks = responseRechecks.data

    const responseBranch = yield call(getBranchesApi)
    const branches = responseBranch.data

    const organizationResponse = yield call(getMyOrganizationApi)
    const organization = organizationResponse.data

    const responseUsers = yield call(getUsersApi)
    const users = responseUsers.data

    let resultInspections = inspections.map(item => { return {
        id: item.id,
        select: false,
        branchId: item.branch_id,
        branch: branches.find(branch => branch.id === item.branch_id),
        cancelReasonId: item.cancel_reason_id,
        createdAt: new Date(item.created_at),
        organizationId: item.entity_id,
        organization: organization,
        executedAt: new Date(item.execute_at),
        displayName: item.form_display_name,
        formId: item.form_id,
        formVersionId: item.form_version_id,
        isDeleted: item.is_deleted,
        state: item.state,
        type: typeConstants.PRIMARY,
        updatedAt: new Date(item.updated_at),
        userId: item.user_id,
        user: users.find(user => user.id === item.user_id)
    }})

    let resultRechecks = rechecks.map(item => { return {
        id: item.id,
        select: false,
        taskId: item.task_id,
        task: item.task,
        branchId: item.branch_id,
        branch: branches.find(branch => branch.id === item.branch_id),
        cancelReasonId: item.cancel_reason_id,
        createdAt: new Date(item.created_at),
        organizationId: item.entity_id,
        organization: organization,
        executedAt: new Date(item.execute_at),
        displayName: item.task.display_name,
        isDeleted: item.is_deleted,
        state: item.state,
        type: typeConstants.SECONDARY,
        updatedAt: new Date(item.updated_at),
        userId: item.user_id,
        user: users.find(user => user.id === item.user_id)
    }})

    if (filterType) {
        resultInspections = resultInspections.filter(item => item.type === filterType)
        resultRechecks = resultRechecks.filter(item => item.type === filterType)
    }

    return {
        inspectionsPrimary: resultInspections,
        inspectionsRecheck: resultRechecks,
        inspections: resultInspections.concat(resultRechecks)
    }
}

export default function* inspectionsSaga() {
    yield takeEvery(actionConstants.START_LOAD_INSPECTIONS, getInspections)
    yield takeEvery(actionConstants.LOAD_YET_INSPECTIONS, loadYetInspections)
}