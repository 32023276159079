import client from "../client";
import {buildUrl} from "../../components/utils/ApiUtil";

export async function getInspectionApi(id) {
    return client.get(
        '/api/inspections/' + id)
}

export async function getInspectionsApi(limit,
                                        offset,
                                        filterBranch,
                                        filterState,
                                        filterInspector,
                                        displayName,
                                        executeSince,
                                        executeTill) {
    return client.get(buildUrl('/api/inspections', {
        limit: limit,
        offset: offset || 0,
        branch_ids: filterBranch || '',
        states: filterState || '',
        inspector_ids: filterInspector || '',
        display_name: displayName || '',
        execute_since: executeSince || '',
        execute_till: executeTill || ''
    }))
}

export async function getInspectionAnswersApi(id) {
    return client.get(
        '/api/inspections/' + id + '/answers')
}

export async function getInspectionFormApi(id) {
    return client.get(
        '/api/inspections/' + id + '/form'
    )
}

export async function createInspectionsApi(data) {
    return client.post(
      "/api/inspections",
      data,
    );
}
