//app
export const CHECK_LOCAL = 'check_local'

//auth
export const START_REQUEST_CODE = 'start_request_code'
export const SUCCESS_REQUEST_CODE = 'success_request_code'
export const ERROR_REQUEST_CODE = 'error_request_code'

export const START_CREATE_TOKEN = 'start_create_token'
export const SUCCESS_CREATE_TOKEN = 'success_create_token'
export const ERROR_CREATE_TOKEN = 'error_create_token'

export const START_RENEW_TOKEN = 'start_renew_token'
export const SUCCESS_RENEW_TOKEN = 'success_renew_token'
export const ERROR_RENEW_TOKEN = 'error_renew_token'

//inspections
export const START_LOAD_INSPECTIONS = 'start_load_inspections'
export const SUCCESS_LOAD_INSPECTIONS = 'success_load_inspections'
export const ERROR_LOAD_INSPECTIONS = 'error_load_inspections'

export const START_LOAD_INSPECTION = 'start_load_inspection'
export const SUCCESS_LOAD_INSPECTION = 'success_load_inspection'
export const ERROR_LOAD_INSPECTION = 'error_load_inspection'

export const START_LOAD_INSPECTION_ANSWER = 'start_load_inspection_answer'
export const SUCCESS_LOAD_INSPECTION_ANSWER = 'success_load_inspection_answer'
export const ERROR_LOAD_INSPECTION_ANSWER = 'error_load_inspection_answer'

export const START_CREATE_INSPECTION = 'start_create_inspection'
export const SUCCESS_CREATE_INSPECTION = 'success_create_inspection'
export const ERROR_CREATE_INSPECTION = 'error_create_inspection'

export const START_ADD_COMMENT_INSPECTION = 'start_add_comment_inspection'
export const SUCCESS_ADD_COMMENT_INSPECTION = 'success_add_comment_inspection'
export const ERROR_ADD_COMMENT_INSPECTION = 'error_add_comment_inspection'

export const START_CANCEL_INSPECTION = 'start_cancel_inspection'
export const SUCCESS_CANCEL_INSPECTION = 'success_cancel_inspection'
export const ERROR_CANCEL_INSPECTION = 'error_cancel_inspection'

//uploads
export const START_LOAD_UPLOADS = 'start_load_uploads'
export const SUCCESS_LOAD_UPLOADS = 'success_load_uploads'
export const ERROR_LOAD_UPLOADS = 'error_load_uploads'

export const START_LOAD_UPLOAD = 'start_load_upload'
export const SUCCESS_LOAD_UPLOAD = 'success_load_upload'
export const ERROR_LOAD_UPLOAD = 'error_load_upload'

export const START_CREATE_UPLOAD = 'start_create_upload'
export const SUCCESS_CREATE_UPLOAD = 'success_create_upload'
export const ERROR_CREATE_UPLOAD = 'error_create_upload'

export const CLEAR_UPLOAD = 'clear_upload'

//tasks
export const START_LOAD_TASKS = 'start_load_tasks'
export const SUCCESS_LOAD_TASKS = 'success_load_tasks'
export const ERROR_LOAD_TASKS = 'error_load_tasks'

export const START_LOAD_TASK = 'start_load_task'
export const SUCCESS_LOAD_TASK = 'success_load_task'
export const ERROR_LOAD_TASK = 'error_load_task'

export const START_ADD_COMMENT_TASK = 'start_add_comment_task'
export const SUCCESS_ADD_COMMENT_TASK = 'success_add_comment_task'
export const ERROR_ADD_COMMENT_TASK = 'error_add_comment_task'

export const START_CANCEL_TASK = 'start_cancel_task'
export const SUCCESS_CANCEL_TASK = 'success_cancel_task'
export const ERROR_CANCEL_TASK = 'error_cancel_task'

//users
export const START_LOAD_USERS = 'get_start_load_users'
export const SUCCESS_LOAD_USERS = 'get_success_load_users'
export const ERROR_LOAD_USERS = 'get_error_load_users'

export const START_LOAD_USER = 'get_start_load_user'
export const SUCCESS_LOAD_USER = 'get_success_load_user'
export const ERROR_LOAD_USER = 'get_error_load_user'

export const START_LOAD_ME = 'get_start_load_me'
export const SUCCESS_LOAD_ME = 'get_success_load_me'
export const ERROR_LOAD_ME = 'get_error_load_me'

export const START_CREATE_USER = 'start_create_user'
export const SUCCESS_CREATE_USER = 'success_create_user'
export const ERROR_CREATE_USER = 'error_create_user'

export const START_UPDATE_USER = 'start_update_user'
export const SUCCESS_UPDATE_USER = 'success_update_user'
export const ERROR_UPDATE_USER = 'error_update_user'

export const START_DELETE_USER = 'start_delete_user'
export const SUCCESS_DELETE_USER = 'success_delete_user'
export const ERROR_DELETE_USER = 'error_delete_user'

export const START_GET_TOKEN_ANOTHER_USER = 'start_get_token_another_user'
export const SUCCESS_GET_TOKEN_ANOTHER_USER = 'success_get_token_another_user'
export const ERROR_GET_TOKEN_ANOTHER_USER = 'error_get_token_another_user'

//cancel reasons
export const START_LOAD_CANCEL_REASONS = 'get_start_load_cancel_reasons'
export const SUCCESS_LOAD_CANCEL_REASONS = 'get_success_load_cancel_reasons'
export const ERROR_LOAD_CANCEL_REASONS = 'get_error_load_cancel_reasons'

export const START_LOAD_CANCEL_REASON = 'get_start_load_cancel_reason'
export const SUCCESS_LOAD_CANCEL_REASON = 'get_success_load_cancel_reason'
export const ERROR_LOAD_CANCEL_REASON = 'get_error_load_cancel_reason'

export const START_CREATE_CANCEL_REASON = 'get_start_create_cancel_reason'
export const SUCCESS_CREATE_CANCEL_REASON = 'get_success_create_cancel_reason'
export const ERROR_CREATE_CANCEL_REASON = 'get_error_create_cancel_reason'

export const START_DELETE_CANCEL_REASON = 'get_start_delete_cancel_reason'
export const SUCCESS_DELETE_CANCEL_REASON = 'get_success_delete_cancel_reason'
export const ERROR_DELETE_CANCEL_REASON = 'get_error_delete_cancel_reason'

//sections
export const START_LOAD_SECTIONS = 'get_start_load_sections'
export const SUCCESS_LOAD_SECTIONS = 'get_success_load_sections'
export const ERROR_LOAD_SECTIONS = 'get_error_load_sections'

export const START_LOAD_SECTION = 'get_start_load_section'
export const SUCCESS_LOAD_SECTION = 'get_success_load_section'
export const ERROR_LOAD_SECTION = 'get_error_load_section'

export const START_CREATE_SECTION = 'get_start_create_section'
export const SUCCESS_CREATE_SECTION = 'get_success_create_section'
export const ERROR_CREATE_SECTION = 'get_error_create_section'

export const START_DELETE_SECTION = 'get_start_delete_section'
export const SUCCESS_DELETE_SECTION = 'get_success_delete_section'
export const ERROR_DELETE_SECTION = 'get_error_delete_section'

//branches
export const START_LOAD_BRANCHES = 'get_start_load_branches'
export const SUCCESS_LOAD_BRANCHES = 'get_success_load_branches'
export const ERROR_LOAD_BRANCHES = 'get_error_load_branches'

export const START_LOAD_BRANCH = 'get_start_load_branch'
export const SUCCESS_LOAD_BRANCH = 'get_success_load_branch'
export const ERROR_LOAD_BRANCH = 'get_error_load_branch'

export const START_CREATE_BRANCH = 'get_start_create_branch'
export const SUCCESS_CREATE_BRANCH = 'get_success_create_branch'
export const ERROR_CREATE_BRANCH = 'get_error_create_branch'

export const START_DELETE_BRANCH = 'get_start_delete_branch'
export const SUCCESS_DELETE_BRANCH = 'get_success_delete_branch'
export const ERROR_DELETE_BRANCH = 'get_error_delete_branch'

//organizations
export const START_LOAD_ORGANIZATIONS = 'start_load_organizations'
export const SUCCESS_LOAD_ORGANIZATIONS = 'get_success_load_organizations'
export const ERROR_LOAD_ORGANIZATIONS = 'get_error_load_organizations'

export const START_LOAD_ORGANIZATION = 'get_start_load_organization'
export const SUCCESS_LOAD_ORGANIZATION = 'get_success_load_organization'
export const ERROR_LOAD_ORGANIZATION = 'get_error_load_organization'

export const START_CREATE_ORGANIZATION = 'get_start_create_organization'
export const SUCCESS_CREATE_ORGANIZATION = 'get_success_create_organization'
export const ERROR_CREATE_ORGANIZATION = 'get_error_create_organization'

export const START_DELETE_ORGANIZATION = 'get_start_delete_organization'
export const SUCCESS_DELETE_ORGANIZATION = 'get_success_delete_organization'
export const ERROR_DELETE_ORGANIZATION = 'get_error_delete_organization'

//templates
export const START_LOAD_TEMPLATES = 'start_load_templates'
export const SUCCESS_LOAD_TEMPLATES = 'get_success_load_templates'
export const ERROR_LOAD_TEMPLATES = 'get_error_load_templates'

export const START_LOAD_TEMPLATE = 'get_start_load_template'
export const SUCCESS_LOAD_TEMPLATE = 'get_success_load_template'
export const ERROR_LOAD_TEMPLATE = 'get_error_load_template'

export const START_CREATE_TEMPLATE = 'start_create_template'
export const SUCCESS_CREATE_TEMPLATE = 'success_create_template'
export const ERROR_CREATE_TEMPLATE = 'error_create_template'

export const START_UPDATE_TEMPLATE = 'start_update_template'
export const SUCCESS_UPDATE_TEMPLATE = 'success_update_template'
export const ERROR_UPDATE_TEMPLATE = 'error_update_template'

export const START_DELETE_TEMPLATE = 'start_delete_template'
export const SUCCESS_DELETE_TEMPLATE = 'success_delete_template'
export const ERROR_DELETE_TEMPLATE = 'error_delete_template'

export const START_UPDATE_CUSTOM_FIELDS_TEMPLATE = 'start_update_custom_fields_template'
export const SUCCESS_UPDATE_CUSTOM_FIELDS_TEMPLATE = 'success_update_custom_fields_template'
export const ERROR_UPDATE_CUSTOM_FIELDS_TEMPLATE = 'error_update_custom_fields_template'

//custom fields
export const START_LOAD_CUSTOM_FIELDS = 'start_load_custom_fields'
export const SUCCESS_LOAD_CUSTOM_FIELDS = 'success_load_custom_fields'
export const ERROR_LOAD_CUSTOM_FIELDS = 'error_load_custom_fields'

export const START_LOAD_ELEMENTS_CUSTOM_FIELDS = 'start_load_elements_custom_fields'
export const SUCCESS_LOAD_ELEMENTS_CUSTOM_FIELDS = 'success_load_elements_custom_fields'
export const ERROR_LOAD_ELEMENTS_CUSTOM_FIELDS = 'error_load_elements_custom_fields'

//common
export const UPDATE_TEMPLATE_QUESTIONS = 'update_template_questions'

export const CHECK_FIELD_FILTER_SELECT = 'check_field_filter_select'

export const CLEAR_ERROR_COMPONENT = 'clear_error_component'
export const CLEAR_ERROR_COMMON = 'clear_error_common'

export const GET_LOGGED_USER = 'get_logged_user'

export const LOAD_YET_INSPECTIONS = 'load_yet_inspections'
export const END_LOAD_YET_INSPECTIONS = 'end_load_yet_inspections'

export const LOAD_YET_TASKS = 'load_yet_tasks'
export const END_LOAD_YET_TASKS = 'end_load_yet_tasks'
