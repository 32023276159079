import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    check: false
};

export default function dropdown(state = initialState, action) {
    switch (action.type) {
        case actionConstants.CHECK_FIELD_FILTER_SELECT:
            return {
                ...state,
                check: !state.check
            };
        default: return {...state}
    }
}