import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    files: null,
    file: null,
    fileId: null,
    questionId: null,
    process: false,
    error: null
};

export default function inspections(state = initialState, action) {
    switch (action.type) {
        case actionConstants.START_LOAD_UPLOADS:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_UPLOADS:
            return {
                ...state,
                files: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_LOAD_UPLOADS:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_LOAD_UPLOAD:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_UPLOAD:
            return {
                ...state,
                file: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_LOAD_UPLOAD:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_CREATE_UPLOAD:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_CREATE_UPLOAD:
            return {
                ...state,
                file: action.payload.data.file,
                fileId: action.payload.data.fileId,
                questionId: action.payload.data.questionId,
                process: false
            }
        case actionConstants.ERROR_CREATE_UPLOAD:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.CLEAR_UPLOAD:
            return {
                ...state,
                file: null,
                fileId: null,
                questionId: null
            }

        case actionConstants.CLEAR_ERROR_COMPONENT:
            return {
                ...state,
                error: null
            }

        default: return {...state}
    }
}