import client from "../client";
import * as actionConstants from "../../actions/ActionConstants";
import {getUserApi} from "../api/UsersApi";
import {getBranchApi} from "../api/BranchesApi";
import {getInspectionAnswersApi, getInspectionFormApi} from "../api/InspectionsApi";
import * as dirItemsConstants from "../../constants/DirItemsConstants";
import {getDirItemsWithKindApi} from "../api/DirItemsApi";

const tasksService = {

    async getTask(dispatch, id) {
        dispatch({type: actionConstants.START_LOAD_TASK, payload: {}})
        try {
            const responseTask = await client.get(
                '/api/tasks/' + id)
            const task = responseTask.data

            let branch = null
            if (task.branch_id) {
                const responseBranch = await getBranchApi(task.branch_id)
                branch = responseBranch.data
            }

            let user = null
            if (task.user_id) {
                const responseUser = await getUserApi(task.user_id)
                user = responseUser.data
            }

            const responseInspectionAnswers = await getInspectionAnswersApi(task.inspection_id)
            const inspectionsAnswers = responseInspectionAnswers.data
            const inspectionAnswer = inspectionsAnswers.find(answer => answer.id === task.answer_id)

            const responseForm = await getInspectionFormApi(task.inspection_id)
            const form = responseForm.data

            let question = null
            for (const section of form.sections) {
                question = section.questions.find(item => item.id === inspectionAnswer.question_id)
                if (question) {
                    break;
                }
            }

            const variant = question?.variants.find(item => item.id === task.variant_id)

            const result = {
                id: task.id,
                branchId: task.branch_id,
                branch: branch,
                inspectionId: task.inspection_id,
                createdAt: new Date(task.created_at),
                organizationId: task.entity_id,
                isDeleted: task.is_deleted,
                state: task.state,
                updatedAt: new Date(task.updated_at),
                userId: task.user_id,
                user: user,
                questionComment: inspectionAnswer.comment,
                variant: variant,
                comment: task.comment,
                comments: task.comments,
                uploads: task.uploads.map(upload => {
                    return { id: upload.id, url: process.env.REACT_APP_HOST_URL + upload.url}
                }),
                historyStatus: task.events.map( event => { return {
                    id: event.id,
                    time: new Date(event.created_at).toLocaleString(),
                    status: event.event_name
                }})
            }

            dispatch({
                type: actionConstants.SUCCESS_LOAD_TASK, payload: {
                    data: result
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_TASK, payload: {
                    error: error
                }
            })
        }
    },

    async cancelTask(dispatch, id) {
        try {
            dispatch({type: actionConstants.START_CANCEL_TASK, payload: {}})

            const responseCancels = await getDirItemsWithKindApi(dirItemsConstants.CANCEL_REASON)
            const cancel = responseCancels.data.find(item => item.value === dirItemsConstants.DEFAULT_CANCEL_ADMIN)

            const response = await client.patch(
                '/api/tasks/' + id + '/cancel',
                {
                    cancel_reason_id: cancel?.id
                })

            dispatch({
                type: actionConstants.SUCCESS_CANCEL_TASK, payload: {
                    data: response.data.success
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_CANCEL_TASK, payload: {
                    error: error
                }
            })
        }
    },

    async addComment(dispatch, id, text) {
        dispatch({type: actionConstants.START_ADD_COMMENT_TASK, payload: {}})
        try {
            const responseComment = await client.post(
                '/api/tasks/' + id + '/comment',
                {
                    text: text
                })

            dispatch({
                type: actionConstants.SUCCESS_ADD_COMMENT_TASK, payload: {
                    data: responseComment.data.success
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_ADD_COMMENT_TASK, payload: {
                    error: error
                }
            })
        }
    }
}

export default tasksService;
