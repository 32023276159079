import client from "../client";
import * as actionConstants from "../../actions/ActionConstants";
import * as typeConstants from "../../constants/TypeConstants";
import {getUserApi} from "../api/UsersApi";
import {
    createInspectionsApi,
    getInspectionAnswersApi,
    getInspectionFormApi,
} from "../api/InspectionsApi";
import {getBranchApi} from "../api/BranchesApi";
import {getRecheckApi} from "../api/RechecksApi";
import {getDirItemsWithKindApi} from "../api/DirItemsApi";
import * as dirItemsConstants from "../../constants/DirItemsConstants";

const inspectionsService = {

    async getInspection(dispatch, id, type) {
        dispatch({type: actionConstants.START_LOAD_INSPECTION, payload: {}})
        try {
            let result = []
            if (type === typeConstants.PRIMARY) {
                result = await this.getInspectionPrimary(id)
            } else if (type === typeConstants.SECONDARY) {
                result = await this.getInspectionSecondary(id)
            }

            dispatch({
                type: actionConstants.SUCCESS_LOAD_INSPECTION, payload: {
                    data: result
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_INSPECTION, payload: {
                    error: error
                }
            })
        }
    },

    async getInspectionPrimary(id) {
        const responseInspection = await client.get(
            '/api/inspections/' + id)
        const inspection = responseInspection.data

        let branch = null
        if (inspection.branch_id) {
            const responseBranch = await getBranchApi(inspection.branch_id)
            branch = responseBranch.data
        }

        let user = null
        if (inspection.user_id) {
            const responseUser = await getUserApi(inspection.user_id)
            user = responseUser.data
        }

        return {
            id: inspection.id,
            branchId: inspection.branch_id,
            branch: branch,
            cancelReasonId: inspection.cancel_reason_id,
            createdAt: new Date(inspection.created_at),
            customFields: inspection.custom_fields.map((field) => {
                return {
                    name: field.name,
                    position: field.position,
                    value: field.value,
                };
            }),
            organizationId: inspection.entity_id,
            executeAt: new Date(inspection.execute_at),
            formDisplayName: inspection.form_display_name,
            formId: inspection.form_id,
            formVersionId: inspection.form_version_id,
            isDeleted: inspection.is_deleted,
            state: inspection.state,
            updatedAt: new Date(inspection.updated_at),
            userId: inspection.user_id,
            user: user,
            type: typeConstants.PRIMARY,
            comments: inspection.comments,
            historyStatus: inspection.events.map( event => { return {
                id: event.id,
                time: new Date(event.created_at).toLocaleString(),
                status: event.event_name
            }})
        }
    },

    async getInspectionSecondary(id) {
        const responseRechecks = await getRecheckApi(id)
        const recheck = responseRechecks.data

        let branch = null
        if (recheck.branch_id) {
            const responseBranch = await getBranchApi(recheck.branch_id)
            branch = responseBranch.data
        }

        let user = null
        if (recheck.user_id) {
            const responseUser = await getUserApi(recheck.user_id)
            user = responseUser.data
        }

        const responseInspectionAnswers = await getInspectionAnswersApi(recheck.task.inspection_id)
        const inspectionsAnswers = responseInspectionAnswers.data
        const inspectionAnswer = inspectionsAnswers.find(answer => answer.id === recheck.task.answer_id)

        const responseForm = await getInspectionFormApi(recheck.task.inspection_id)
        const form = responseForm.data

        let question = null
        for (const section of form.sections) {
            question = section.questions.find(item => item.id === inspectionAnswer.question_id)
            if (question) {
                break;
            }
        }

        return {
            id: recheck.id,
            branchId: recheck.branch_id,
            branch: branch,
            taskId: recheck.task_id,
            task: recheck.task,
            cancelReasonId: recheck.cancel_reason_id,
            createdAt: new Date(recheck.created_at),
            organizationId: recheck.entity_id,
            executeAt: new Date(recheck.execute_at),
            displayName: recheck.task.display_name,
            isDeleted: recheck.is_deleted,
            state: recheck.state,
            updatedAt: new Date(recheck.updated_at),
            userId: recheck.user_id,
            user: user,
            type: typeConstants.SECONDARY,
            question: question,
            comment: recheck.comment,
            comments: recheck.comments,
            uploads: recheck.uploads.map(upload => {
                return { id: upload.id, url: process.env.REACT_APP_HOST_URL + upload.url}
            }),
            historyStatus: recheck.events.map( event => { return {
                id: event.id,
                time: new Date(event.created_at).toLocaleString(),
                status: event.event_name
            }})
        }
    },

    async createInspection(
      dispatch,
      branchId,
      customFieldItemId,
      displayName,
      entityId,
      executeAt,
      formVersionCustomFieldId,
      templateId,
      userId,
    ) {
        dispatch({
            type: actionConstants.START_CREATE_INSPECTION,
            payload: {},
        });

        try {
            const data = {
                branch_id: branchId,
                ...customFieldItemId && formVersionCustomFieldId
                    ? {
                        custom_fields: [
                            {
                                custom_field_item_id: customFieldItemId,
                                form_version_custom_field_id: formVersionCustomFieldId,
                            },
                        ]
                    }
                    : {},
                display_name: displayName,
                entity_id: entityId,
                execute_at: executeAt,
                form_id: templateId,
                user_id: userId,
            };

            const responseInspection = await createInspectionsApi(data);

            dispatch({
                type: actionConstants.SUCCESS_CREATE_INSPECTION,
                payload: {
                    data: responseInspection.data.success,
                },
            });
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_CREATE_INSPECTION,
                payload: {
                    error,
                },
            });
        }
    },

    async getInspectionAnswers(dispatch, id) {
        dispatch({type: actionConstants.START_LOAD_INSPECTION_ANSWER, payload: {}})
        try {
            const responseInspectionAnswers = await getInspectionAnswersApi(id)
            const inspectionsAnswers = responseInspectionAnswers.data

            const responseForm = await getInspectionFormApi(id)
            const form = responseForm.data

            const result = inspectionsAnswers.map(item => {
                let question = null
                for (const section of form.sections) {
                    question = section.questions.find(itemFind => itemFind.id === item.question_id)
                    if (question) {
                        break;
                    }
                }

                return {
                    id: item.id,
                    comment: item.comment,
                    cancelReasonId: item.cancel_reason_id,
                    createdAt: new Date(item.created_at),
                    organizationId: item.entity_id,
                    inspectionId: item.inspection_id,
                    isDeleted: item.is_deleted,
                    questionId: item.question_id,
                    question: question,
                    uploads: item.uploads.map(upload => {
                        return { id: upload.id, url: process.env.REACT_APP_HOST_URL + upload.url}
                    }),
                    updatedAt: new Date(item.updated_at),
                    variantIds: item.variant_ids
                }
            })

            dispatch({
                type: actionConstants.SUCCESS_LOAD_INSPECTION_ANSWER, payload: {
                    data: result
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_INSPECTION_ANSWER, payload: {
                    error: error
                }
            })
        }
    },

    async cancelInspection(dispatch, id, type) {
        try {
            dispatch({type: actionConstants.START_CANCEL_INSPECTION, payload: {}})

            const responseCancels = await getDirItemsWithKindApi(dirItemsConstants.CANCEL_REASON)
            const cancel = responseCancels.data.find(item => item.value === dirItemsConstants.DEFAULT_CANCEL_ADMIN)

            let response = {}
            if (type === typeConstants.PRIMARY)
                response = await client.patch(
                    '/api/inspections/' + id + '/cancel',
                    {
                        cancel_reason_id: cancel?.id
                    })
            else if (type === typeConstants.SECONDARY)
                response = await client.patch(
                    '/api/rechecks/' + id + '/cancel',
                    {
                        cancel_reason_id: cancel?.id
                    })

            dispatch({
                type: actionConstants.SUCCESS_CANCEL_INSPECTION, payload: {
                    data: response.data.success
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_CANCEL_INSPECTION, payload: {
                    error: error
                }
            })
        }
    },

    async addComment(dispatch, id, type, text) {
        dispatch({type: actionConstants.START_ADD_COMMENT_INSPECTION, payload: {}})
        try {
            let responseComment = {}
            if (type === typeConstants.PRIMARY) {
                responseComment = await client.post(
                    '/api/inspections/' + id + '/comment',
                    {
                        text: text
                    })
            } else if (type === typeConstants.SECONDARY) {
                responseComment = await this.addCommentRecheckApi(id, text)
            }

            dispatch({
                type: actionConstants.SUCCESS_ADD_COMMENT_INSPECTION, payload: {
                    data: responseComment.data.success
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_ADD_COMMENT_INSPECTION, payload: {
                    error: error
                }
            })
        }
    }
}

export default inspectionsService;
