import * as actionConstants from "./ActionConstants";
import BranchesService from "../network/service/BranchesService";

const branchesActions = {

    getBranches() {
        return {
            type: actionConstants.START_LOAD_BRANCHES, payload: {}
        }
    },

    getBranch(id) {
        return {
            type: actionConstants.START_LOAD_BRANCH, payload: {
                id: id
            }
        }
    },

    async createBranch(dispatch, name) {
        await BranchesService.createBranch(dispatch, name)
        dispatch(this.getBranches())
    },

    async deleteBranch(dispatch, id) {
        await BranchesService.deleteBranch(dispatch, id)
        dispatch(this.getBranches())
    }
}

export default branchesActions
