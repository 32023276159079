import IconsCommon from '../../assets/icons/IconsCommon'
import PropTypes from 'prop-types'

import React from 'react'
import {connect} from 'react-redux'

import DialogDelete from "../commons/dialog/DialogDelete";

import TableDropDown from "../commons/dropdown/TableDropDown"
import DialogSectionsEdit from "./DialogSectionsEdit";
import * as roleConstants from "../../constants/RoleConstants";
import {minTwoDigits} from "../utils/TableUtil";
import sectionsActions from "../../actions/SectionsActions";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";

class Sections extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 5,
            isSortId: false,
            showSectionsEditDialog: {
                isShowSectionsEditDialog: false,
                isEdit: false
            },
            showDeleteDialog: {
                isShowDeleteDialog: false,
                sectionsId: -1
            },

            filterSelect: {
                organisation: TableDropDown.emptyObject
            },

            sections: null,
        }

        this.props.onGetSections().then()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sectionsState.sections !== prevProps.sectionsState.sections) {
            this.setState({
                sections: this.props.sectionsState.sections
            })
        }
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <span className="title-text">{this.getLocale().sections.title}</span>
                        </div>
                        <div className="col">
                            <div className="row h-100 align-items-center no-gutters">
                                <button className="directory-button" onClick={this.showSectionsEditDialog}>
                                    {this.getLocale().sections.add_section}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col table-block">
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" style={{width: '25%'}}>
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">ID</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" style={{width: '15%'}}>
                                        <span className="table-header-title">{this.getLocale().sections.table_header.name}</span>
                                    </th>
                                    {
                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                            ? <TableDropDown type={TableDropDown.typesFilter.organisation}
                                                             customStyle={{width: '20%'}}
                                                             filterSelect={this.state.filterSelect}
                                                             updateFilterSelect={this.updateFilterSelect}
                                                             updateData={this.updateData} />
                                            : null
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.sections?.map(item => {
                                            return (
                                                <tr className="table-row" key={item.id}>
                                                    <td><span className="table-ceil">{minTwoDigits(item.id)}</span></td>
                                                    <td onClick={() => this.onRowClick(item.id)}><span className="table-ceil-name-non-pointer">{item.displayName}</span></td>
                                                    {
                                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                                            ? <td><span className="table-ceil">{item.organisation.displayName}</span></td>
                                                            : null
                                                    }
                                                    <td>
                                                        <div className="table-icn-delete">
                                                            <div className="row">
                                                                <div className="col-auto" onClick={() => this.showDialogDelete(item.id)}>
                                                                    {IconsCommon.getIcnDelete()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        this.props.sectionsState.error
                            ? <DialogError isShow={true}
                                           type={errorConstants.ERROR_COMPONENT}
                                           body={this.props.sectionsState.error?.status === errorConstants.UNPROCESSABLE_ENTITY
                                                    ? this.getLocale().errors.duplicatedName : null } />
                            : null
                    }
                    {
                        this.state.showDeleteDialog.isShowDeleteDialog
                            ? <DialogDelete isShow={true} onHide={this.hideDialogDelete} clickYes={this.deleteSection} />
                            : null
                    }
                    {
                        this.state.showSectionsEditDialog.isShowSectionsEditDialog
                            ? <DialogSectionsEdit role={this.props.loggedUser?.role}
                                                       isShow={true}
                                                       onHide={this.hideSectionsDialog}
                                                       clickSave={this.saveSections} />
                            : null
                    }
                </section>
        )
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })
    }

    showDialogDelete = (sectionsId) => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = true
        newShowDeleteDialog.sectionsId = sectionsId
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    hideDialogDelete = () => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = false
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    showSectionsEditDialog = (edit) => {
        let newShowSectionsEditDialog = this.state.showSectionsEditDialog
        newShowSectionsEditDialog.isShowSectionsEditDialog = true
        newShowSectionsEditDialog.isEdit = !!edit
        this.setState({
            showSectionsEditDialog: newShowSectionsEditDialog
        })
    }

    hideSectionsDialog = () => {
        let newShowSectionsEditDialog = this.state.showSectionsEditDialog
        newShowSectionsEditDialog.isShowSectionsEditDialog = false
        this.setState({
            showSectionsEditDialog: newShowSectionsEditDialog
        })
    }

    deleteSection = async () => {
        await this.props.onDeleteSection(this.state.showDeleteDialog.sectionsId)
    }

    saveSections = async (name) => {
        await this.props.onCreateSection(name)
    }

    onRowClick = () => {}

    getLocale() {
        return this.props.appState.locale
    }

    updateData = () => {
        let newFiltered = this.props.sectionsState.sections.slice()
        if (this.state.filterSelect.organisation) {
            newFiltered = newFiltered.filter(value => value.organisation.id === this.state.filterSelect.organisation.id)
        }

        this.setState({
            sections: newFiltered
        })
    }
}

Sections.propTypes = {
    page: PropTypes.number,   // Page number
}

export default connect(
    state => ({
        sectionsState: state.sections,
        loggedUser: state.users.loggedUser,
        appState: state.app
    }),
    dispatch => ({
        onGetSections: async () => {
            await sectionsActions.getSections(dispatch)
        },
        onCreateSection: async (name) => {
            await sectionsActions.createSection(dispatch, name)
        },
        onDeleteSection: async (id) => {
            await sectionsActions.deleteSection(dispatch, id)
        }
    })
)(Sections);