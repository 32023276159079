import React from 'react';
import PropTypes from 'prop-types'

import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import * as actionConstants from "../../../actions/ActionConstants";
import * as errorConstants from "../../../constants/ErrorConstants";

class DialogError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: this.props.isShow
        }

        this.commentRef = React.createRef()
    }

    render() {
        return (
            <Modal dialogClassName="modal-dialog-delete" show={this.state.isShow} onHide={this.hideModal}>
                <Modal.Header className="modal-dialog-header">
                    <span className="modal-dialog-header-body">
                        { this.props.title ? this.props.title : this.getLocale().common.oh }
                    </span>
                </Modal.Header>
                <Modal.Body style={{marginBottom: '20px'}}>
                    <span className="modal-dialog-body">
                        { this.props.body ? this.props.body : this.getLocale().errors.undefinedError }
                    </span>
                </Modal.Body>
                <Modal.Footer className="modal-dialog-delete-footer">
                    <button type="button" className="button-common" onClick={this.hideModal}>
                        {this.getLocale().common.understandably}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    hideModal = () => {
        if (this.props.type === errorConstants.ERROR_COMPONENT)
            this.props.onClearComponentError()

        if (this.props.type === errorConstants.ERROR_COMMON)
            this.props.onClearCommonError()

        this.setState({
            isShow: false
        })
    }

}

DialogError.propTypes = {
    isShow: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    body: PropTypes.string
}

export default connect(
    state => ({
        appState: state.app
    }),
    dispatch => ({
        onClearComponentError: () => {
            dispatch({type: actionConstants.CLEAR_ERROR_COMPONENT, payload: {}})
        },
        onClearCommonError: () => {
            dispatch({type: actionConstants.CLEAR_ERROR_COMMON, payload: {}})
        }
    })
)(DialogError);
