import React from "react";

const iconsStatistics = {
    getIcnCompleted() {
        return  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="3" stroke="#2ED47A" strokeWidth="2"/>
                </svg>
    },
    getIcnCanceled() {
        return  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="3" stroke="#FF3C5F" strokeWidth="2"/>
                </svg>
    }
}

export default iconsStatistics