import client from "../client";
import Cookies from 'universal-cookie';
import * as actionConstants from "../../actions/ActionConstants";
import * as errorConstants from "../../constants/ErrorConstants";

const authService = {

    async authRequestCode(dispatch, phone) {
        dispatch({type: actionConstants.START_REQUEST_CODE, payload: {}})
        try {
            const response = await client.post(
                '/api/tokens/request_code',
                {
                    phone: phone
                })

            dispatch({
                type: actionConstants.SUCCESS_REQUEST_CODE, payload: {
                    data: response.data.sign
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_REQUEST_CODE, payload: {
                    error: { id: errorConstants.NOT_FOUND_PHONE, error: error }
                }
            })
        }
    },

    async authCreateToken(dispatch, phone, sign, code) {
        dispatch({type: actionConstants.START_CREATE_TOKEN, payload: {}})
        try {
            const response = await client.post(
                '/api/tokens/create',
                {
                    phone: phone,
                    sign: sign,
                    code: code
                })

            const token = response.data.token

            this.setCookie({
                phone: phone,
                token: token
            })

            dispatch({
                type: actionConstants.SUCCESS_CREATE_TOKEN, payload: {
                    data: token
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_CREATE_TOKEN, payload: {
                    error: { id: errorConstants.WRONG_CODE, error: error }
                }
            })
        }
    },

    async renewToken(dispatch) {
        dispatch({type: actionConstants.START_RENEW_TOKEN, payload: {}})
        try {
            let token = ""
            if (this.loggedIn()) {
                const response = await client.post(
                    '/api/tokens/renew')

                const cookies = new Cookies()
                const user = cookies.get('XrayLoggedUser')
                user.token = response.data.token
                this.setCookie(user)
                token = response.data.token
            }

            dispatch({
                type: actionConstants.SUCCESS_RENEW_TOKEN, payload: {
                    data: token
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_RENEW_TOKEN, payload: {
                    error: error
                }
            })
        }
    },

    setCookie(user) {
        const cookies = new Cookies()
        const expiredData = this.getDateExpired(process.env.REACT_APP_TOKEN_LIFETIME)
        cookies.set('XrayLoggedUser', JSON.stringify(user), { expires: expiredData});
    },

    getDateExpired(days) {
        const today = new Date();
        let expiredDate = new Date();
        expiredDate.setDate(today.getDate() + Number.parseInt(days));
        return expiredDate
    },

    loggedIn() {
        const cookies = new Cookies()
        const user = cookies.get('XrayLoggedUser')
        return user !== null && user !== undefined
    },

    loggedOut() {
        const cookies = new Cookies()
        cookies.remove('XrayLoggedUser')
        window.location.replace('/')
    }
}

export default authService;
