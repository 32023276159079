import React from 'react'
import {connect} from 'react-redux'
import DialogComment from '../commons/dialog/DialogComment';
import DialogImage from '../commons/dialog/DialogImage'

import {calculateAspectRatioFit} from "../utils/ImageUtil"
import TemplatesActions from "../../actions/TemplatesActions";

class TemplateSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pathImage: '',
            images: []
        }

        this.props.onGetTemplate(this.props.match.params.id).then()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.templatesState.template !== prevProps.templatesState.template) {
            this.reconstructImage(this.props.templatesState.template)
        }
    }

    render() {
        return (
            <section className="col inspection-select">
                <div className="row inspection-select-offset">
                    <div className="col-7">
                        <div className="row inspection-select-title-block">
                            <span className="inspection-select-title">{this.props.templatesState.template?.displayName}</span>
                        </div>
                        <div className="row">
                            <div className="col inspection-select-block">
                                {
                                    this.props.templatesState.template?.questions.map(question => {
                                        return  <div key={question.id} className="row card-block">
                                                    <div className="col">
                                                        <div className="row title-card-block">
                                                            <div className="col">
                                                                <span>{question.name}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row select-block">
                                                            {
                                                                this.getSelect(question)
                                                            }
                                                        </div>
                                                        <div className="row comment-block">
                                                            {
                                                                question.prompt
                                                                ?   <div className="col">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <span className="comment-title-text">{this.getLocale().common.prompt}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col divide-comment">
                                                                                <span className="comment-text">{question.prompt}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="row select-image-block">
                                                            {
                                                                question.photos.map(photo => {
                                                                    return <div key={photo.file.id} className="col-auto">
                                                                                <div className="row">
                                                                                    {
                                                                                        this.state.images.find((i) => i.id === photo.file.id.toString())
                                                                                            ? this.state.images.find((i) => i.id === photo.file.id.toString()).elements.map( (image, index) => {
                                                                                                return  <div key={index} className="col-auto">
                                                                                                    <img onClick={
                                                                                                        () => this.showDialogImage(image.src)
                                                                                                    }
                                                                                                         className="select-image"
                                                                                                         src={image.src}
                                                                                                         width={image.width}
                                                                                                         height={image.height} alt="" />
                                                                                                </div>
                                                                                            })
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-5" />
                </div>
                {
                    this.state.isShowDialogComment
                        ? <DialogComment isShow={true} onHide={this.hideDialogComment} clickSave={this.addComment} />
                        : null
                }
                {
                    this.state.isShowDialogImage
                        ? <DialogImage path={this.state.pathImage} isShow={true} onHide={this.hideDialogImage}/>
                        : null
                }
            </section>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    showDialogComment = () => {
        this.setState({
            isShowDialogComment: true
        })
    }

    hideDialogComment = () => {
        this.setState({
            isShowDialogComment: false
        })
    }

    showDialogImage = (path) => {
        this.setState({
            isShowDialogImage: true,
            pathImage: path
        })
    }

    hideDialogImage = () => {
        this.setState({
            isShowDialogImage: false
        })
    }

    addComment = (comment) => {
        this.setState({
            comment: comment
        })
    }

    getSelect(question) {
        if (question.multiSelect)
            return this.getMultiSelect(question.answers)
        else
            return this.getOneSelect(question.answers)
    }

    getOneSelect(answers) {
        return <div className="col">
                {
                    answers.map(answer => {
                        return  <div key={answer.id} className="row no-gutters select-one">
                                    <div className="col-auto">
                                        <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                            <input type="radio" defaultChecked={answer.isTrue} disabled />
                                        </div>
                                    </div>
                                    <div className="col-auto select-text">
                                        <div className="row h-100 no-gutters align-items-center">
                                            <span className={answer.isTrue ? "text-select-checked" : "text-select"}>{answer.text}</span>
                                        </div>
                                    </div>
                                </div>
                    })
                }
            </div>
    }

    getMultiSelect(answers) {
        return <div className="col">
            {
                answers.map(answer => {
                    return  <div key={answer.id} className="row no-gutters select-multi">
                                <div className="col-auto">
                                    <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                        <input type="radio" defaultChecked={answer.isTrue} disabled />
                                    </div>
                                </div>
                                <div className="col-auto select-text">
                                    <div className="row h-100 no-gutters align-items-center">
                                        <span className={answer.isTrue ? "text-select-checked" : "text-select"}>{answer.text}</span>
                                    </div>
                                </div>
                            </div>
                })
            }
        </div>
    }

    reconstructImage(template) {
        template.questions.forEach(question => {
            question.photos.forEach(photo => {
                this.processReconstructImage(photo.file.id, photo.file.url)
            })
        })
    }

    processReconstructImage(id, url) {
        let img = new Image();
        img.onload = (g) => {
            let imageSrc = null

            if (g.hasOwnProperty('path') && g.path.length > 0) {
                imageSrc = g.path[0]
            }

            if (g.target) {
                imageSrc = g.target
            }

            if (imageSrc) {
                let sizeImage = calculateAspectRatioFit(imageSrc.width, imageSrc.height, 160, 160)
                let newImages = this.state.images.slice()
                let idEmpty = {id: imageSrc.tag.toString(), elements: []}

                if (!newImages.find((i) => i.id === imageSrc.tag.toString())) {
                    newImages.push(idEmpty)
                }

                newImages.find((i) => i.id === imageSrc.tag.toString()).elements.push(
                    {
                        src: imageSrc.src,
                        width: sizeImage.width,
                        height: sizeImage.height
                    }
                )

                this.setState({
                    images: newImages
                })
            }
        }
        img.src = url
        img.tag = id
    }
}

export default connect(
    state => ({
        appState: state.app,
        templatesState: state.templates
    }),
    dispatch => ({
        onGetTemplate: async (id) => {
            await TemplatesActions.getTemplate(dispatch, id)
        }
    })

)(TemplateSelect);