export function sortDate(array, stateSort, typeSort) {
    let sortArray

    if (stateSort) {
        //по возрастанию
        sortArray = array.slice().sort((a, b) =>  a[typeSort] - b[typeSort] )
    } else {
        //по убыванию
        sortArray = array.slice().sort((a, b) =>  b[typeSort] - a[typeSort] )
    }

    return sortArray
}