import OrganizationsService from "../network/service/OganizationsService";

const organizationsActions = {

    async getOrganizations(dispatch) {
        await OrganizationsService.getOrganizations(dispatch)
    },

    async createOrganization(dispatch, displayName) {
        await OrganizationsService.createOrganization(dispatch, displayName)
        await OrganizationsService.getOrganizations(dispatch)
    },

    async deleteOrganization(dispatch, id) {
        await OrganizationsService.deleteOrganization(dispatch, id)
        await OrganizationsService.getOrganizations(dispatch)
    }
}

export default organizationsActions
