import client from "../client";
import * as actionConstants from "../../actions/ActionConstants";
import Cookies from "universal-cookie";
import AuthService from "./AuthService";

const usersService = {

    async getTokenAnotherUser(dispatch, userId) {
        dispatch({type: actionConstants.START_GET_TOKEN_ANOTHER_USER, payload: {}})
        try {
            const responseToken = await client.get(
                '/super_user_api/users/' + userId + '/token')
            const token = responseToken.data.token

            const cookies = new Cookies()
            const user = cookies.get('XrayLoggedUser')
            user.token = token
            AuthService.setCookie(user)

            dispatch({
                type: actionConstants.SUCCESS_GET_TOKEN_ANOTHER_USER, payload: {
                    data: token
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_GET_TOKEN_ANOTHER_USER, payload: {
                    error: error
                }
            })
        }
    },
}

export default usersService;
