import * as actionConstants from "../actions/ActionConstants";

const initialState = {
  customFields: [],
  elementsCustomField: [],
  process: false,
};

export default function customFields(state = initialState, action) {
  switch (action.type) {
    case actionConstants.START_LOAD_CUSTOM_FIELDS: {
      return {
        ...state,
        process: true,
      };
    }

    case actionConstants.SUCCESS_LOAD_CUSTOM_FIELDS: {
      return {
        ...state,
        customFields: action.payload.data,
        process: false,
      };
    }

    case actionConstants.ERROR_LOAD_CUSTOM_FIELDS: {
      return {
        ...state,
        error: action.payload.error,
        process: false,
      };
    }

    case actionConstants.START_LOAD_ELEMENTS_CUSTOM_FIELDS: {
      return {
        ...state,
        process: true,
      };
    }

    case actionConstants.SUCCESS_LOAD_ELEMENTS_CUSTOM_FIELDS: {
      return {
        ...state,
        elementsCustomField: action.payload.data,
        process: false,
      };
    }

    case actionConstants.ERROR_LOAD_ELEMENTS_CUSTOM_FIELDS: {
      return {
        ...state,
        error: action.payload.error,
        process: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
