import client from "../client";

export async function getBranchApi(id) {
    return client.get(
        '/api/branches/' + id)
}

export async function getBranchesApi() {
    return client.get(
        '/api/branches')
}

export async function createBranchApi(name, timezone) {
    return client.post(
        '/api/branches',
        {
            display_name: name,
            timezone: timezone
        })
}

export async function deleteBranchApi(id) {
    return client.delete(
        '/api/branches/' + id
    )
}

//super user
export async function getBranchForSuperUserApi(id) {
    return client.get(
        '/super_user_api/branches/' + id)
}

export async function getBranchesForSuperUserApi() {
    return client.get(
        '/super_user_api/branches')
}