import React from 'react';
import IconsFooter from "../assets/icons/IconsFooter";
import {connect} from "react-redux";
import * as appInfoConstants from "../constants/AppInfoConstants";

class Footer extends React.Component {
    render() {
        return (
            <>
                {process.env.REACT_APP_HIDE_NERVE != '1' && (
                    <div className="footer-phantom">
                        <div className="footer-style">
                            <div className="row h-100 align-items-center">
                                <div className="col">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <span className="footer-text text-center">Copyright {appInfoConstants.COMPANY_NAME}, {new Date().getFullYear()} {this.getLocale().login.version} {appInfoConstants.APP_VERSION}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row justify-content-center no-gutters">
                                        <div className="col-auto" style={{marginRight: '10px'}}>
                                            {IconsFooter.getIcnEmail()}
                                        </div>
                                        <div className="col-auto">
                                            <span className="footer-text text-center">{appInfoConstants.SUPPORT_EMAIL}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }
}

export default connect(
    state => ({
        appState: state.app
    })
)(Footer);
