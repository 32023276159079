import * as actionConstants from "../../actions/ActionConstants";
import client from "../client";

const branchesService = {

    async createBranch(dispatch, name) {
        dispatch({type: actionConstants.START_CREATE_BRANCH, payload: {}})
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        try {
            const response = await client.post(
                '/api/branches',
                {
                    display_name: name,
                    timezone: timezone
                })

            dispatch({
                type: actionConstants.SUCCESS_CREATE_BRANCH, payload: {
                    data: response.data.success
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_CREATE_BRANCH, payload: {
                    error: error
                }
            })
        }
    },

    async deleteBranch(dispatch, id) {
        dispatch({type: actionConstants.START_DELETE_BRANCH, payload: {}})
        try {
            const response = await client.delete(
                '/api/branches/' + id,
            )

            dispatch({
                type: actionConstants.SUCCESS_DELETE_BRANCH, payload: {
                    data: response.data.success
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_DELETE_BRANCH, payload: {
                    error: error
                }
            })
        }
    }
}

export default branchesService;
