import React from 'react';
import PropTypes from 'prop-types'

import {Modal} from "react-bootstrap";
import {connect} from "react-redux";

class DialogOrganizationsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: props.isShow,

            isNameError: false,
        }

        this.nameRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isShow !== prevProps.isShow)
            this.setState({
                isShow: this.state.isShow
            })
    }

    render() {
        return (
            <Modal dialogClassName="modal-dialog-delete" show={this.state.isShow} onHide={this.hideModal}>
                <form>
                    <Modal.Header className="modal-dialog-header">
                        <div className="row w-100 no-gutters">
                            <div className="col-auto">
                                <span className="modal-dialog-header-body">{this.getLocale().organisations.add_organisation}</span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group row no-gutters">
                            <div className="col">
                                <div className="row align-items-center row-input-inspection">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <span className="label-inspection">{this.getLocale().organisations.user_form.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            ref={this.nameRef}
                                            type="text"
                                            placeholder={this.getLocale().organisations.user_form.input_name}
                                            className={ 'form-control ' + (this.state.isNameError ? 'input-error' : 'input-inspection') }
                                            onChange={(e) => {
                                                this.setState({isNameError: !e.target.value})}
                                            }
                                            maxLength={100} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-dialog-delete-footer">
                        <button type="button" className="button-border-common" onClick={this.hideModal}>
                            {this.getLocale().common.cancel}
                        </button>
                        <button type="button" className="button-common" onClick={this.saveField}>
                            {this.getLocale().common.save}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    saveField = () => {
        let isCheck = this.checkField()

        if (isCheck) {
            this.props.clickSave(this.nameRef.current.value)
            this.hideModal()
        }
    }

    checkField() {
        if (!this.nameRef.current.value) {
            this.setState({
                isNameError: true
            })
            return false
        } else {
            return true
        }
    }

    hideModal = () => {
        this.setState({
            isShow: false
        })
        this.props.onHide()
    }
}

DialogOrganizationsEdit.propTypes = {
    isShow: PropTypes.bool,
    clickSave: PropTypes.func
}

export default connect(
    state => ({
        appState: state.app
    })
)(DialogOrganizationsEdit);
