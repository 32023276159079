import IconsCommon from '../../assets/icons/IconsCommon'
import PropTypes from 'prop-types'

import React from 'react'
import {connect} from 'react-redux'

import DialogDelete from "../commons/dialog/DialogDelete";

import TableDropDown from "../commons/dropdown/TableDropDown"
import DialogCancelReasonsEdit from "./DialogCancelReasonsEdit";
import * as roleConstants from "../../constants/RoleConstants";
import {minTwoDigits} from "../utils/TableUtil";
import CancelReasonsActions from "../../actions/CancelReasonsActions";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";

class CancelReasons extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 5,
            isSortId: false,
            showCancelReasonsEditDialog: {
                isShowCancelReasonsEditDialog: false,
                isEdit: false
            },
            showDeleteDialog: {
                isShowDeleteDialog: false,
                cancelReasonsId: -1
            },

            filterSelect: {
                organisation: TableDropDown.emptyObject
            },

            cancelReasons: null,
        }

        this.props.onGetCancelReasons().then()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cancelReasonsState.cancelReasons !== prevProps.cancelReasonsState.cancelReasons) {
            this.setState({
                cancelReasons: this.props.cancelReasonsState.cancelReasons
            })
        }
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <span className="title-text">{this.getLocale().cancel_reasons.title}</span>
                        </div>
                        <div className="col">
                            <div className="row h-100 align-items-center no-gutters">
                                <button className="directory-button" onClick={this.showCancelReasonsEditDialog}>
                                    {this.getLocale().cancel_reasons.add_reason}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col table-block">
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" style={{width: '25%'}}>
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">ID</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" style={{width: '15%'}}>
                                        <span className="table-header-title">{this.getLocale().cancel_reasons.table_header.name}</span>
                                    </th>
                                    {
                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                            ? <TableDropDown type={TableDropDown.typesFilter.organisation}
                                                             customStyle={{width: '20%'}}
                                                             filterSelect={this.state.filterSelect}
                                                             updateFilterSelect={this.updateFilterSelect}
                                                             updateData={this.updateData} />
                                            : null
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.cancelReasons?.map(item => {
                                            return (
                                                <tr className="table-row" key={item.id}>
                                                    <td><span className="table-ceil">{minTwoDigits(item.id)}</span></td>
                                                    <td onClick={() => this.onRowClick(item.id)}><span className="table-ceil-name-non-pointer">{item.displayName}</span></td>
                                                    {
                                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                                            ? <td><span className="table-ceil">{item.organisation.displayName}</span></td>
                                                            : null
                                                    }
                                                    <td>
                                                        <div className="table-icn-delete">
                                                            <div className="row">
                                                                <div className="col-auto" onClick={() => this.showDialogDelete(item.id)}>
                                                                    {IconsCommon.getIcnDelete()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        this.state.showDeleteDialog.isShowDeleteDialog
                            ? <DialogDelete isShow={true} onHide={this.hideDialogDelete} clickYes={this.deleteCancelReason} />
                            : null
                    }
                    {
                        this.state.showCancelReasonsEditDialog.isShowCancelReasonsEditDialog
                            ? <DialogCancelReasonsEdit role={this.props.loggedUser?.role}
                                                       isShow={true}
                                                       onHide={this.hideCancelReasonsDialog}
                                                       clickSave={this.saveCancelReasons} />
                            : null
                    }
                    {
                        this.props.cancelReasonsState.error
                            ? <DialogError isShow={true}
                                           type={errorConstants.ERROR_COMPONENT}/>
                            : null
                    }
                </section>
        )
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })
    }

    showDialogDelete = (cancelReasonsId) => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = true
        newShowDeleteDialog.cancelReasonsId = cancelReasonsId
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    hideDialogDelete = () => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = false
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    showCancelReasonsEditDialog = (edit) => {
        let newShowCancelReasonsEditDialog = this.state.showCancelReasonsEditDialog
        newShowCancelReasonsEditDialog.isShowCancelReasonsEditDialog = true
        newShowCancelReasonsEditDialog.isEdit = !!edit
        this.setState({
            showCancelReasonsEditDialog: newShowCancelReasonsEditDialog
        })
    }

    hideCancelReasonsDialog = () => {
        let newShowCancelReasonsEditDialog = this.state.showCancelReasonsEditDialog
        newShowCancelReasonsEditDialog.isShowCancelReasonsEditDialog = false
        this.setState({
            showCancelReasonsEditDialog: newShowCancelReasonsEditDialog
        })
    }

    deleteCancelReason = async () => {
        await this.props.onDeleteCancelReason(this.state.showDeleteDialog.cancelReasonsId)
    }

    saveCancelReasons = async (name) => {
        await this.props.onCreateCancelReason(name)
    }

    onRowClick = () => {}

    getLocale() {
        return this.props.appState.locale
    }

    updateData = () => {
        let newFiltered = this.props.cancelReasonsState.cancelReasons.slice()
        if (this.state.filterSelect.organisation) {
            newFiltered = newFiltered.filter(value => value.organisation.id === this.state.filterSelect.organisation.id)
        }

        this.setState({
            cancelReasons: newFiltered
        })
    }
}

CancelReasons.propTypes = {
    page: PropTypes.number,   // Page number
}

export default connect(
    state => ({
        cancelReasonsState: state.cancelReasons,
        loggedUser: state.users.loggedUser,
        appState: state.app
    }),
    dispatch => ({
        onGetCancelReasons: async () => {
            await CancelReasonsActions.getCancelReasons(dispatch)
        },
        onCreateCancelReason: async (name) => {
            await CancelReasonsActions.createCancelReason(dispatch, name)
        },
        onDeleteCancelReason: async (id) => {
            await CancelReasonsActions.deleteCancelReason(dispatch, id)
        }
    })
)(CancelReasons);