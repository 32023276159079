import client from "../client";

export async function getCustomFieldsApi(name = null) {
  const url = name ? `/api/custom_fields?display_name=${name}` : "/api/custom_fields";

  return client.get(url);
}

export async function getElementsCustomFieldApi(field_id, name = null) {
  const url = name
    ? `/api/custom_fields/${field_id}/custom_field_items?display_name=${name}`
    : `/api/custom_fields/${field_id}/custom_field_items`;

  return client.get(url);
}
