import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    tasks: [],
    task: null,
    isCanceledTask: false,
    isLoadYet: true,
    process: false,
    error: null
};

export default function tasks(state = initialState, action) {
    switch (action.type) {
        case actionConstants.START_LOAD_TASKS:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_TASKS:
            return {
                ...state,
                tasks: action.payload.data,
                isLoadYet: true,
                process: false
            };
        case actionConstants.ERROR_LOAD_TASKS:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_LOAD_TASK:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_TASK:
            return {
                ...state,
                task: action.payload.data
            };
        case actionConstants.ERROR_LOAD_TASK:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_CANCEL_TASK:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_CANCEL_TASK:
            return {
                ...state,
                isCanceledTask: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_CANCEL_TASK:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.CLEAR_ERROR_COMPONENT:
            return {
                ...state,
                error: null
            }

        case actionConstants.END_LOAD_YET_TASKS:
            return {
                ...state,
                isLoadYet: false
            }

        default: return {...state}
    }
}