import React from "react";

const iconsNavigation = {
    getIcnLogOut() {
        return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                        <path d="M19.9668 28.3057H12.4917C12.0332 28.3057 11.6611 27.9336 11.6611 27.4751V12.5249C11.6611 12.0664 12.0332 11.6944 12.4917 11.6944H19.9668C20.4261 11.6944 20.7973 11.3231 20.7973 10.8638C20.7973 10.4045 20.4261 10.0332 19.9668 10.0332H12.4917C11.1179 10.0332 10 11.1512 10 12.5249V27.4751C10 28.8488 11.1179 29.9668 12.4917 29.9668H19.9668C20.4261 29.9668 20.7973 29.5955 20.7973 29.1362C20.7973 28.6769 20.4261 28.3057 19.9668 28.3057Z" fill="#707683"/>
                        <path d="M29.7525 19.4085L24.7027 14.4251C24.3771 14.1029 23.8505 14.1071 23.5282 14.4335C23.206 14.7599 23.2093 15.2856 23.5366 15.6079L27.1454 19.1693H17.4751C17.0158 19.1693 16.6445 19.5406 16.6445 19.9999C16.6445 20.4592 17.0158 20.8305 17.4751 20.8305H27.1454L23.5366 24.3919C23.2093 24.7142 23.2068 25.2399 23.5282 25.5663C23.691 25.7308 23.9053 25.8138 24.1196 25.8138C24.3306 25.8138 24.5415 25.7341 24.7027 25.5746L29.7525 20.5912C29.9103 20.4351 30 20.2224 30 19.9998C30 19.7773 29.9111 19.5655 29.7525 19.4085Z" fill="#707683"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect x="10" y="10" width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
    },
    getIcnInspection(){
        return  <svg className="image-nav" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0003 1.6665L1.66699 5.83317L10.0003 9.99984L18.3337 5.83317L10.0003 1.6665Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.66699 14.1665L10.0003 18.3332L18.3337 14.1665" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.66699 10L10.0003 14.1667L18.3337 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

    },
    getIcnTask(){
        return  <svg className="image-nav" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.0004 11.6665H7.5004C7.0404 11.6665 6.66707 11.2932 6.66707 10.8332C6.66707 10.3732 7.0404 9.99984 7.5004 9.99984H10.0004C10.4604 9.99984 10.8337 10.3732 10.8337 10.8332C10.8337 11.2932 10.4604 11.6665 10.0004 11.6665ZM6.66707 14.1665C6.66707 13.7065 7.0404 13.3332 7.5004 13.3332H12.5004C12.9612 13.3332 13.3337 13.7065 13.3337 14.1665C13.3337 14.6265 12.9612 14.9998 12.5004 14.9998H7.5004C7.0404 14.9998 6.66707 14.6265 6.66707 14.1665ZM14.5373 16.6665H5.46315C5.20815 16.6665 5.00065 16.4798 5.00065 16.2498V3.74984C5.00065 3.51984 5.20815 3.33317 5.46315 3.33317H10.0007V5.95817C10.0007 7.26817 11.0148 8.33317 12.2623 8.33317H15.0007V16.2498C15.0007 16.4798 14.7932 16.6665 14.5373 16.6665ZM11.6673 4.14817L13.9523 6.6665H12.2623C11.934 6.6665 11.6673 6.349 11.6673 5.95817V4.14817ZM16.4506 6.93984L11.914 1.93984C11.7556 1.76567 11.5323 1.6665 11.2965 1.6665H5.46315C4.28898 1.6665 3.33398 2.6015 3.33398 3.74984V16.2498C3.33398 17.3982 4.28898 18.3332 5.46315 18.3332H14.5373C15.7115 18.3332 16.6673 17.3982 16.6673 16.2498V7.49984C16.6673 7.29234 16.5898 7.09317 16.4506 6.93984Z" fill="currentColor" />
                </svg>

    },
    getIcnUser(){
        return  <svg className="image-nav" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.667 5.83333C11.667 4.91417 10.9195 4.16667 10.0003 4.16667C9.08116 4.16667 8.33366 4.91417 8.33366 5.83333C8.33366 6.7525 9.08116 7.5 10.0003 7.5C10.9195 7.5 11.667 6.7525 11.667 5.83333ZM13.3337 5.83333C13.3337 7.67167 11.8387 9.16667 10.0003 9.16667C8.16199 9.16667 6.66699 7.67167 6.66699 5.83333C6.66699 3.995 8.16199 2.5 10.0003 2.5C11.8387 2.5 13.3337 3.995 13.3337 5.83333ZM4.16699 16.6667C4.16699 13.45 6.78449 10.8333 10.0003 10.8333C13.2162 10.8333 15.8337 13.45 15.8337 16.6667C15.8337 17.1267 15.4612 17.5 15.0003 17.5C14.5395 17.5 14.167 17.1267 14.167 16.6667C14.167 14.3692 12.2978 12.5 10.0003 12.5C7.70283 12.5 5.83366 14.3692 5.83366 16.6667C5.83366 17.1267 5.46116 17.5 5.00033 17.5C4.53949 17.5 4.16699 17.1267 4.16699 16.6667Z" fill="currentColor"/>
                </svg>

    },
    getIcnStatistics() {
        return  <svg className="image-nav" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 10.1333C3.33431 10.1333 3.2 9.99902 3.2 9.83333V3.5C3.2 3.33431 3.33431 3.2 3.5 3.2H8.16667C8.33235 3.2 8.46667 3.33431 8.46667 3.5V9.83333C8.46667 9.99902 8.33235 10.1333 8.16667 10.1333H3.5ZM3.5 16.8C3.33431 16.8 3.2 16.6657 3.2 16.5V13.5C3.2 13.3343 3.33431 13.2 3.5 13.2H8.16667C8.33235 13.2 8.46667 13.3343 8.46667 13.5V16.5C8.46667 16.6657 8.33235 16.8 8.16667 16.8H3.5ZM11.8333 16.8C11.6676 16.8 11.5333 16.6657 11.5333 16.5V10.1667C11.5333 10.001 11.6676 9.86667 11.8333 9.86667H16.5C16.6657 9.86667 16.8 10.001 16.8 10.1667V16.5C16.8 16.6657 16.6657 16.8 16.5 16.8H11.8333ZM11.5333 3.5C11.5333 3.33431 11.6676 3.2 11.8333 3.2H16.5C16.6657 3.2 16.8 3.33431 16.8 3.5V6.5C16.8 6.66569 16.6657 6.8 16.5 6.8H11.8333C11.6676 6.8 11.5333 6.66569 11.5333 6.5V3.5Z" stroke="currentColor" strokeWidth="1.4"/>
                </svg>
    },
    getIcnDirectory() {
        return  <svg className="image-nav" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.50033 3.2H16.667C16.7387 3.2 16.8003 3.2616 16.8003 3.33333V8.33333C16.8003 8.40507 16.7387 8.46667 16.667 8.46667H2.50033C2.42859 8.46667 2.36699 8.40507 2.36699 8.33333V3.33333C2.36699 3.2616 2.42859 3.2 2.50033 3.2ZM2.50033 11.5333H16.667C16.7387 11.5333 16.8003 11.5949 16.8003 11.6667V16.6667C16.8003 16.7384 16.7387 16.8 16.667 16.8H2.50033C2.42859 16.8 2.36699 16.7384 2.36699 16.6667V11.6667C2.36699 11.5949 2.42859 11.5333 2.50033 11.5333Z" stroke="currentColor" strokeOpacity="0.5" strokeWidth="1.4"/>
                </svg>
    },
    getIcnTemplate() {
        return  <svg className="image-nav" transform="rotate(90)" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.50033 3.2H16.667C16.7387 3.2 16.8003 3.2616 16.8003 3.33333V8.33333C16.8003 8.40507 16.7387 8.46667 16.667 8.46667H2.50033C2.42859 8.46667 2.36699 8.40507 2.36699 8.33333V3.33333C2.36699 3.2616 2.42859 3.2 2.50033 3.2ZM2.50033 11.5333H16.667C16.7387 11.5333 16.8003 11.5949 16.8003 11.6667V16.6667C16.8003 16.7384 16.7387 16.8 16.667 16.8H2.50033C2.42859 16.8 2.36699 16.7384 2.36699 16.6667V11.6667C2.36699 11.5949 2.42859 11.5333 2.50033 11.5333Z" stroke="currentColor" strokeWidth="1.4"/>
                </svg>
    },
    getIcnArrowCollapse(isRotate) {
        return  <svg className="image-nav" style={{transform: isRotate ? 'rotate(360deg)' : 'rotate(270deg)', marginRight: '10px'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 7.5L10 12.5L15 7.5" stroke="#3366FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
    }
}

export default iconsNavigation