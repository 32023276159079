import client from "../client";

export async function getUploadsApi() {
    return client.get(
        '/api/uploads')
}

export async function getUploadApi(id) {
    return client.get(
        '/api/uploads/' + id)
}

export async function createUploadApi(formData) {
    return client.post(
        '/api/uploads',
        formData
    )
}

export async function getUploadsForSuperUserApi() {
    return client.get(
        '/super_user_api/uploads')
}

export async function getUploadForSuperUserApi(id) {
    return client.get(
        '/super_user_api/uploads/' + id)
}

export async function createUploadForSuperUserApi(formData) {
    return client.post(
        '/super_user_api/uploads',
        formData
    )
}