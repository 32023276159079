import TasksService from "../network/service/TasksService";
import * as actionConstants from "./ActionConstants";
import * as commonConstants from "../constants/CommonConstants";

const tasksActions = {

    getTasks(limit, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill) {
        return {
            type: actionConstants.START_LOAD_TASKS, payload: {
                limit: limit,
                filterBranch: filterBranch,
                filterState: filterState,
                filterExecutor: filterExecutor,
                displayName: displayName,
                createdSince: createdSince,
                createdTill: createdTill,
                finishedSince: finishedSince,
                finishedTill: finishedTill
            }
        }
    },

    loadYetTasks(limit, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill) {
        return {
            type: actionConstants.LOAD_YET_TASKS, payload: {
                limit: limit,
                filterBranch: filterBranch,
                filterState: filterState,
                filterExecutor: filterExecutor,
                displayName: displayName,
                createdSince: createdSince,
                createdTill: createdTill,
                finishedSince: finishedSince,
                finishedTill: finishedTill
            }
        }
    },

    async getTask(dispatch, id) {
        await TasksService.getTask(dispatch, id)
    },

    async cancelTask(dispatch, id) {
        await TasksService.cancelTask(dispatch, id)
        dispatch(this.getTasks(commonConstants.TASKS_LIMIT_LOAD))
    },

    async addComment(dispatch, id, text) {
        await TasksService.addComment(dispatch, id, text)
    }
}

export default tasksActions
