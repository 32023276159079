import AuthService from "../network/service/AuthService";

const authActions = {

    async authRequestCode(dispatch, phone) {
        await AuthService.authRequestCode(dispatch, phone)
    },

    async authCreateToken(dispatch, phone, sign, code) {
        await AuthService.authCreateToken(dispatch, phone, sign, code)
    },

    async renewToken(dispatch) {
        await AuthService.renewToken(dispatch)
        window.location.replace("/")
    }
}

export default authActions
