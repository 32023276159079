import IconsInspections from "../../../assets/icons/IconsInspections";
import IconsCommon from "../../../assets/icons/IconsCommon";
import FieldDropDown from "../dropdown/FieldDropDown";
import {Collapse} from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import Answer from "./Answer";
import {arrayMax} from "../../utils/ArrayUtil";
import {connect} from "react-redux";
import UploadActions from "../../../actions/UploadsActions";
import * as actionConstants from "../../../actions/ActionConstants";

class Question extends React.Component {
    constructor(props) {
        super(props);

        this.defaultAnswer = {
            id: -1,
            text: '',
            isTrue: false,
            isCreateTask: false,
            nameTask: ''
        }

        this.typesField = {
            nameQuestion: 'name',
            photo: 'photo',
            prompt: 'prompt',
            multiSelect: 'multiSelect',
            section: 'section',
            requiredPhoto: 'requiredPhoto',
            requiredComment: 'requiredComment'
        }

        this.state = {
            isOpen: true,
            question: this.props.question,
            index: this.props.index,
            filterSelect: {
                section: this.props.question.section,
                isSectionError: false
            },
            questionName: this.props.question.name,
            isQuestionNameError: false,
            prompt: this.props.question.prompt
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.uploadsFileState.file !== nextProps.uploadsFileState.file
            && this.props.uploadsFileState.fileId !== nextProps.uploadsFileState.fileId
            && nextProps.uploadsFileState.file !== null
            && nextProps.uploadsFileState.fileId !== null
            && nextProps.uploadsFileState.questionId !== null
            && nextProps.uploadsFileState.questionId === this.state.question.id) {
            if (this.props.uploadsFileState.file !== null && this.props.uploadsFileState.fileId !== null) {
                let questionFind = this.state.question.photos.find(photo => photo.id === nextProps.uploadsFileState.fileId)
                if (questionFind) {
                    let question = {...this.state.question}
                    let filteredPhotos = question.photos.filter(photo => photo.id !== nextProps.uploadsFileState.fileId)
                    question.photos = filteredPhotos.push({
                        id: nextProps.uploadsFileState.fileId,
                        file: nextProps.uploadsFileState.file
                    })
                    this.setState({
                        question: question
                    })
                } else {
                    let question = {...this.state.question}
                    question.photos.push({id: nextProps.uploadsFileState.fileId, file: nextProps.uploadsFileState.file})
                    this.setState({
                        question: question
                    })
                }
            } else {
                let question = {...this.state.question}
                question.photos.push({id: nextProps.uploadsFileState.fileId, file: nextProps.uploadsFileState.file})
                this.setState({
                    question: question
                })
            }

            this.props.onClearUpload()
        }

        return true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dropdownState.check !== this.props.dropdownState.check)
            this.checkFields()
    }

    render() {
        return (
            <div className="row row-input-inspection">
                <div className="col">
                    <div className="row name-block row-input-inspection">
                        <div className="col-2">
                            <div className="row h-100 align-items-center justify-content-end">
                                <div className="col-auto">
                                    <span className="label-inspection">{this.getLocale().inspection.user_form.question} № {this.state.index + 1}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                        <textarea
                            placeholder={this.getLocale().inspection.user_form.input_name_inspection}
                            className={ 'form-control ' + (this.state.isOpen
                                ? (this.state.isQuestionNameError ? 'input-error' : 'input-inspection')
                                : 'input-inspection-textarea') }
                            defaultValue={this.state.questionName}
                            onChange={(e) => {
                                this.setState({isQuestionNameError: !e.target.value})}
                            }
                            onInput={e =>
                                this.updateField(e.target.value, this.typesField.nameQuestion, this.state.index, this.state.question)}
                            maxLength={100} />
                        </div>
                        <div className="col-auto">
                            <div
                                className={'row justify-content-center align-items-center '
                                    + (this.state.isOpen ? 'arrow-question-rect' : 'arrow-question-rect-active')}
                                onClick={() => this.isOpenQuestion()}
                                aria-controls={"question_" + this.state.question.id}
                                aria-expanded={this.state.isOpen}>
                                {IconsInspections.getIcnArrowQuestionCollapse(this.state.isOpen)}
                            </div>
                        </div>
                        <div className="col-auto" style={{marginLeft: '20px'}}>
                            <div
                                className='row justify-content-center align-items-center arrow-question-rect-active'
                                onClick={() => this.deleteQuestion(this.state.question)}>
                                {IconsCommon.getIcnDelete()}
                            </div>
                        </div>
                    </div>
                    <Collapse
                        in={this.state.isOpen}>
                        <div className={"question_" + this.state.question.id}>
                            <div className="row align-items-center row-input-inspection">
                                <div className="col-2">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.section}*</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <FieldDropDown type={FieldDropDown.typesFilter.section}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}/>
                                </div>
                            </div>
                            <div className="row photo-block row-input-inspection">
                                <div className="col-2">
                                    <div className="row h-100 align-items-center justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.photo_for_prompt}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            {
                                                this.state.question.photos.find(photo => photo.id === 0)
                                                    ? <div className="row no-border justify-content-end">
                                                        <div className="col-auto">
                                                            <img className="add-photo-img" src={this.state.question.photos.find(photo => photo.id === 0).file.url} alt=""/>
                                                        </div>
                                                        <div className="col-auto icn-delete-image"
                                                            onClick={() => {this.deleteFile(0)}}>
                                                            {IconsCommon.getIcnDeleteImage()}
                                                        </div>
                                                      </div>
                                                    : <>
                                                        <label className="add-photo-label" htmlFor={"upload-photo-one" + this.state.question.id} />
                                                        <input className="input-upload-photo" type="file" name="photo" id={"upload-photo-one" + this.state.question.id}
                                                               onChange={(e) => this.uploadFile(e, 0)} />
                                                      </>
                                            }
                                        </div>
                                        <div className="col-auto">
                                            {
                                                this.state.question.photos.find(photo => photo.id === 1)
                                                    ? <div className="row no-border justify-content-end">
                                                        <div className="col-auto">
                                                            <img className="add-photo-img" src={this.state.question.photos.find(photo => photo.id === 1).file.url} alt=""/>
                                                        </div>
                                                        <div className="col-auto icn-delete-image"
                                                             onClick={() => {this.deleteFile(1)}}>
                                                            {IconsCommon.getIcnDeleteImage()}
                                                        </div>
                                                    </div>
                                                    : <>
                                                        <label className="add-photo-label" htmlFor={"upload-photo-two"  + this.state.question.id} />
                                                        <input className="input-upload-photo" type="file" name="photo" id={"upload-photo-two"  + this.state.question.id}
                                                               onChange={(e) => this.uploadFile(e, 1)} />
                                                      </>
                                            }
                                        </div>
                                        <div className="col-auto">
                                            {
                                                this.state.question.photos.find(photo => photo.id === 2)
                                                    ? <div className="row no-border justify-content-end">
                                                        <div className="col-auto">
                                                            <img className="add-photo-img" src={this.state.question.photos.find(photo => photo.id === 2).file.url} alt=""/>
                                                        </div>
                                                        <div className="col-auto icn-delete-image"
                                                             onClick={() => {this.deleteFile(2)}}>
                                                            {IconsCommon.getIcnDeleteImage()}
                                                        </div>
                                                    </div>
                                                    : <>
                                                        <label className="add-photo-label" htmlFor={"upload-photo-three"  + this.state.question.id} />
                                                        <input className="input-upload-photo" type="file" name="photo" id={"upload-photo-three"  + this.state.question.id}
                                                               onChange={(e) => this.uploadFile(e, 2)} />
                                                      </>
                                            }
                                        </div>
                                        <div className="col-auto">
                                            {
                                                this.state.question.photos.find(photo => photo.id === 3)
                                                    ? <div className="row no-border justify-content-end">
                                                        <div className="col-auto">
                                                            <img className="add-photo-img" src={this.state.question.photos.find(photo => photo.id === 3).file.url} alt=""/>
                                                        </div>
                                                        <div className="col-auto icn-delete-image"
                                                             onClick={() => {this.deleteFile(3)}}>
                                                            {IconsCommon.getIcnDeleteImage()}
                                                        </div>
                                                    </div>
                                                    : <>
                                                        <label className="add-photo-label" htmlFor={"upload-photo-four" + this.state.question.id} />
                                                        <input className="input-upload-photo" type="file" name="photo" id={"upload-photo-four"  + this.state.question.id}
                                                               onChange={(e) => this.uploadFile(e, 3)} />
                                                      </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row name-prompt row-input-inspection">
                                <div className="col-2">
                                    <div className="row h-100 align-items-center justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.prompt}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <textarea
                                        placeholder={this.getLocale().inspection.user_form.input_name_inspection}
                                        className={ 'form-control input-inspection' }
                                        defaultValue={this.state.prompt}
                                        onInput={e => {
                                            this.updateField(e.target.value, this.typesField.prompt, this.state.index, this.state.question)}
                                        }
                                        maxLength={100} />
                                </div>
                            </div>
                            <div className="row name-prompt row-input-inspection">
                                <div className="col-2">
                                    <div className="row h-100 align-items-center justify-content-end">
                                        <div className="col-auto">
                                            <span className="label-inspection">{this.getLocale().inspection.user_form.response_parameters}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="row no-gutters select-one">
                                        <div className="col-auto">
                                            <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                                <input type="radio" checked={this.state.question.multiSelect}
                                                       readOnly
                                                       onClick={() =>
                                                           this.updateField('', this.typesField.multiSelect, this.state.index, this.state.question)} />
                                            </div>
                                        </div>
                                        <div className="col-auto select-text">
                                            <div className="row h-100 no-gutters align-items-center">
                                                <span className={this.state.question.multiSelect ? "text-select-checked" : "text-select"}>
                                                    {this.getLocale().inspection.user_form.multitiple_choice}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters select-one">
                                        <div className="col-auto">
                                            <div className="row h-100 no-gutters align-items-center offset-select-radio">
                                                <input type="radio" checked={!this.state.question.multiSelect}
                                                       readOnly
                                                       onClick={() =>
                                                           this.updateField('', this.typesField.multiSelect, this.state.index, this.state.question)} />
                                            </div>
                                        </div>
                                        <div className="col-auto select-text">
                                            <div className="row h-100 no-gutters align-items-center">
                                                <span className={!this.state.question.multiSelect ? "text-select-checked" : "text-select"}>
                                                    {this.getLocale().inspection.user_form.only_choice}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.question.answers.map((answer, answerIndex) => {
                                    return <Answer key={answer.id}
                                                   answer={answer}
                                                   questionIndex={this.state.index}
                                                   answerIndex={answerIndex}
                                                   updateAnswer={this.updateAnswer}
                                                   deleteAnswer={this.deleteAnswer} />
                                })
                            }
                            <div className="row add-button-block row-input-inspection">
                                <div className="col-2" />
                                <div className="col-7">
                                    <div className="row no-gutters justify-content-end">
                                        <div className="col-auto">
                                            <button type="button" className="button-common"
                                                    onClick={() => this.addAnswer(this.state.index, this.state.question)}>
                                                {this.getLocale().common.add_answer}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-input-inspection">
                                <div className="col-2" />
                                <div className="col-7">
                                    <div className="row no-gutters">
                                        <div className="col-auto">
                                            <div className="row h-100 no-gutters align-items-center offset-select-radio custom-switch custom-switch-md">
                                                <div className="col-auto">
                                                    <input
                                                           id={"switchPhoto" + this.state.index}
                                                           type="checkbox"
                                                           className="custom-control-input"
                                                           checked={this.state.question.isRequiredPhoto}
                                                           readOnly
                                                           onClick={() =>
                                                               this.updateField('', this.typesField.requiredPhoto, this.state.index, this.state.question)} />
                                                    <label className={(this.state.question.isRequiredPhoto ? "text-select-checked" : "text-select") + " custom-control-label"}
                                                           htmlFor={"switchPhoto" + this.state.index}>{this.getLocale().inspection.user_form.stuff_must_add_photo}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-input-inspection">
                                <div className="col-2" />
                                <div className="col-7">
                                    <div className="row no-gutters">
                                        <div className="col-auto">
                                            <div className="row h-100 no-gutters align-items-center offset-select-radio custom-switch custom-switch-md">
                                                <input
                                                       id={"switchComment" + this.state.index}
                                                       type="checkbox"
                                                       className="custom-control-input"
                                                       checked={this.state.question.isRequiredComment}
                                                       readOnly
                                                       onClick={() =>
                                                           this.updateField('', this.typesField.requiredComment, this.state.index, this.state.question)}
                                                />
                                                <label className={(this.state.question.isRequiredComment ? "text-select-checked" : "text-select") + " custom-control-label"}
                                                       htmlFor={"switchComment" + this.state.index}>{this.getLocale().inspection.user_form.stuff_must_add_comment}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    uploadFile = (e, fileId) => {
        this.props.onCreateUpload(e.target.files[0], fileId, this.state.question.id)
    }

    deleteFile = (fileId) => {
        let question = {...this.state.question}
        question.photos = question.photos.filter(photo => photo.id !== fileId)
        this.setState({
            question: question
        })
        this.props.updateQuestions(this.props.index, question)
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })

        this.updateField(filterSelect.section, this.typesField.section, this.props.index, this.props.question)
    }

    addAnswer(index, question) {
        let maxId = arrayMax(question.answers)
        if (maxId || maxId === 0) {
            maxId = maxId + 1
        }

        const answer = {...this.defaultAnswer, id: maxId}
        question.answers.push(answer)

        this.props.updateQuestions(index, question)
    }

    isOpenQuestion() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    updateAnswer = (questionIndex, answerIndex, answer) => {
        const question = this.props.question
        question.answers[answerIndex] = answer
        this.props.updateQuestions(questionIndex, question)
    }

    updateField = (value, type, index, question) => {
        if (type === this.typesField.nameQuestion) {
            question.name = value
            this.setState({
                questionName: value
            })
        }

        if (type === this.typesField.photo)
            question.photo = value

        if (type === this.typesField.prompt) {
            question.prompt = value
            this.setState({
                prompt: value
            })
        }

        if (type === this.typesField.section)
            question.section = this.state.filterSelect.section

        if (type === this.typesField.requiredPhoto)
            question.isRequiredPhoto = !question.isRequiredPhoto

        if (type === this.typesField.requiredComment)
            question.isRequiredComment = !question.isRequiredComment

        if (type === this.typesField.multiSelect)
            question.multiSelect = !question.multiSelect

        this.props.updateQuestions(index, question)
    }

    checkFields() {
        if (!this.state.questionName)
            this.setState({
                isQuestionNameError: true
            })
    }

    deleteQuestion(removeQuestion) {
        this.props.deleteQuestion(removeQuestion)
    }

    deleteAnswer = (removeAnswer, questionIndex) => {
        const question = this.props.question
        const newAnswers = question.answers.slice()
        question.answers = newAnswers.filter(answer => answer.id !== removeAnswer.id)
        this.props.updateQuestions(questionIndex, question)
    }

}

Question.propTypes = {
    question: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    updateQuestions: PropTypes.func.isRequired,
    deleteQuestion: PropTypes.func.isRequired
};

export default connect(
    state => ({
        appState: state.app,
        dropdownState: state.dropdown,
        uploadsFileState: state.uploads
    }),
    dispatch => ({
        onCreateUpload: (file, fileId, questionId) => {
            dispatch(UploadActions.createUpload(file, fileId, questionId))
        },
        onClearUpload: () => {
            dispatch({type: actionConstants.CLEAR_UPLOAD, payload: {}})
        }
    })
)(Question);