import TemplatesService from "../network/service/TemplatesService";

const templatesActions = {

    async getTemplates(dispatch) {
        await TemplatesService.getTemplates(dispatch)
    },

    async getTemplate(dispatch, id) {
        await TemplatesService.getTemplate(dispatch, id)
    },

    async createTemplate(dispatch, nameTemplate, questions, sectionsFromServer) {
        const sections = this.getSections(questions, sectionsFromServer)
        await TemplatesService.createTemplate(dispatch, nameTemplate, sections)
    },

    async updateTemplate(dispatch, id, nameTemplate, questions, sectionsFromServer) {
        const sections = this.getSections(questions, sectionsFromServer)
        await TemplatesService.updateTemplate(dispatch, id, nameTemplate, sections)
    },

    async deleteTemplate(dispatch, id) {
        await TemplatesService.deleteTemplate(dispatch, id)
        await TemplatesService.getTemplates(dispatch)
    },

    async updateTemplateCustomFields(dispatch, id, customFields) {
        await TemplatesService.updateTemplateCustomFields(dispatch, id, customFields);
    },

    getSections(questions, sectionsFromServer) {
        let sections = []
        sectionsFromServer.forEach(sectionFromServer => {
            const section = this.getSection(questions, sectionFromServer)
            if (section !== null)
                sections.push(section)
        })

        return sections
    },

    getSection(questions, sectionFromServer) {
        const questionsForSection = questions.filter(question => question.section.displayName === sectionFromServer.displayName)
        if (questionsForSection.length !== 0) {
            return {
                title_id: sectionFromServer.id,
                position: sectionFromServer.id,
                questions: questionsForSection.map(question =>
                {
                    const uploads = question.photos.map(photo => photo.file.id)
                    return {
                        position: question.id,
                        display_name: question.name,
                        hint: question.prompt,
                        multi_select: question.multiSelect,
                        comment_required: question.isRequiredComment,
                        photo_required: question.isRequiredPhoto,
                        upload_ids: uploads,
                        variants: question.answers.map(answer => {
                                return {
                                    position: answer.id,
                                    display_name: answer.text,
                                    is_correct: answer.isTrue,
                                    task_required: answer.isCreateTask,
                                    task_title: answer.nameTask
                                }
                            }
                        )
                    }
                })
            }
        } else {
            return null
        }
    }
}

export default templatesActions
