import SectionsService from "../network/service/SectionsService";

const sectionsActions = {

    async getSections(dispatch) {
        await SectionsService.getSections(dispatch)
    },

    async getSection(dispatch, id) {
        await SectionsService.getSection(dispatch, id)
    },

    async createSection(dispatch, name) {
        await SectionsService.createSection(dispatch, name)
        await SectionsService.getSections(dispatch)
    },

    async deleteSection(dispatch, id) {
        await SectionsService.deleteSection(dispatch, id)
        await SectionsService.getSections(dispatch)
    }
}

export default sectionsActions
