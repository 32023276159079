import { combineReducers } from 'redux';

import app from './AppReducer'
import customFields from "./CustomFieldsReducer";
import inspections from './InspectionsReducer';
import tasks from './TasksReducer'
import login from './LoginReducer'
import users from './UsersReducer'
import cancelReasons from './CancelReasonsReducer'
import sections from './SectionsReducer'
import branches from './BranchesReducer'
import organizations from './OrganizationsReducer'
import templates from './TemplatesReducer'
import dropdown from './FieldDropDownReducer'
import globalError from './GlobalErrorReducer'
import uploads from './UploadsReducer'

export default combineReducers({
    app,
    customFields,
    inspections,
    tasks,
    login,
    users,
    cancelReasons,
    sections,
    branches,
    organizations,
    templates,
    dropdown,
    globalError,
    uploads
})
