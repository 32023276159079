import * as actionConstants from "../../actions/ActionConstants";

import { getCustomFieldsApi, getElementsCustomFieldApi } from "../../network/api/CustomFieldsApi";

const customFieldsService = {
  async getCustomFields(dispatch, name = null) {
    dispatch({
      type: actionConstants.START_LOAD_CUSTOM_FIELDS,
      payload: {},
    });

    try {
      const responseCustomFields = await getCustomFieldsApi(name);
      const customFields = responseCustomFields.data;

      const result = customFields.map((field) => {
        return {
          brandId: field.branch_id,
          createdAd: new Date(field.created_at),
          displayName: field.display_name,
          entityId: field.entity_id,
          id: field.id,
          isDeleted: field.is_deleted,
          updatedAd: new Date(field.updated_at),
        };
      });

      dispatch({
        type: actionConstants.SUCCESS_LOAD_CUSTOM_FIELDS,
        payload: {
          data: result,
        },
      });
    } catch (error) {
      dispatch({
        type: actionConstants.ERROR_LOAD_CUSTOM_FIELDS,
        payload: {
          error,
        },
      });
    }
  },

  async getElementsCustomFields(dispatch, customFieldId) {
    dispatch({
      type: actionConstants.START_LOAD_ELEMENTS_CUSTOM_FIELDS,
      payload: {},
    });

    try {
      const responseElementsCustomFields = await getElementsCustomFieldApi(customFieldId);
      const elementsCustomFields = responseElementsCustomFields.data;

      const result = elementsCustomFields.map((element) => {
        return {
          createdAd: new Date(element.created_at),
          customFieldIn: element.custom_field_id,
          displayName: element.display_name,
          id: element.id,
          isDeleted: element.is_deleted,
          updatedAd: new Date(element.updated_at),
        };
      });

      dispatch({
        type: actionConstants.SUCCESS_LOAD_ELEMENTS_CUSTOM_FIELDS,
        payload: {
          data: result,
        },
      });
    } catch (error) {
      dispatch({
        type: actionConstants.ERROR_LOAD_ELEMENTS_CUSTOM_FIELDS,
        payload: {
          error,
        },
      });
    }
  }
};

export default customFieldsService;
