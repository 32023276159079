export const CREATED = 'created'
export const STARTED = 'started'
export const FINISHED = 'finished'
export const CANCELLED = 'cancelled'
export const REJECTED = 'rejected'

export const STATUS = [
    {
        id: 0,
        status: CREATED
    },
    {
        id: 1,
        status: STARTED
    },
    {
        id: 2,
        status: FINISHED
    },
    {
        id: 3,
        status: CANCELLED
    },
    {
        id: 4,
        status: REJECTED
    }
]

export function getStatusForServer(id) {
    return STATUS.find(status => status.id === id).status
}

export function getStatusForApp(status, locale) {
    const id = STATUS.find(item => item.status === status).id
    return locale.statuses.find(item => item.id === id)
}