import client from "../client";
import {buildUrl} from "../../components/utils/ApiUtil";

export async function getTaskApi(id) {
    return client.get(
        '/api/tasks/' + id)
}

export async function getTasksApi(limit,
                                  offset,
                                  filterBranch,
                                  filterState,
                                  filterExecutor,
                                  displayName,
                                  createdSince,
                                  createdTill,
                                  finishedSince,
                                  finishedTill) {
    return client.get(buildUrl('/api/tasks', {
        limit: limit,
        offset: offset || 0,
        branch_ids: filterBranch || '',
        states: filterState || '',
        tech_ids: filterExecutor || '',
        display_name: displayName || '',
        created_since: createdSince || '',
        created_till: createdTill || '',
        finished_since: finishedSince || '',
        finished_till: finishedTill || ''
    }))
}