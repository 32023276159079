import client from "../client";

export async function getMyOrganizationApi() {
    return client.get(
        '/api/entity')
}

//super user
export async function getOrganizationApi(id) {
    return client.get(
        '/super_user_api/entities/' + id)
}

export async function getOrganizationsApi() {
    return client.get(
        '/super_user_api/entities')
}

export async function createOrganizationApi(name, timezone) {
    return client.post(
        '/super_user_api/entities',
        {
            display_name: name,
            timezone: timezone
        })
}

export async function updateOrganizationApi(id, name, timezone) {
    return client.patch(
        '/super_user_api/entities/' + id,
        {
            display_name: name,
            timezone: timezone
        })
}