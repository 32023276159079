import client from "../client";

export async function getUserApi(id) {
    return client.get(
        '/api/users/' + id)
}

export async function getUsersApi() {
    return client.get(
        '/api/users')
}

export async function getMeApi() {
    return client.get(
        '/api/users/me')
}

export async function updateAvatar(userId, uploadId) {
    return client.patch(
        '/api/users/' + userId + '/avatar',
        {
            upload_id: uploadId
        }
    )
}

export async function createUserApi(branchId, displayName, phone, role) {
    return client.post(
        '/api/users',
        {
            branch_id: branchId,
            display_name: displayName,
            phone: phone,
            role: role
        })
}

export async function updateUserApi(user, branchId, displayName, phone, role) {
    return client.patch(
        '/api/users/' + user.id,
        {
            branch_id: branchId,
            display_name: displayName,
            phone: phone,
            role: role
        })
}

export async function deleteUserApi(id) {
    return client.delete(
        '/api/users/' + id)
}

//super user
export async function getUserForSuperUserApi(id) {
    return client.get(
        '/super_user_api/users/' + id)
}

export async function getUsersForSuperUserApi() {
    return client.get(
        '/super_user_api/users')
}

export async function updateAvatarForSuperUserApi(userId, uploadId) {
    return client.patch(
        '/super_user_api/users/' + userId + '/avatar',
        {
            upload_id: uploadId
        }
    )
}

export async function createUserForSuperUserApi(entityId, branchId, displayName, phone, role) {
    return client.post(
        '/super_user_api/users',
        {
            branch_id: branchId,
            display_name: displayName,
            entity_id: entityId,
            phone: phone,
            role: role
        })
}

export async function updateUserForSuperUserApi(user, entityId, branchId, displayName, phone, role) {
    return client.patch(
        '/super_user_api/users/' + user.id,
        {
            branch_id: branchId,
            display_name: displayName,
            entity_id: entityId,
            phone: phone,
            role: role
        })
}

export async function deleteUserForSuperUserApi(id) {
    return client.delete(
        '/super_user_api/users/' + id)
}
