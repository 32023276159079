export const SUPER_USER = 'super_user'
export const ADMIN = 'admin'
export const INSPECTOR = 'inspector'
export const TECH = 'tech'

export const ROLES = [
    {
        id: 1,
        role: ADMIN
    },
    {
        id: 2,
        role: INSPECTOR
    },
    {
        id: 3,
        role: TECH
    }
]

export function getRoleForServer(id) {
    return ROLES.find(role => role.id === id).role
}

export function getRoleForApp(role, locale) {
    const id = ROLES.find(item => item.role === role)?.id
    if (id !== null && id !== undefined) {
        return locale.roles.find(item => item.id === id)
    } else {
        return locale.users.roles.super_user
    }

}