import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    cancelReasons: [],
    cancelReason: null,
    isCreateCancelReason: false,
    isDeleteCancelReason: false,
    process: null,
    error: null
};

export default function cancelReasons(state = initialState, action) {
    switch (action.type) {
        case actionConstants.START_LOAD_CANCEL_REASONS:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_CANCEL_REASONS:
            return {
                ...state,
                cancelReasons: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_LOAD_CANCEL_REASONS:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_LOAD_CANCEL_REASON:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_CANCEL_REASON:
            return {
                ...state,
                cancelReason: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_LOAD_CANCEL_REASON:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_CREATE_CANCEL_REASON:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_CREATE_CANCEL_REASON:
            return {
                ...state,
                isCreateCancelReason: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_CREATE_CANCEL_REASON:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_DELETE_CANCEL_REASON:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_DELETE_CANCEL_REASON:
            return {
                ...state,
                isDeleteCancelReason: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_DELETE_CANCEL_REASON:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.CLEAR_ERROR_COMPONENT:
            return {
                ...state,
                error: null
            }

        default: return {...state}
    }
}