export const EXECUTED = 'executedAt'
export const CREATED = 'createdAt'
export const UPDATE = 'updatedAt'

export function getTypeDates(locale) {
    return [
        {id: 0, displayName: locale.tasks.table_header.created},
        {id: 1, displayName: locale.tasks.table_header.finished}
    ]
}
