import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    process: false,
    users: [],
    user: null,
    anotherToken: null,
    inspectors: [],
    loggedUser: null,
    isCreateUser: false,
    isUpdateUser: false,
    isDeleteUser: false,
    error: null
};

export default function users(state = initialState, action) {
    switch (action.type) {
        case actionConstants.START_LOAD_USERS:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_USERS:
            return {
                ...state,
                users: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_LOAD_USERS:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_LOAD_USER:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_USER:
            return {
                ...state,
                user: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_LOAD_USER:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_LOAD_ME:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_ME:
            return {
                ...state,
                loggedUser: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_LOAD_ME:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_CREATE_USER:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_CREATE_USER:
            return {
                ...state,
                isCreateUser: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_CREATE_USER:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_UPDATE_USER:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_UPDATE_USER:
            return {
                ...state,
                isUpdateUser: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_UPDATE_USER:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_DELETE_USER:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_DELETE_USER:
            return {
                ...state,
                isDeleteUser: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_DELETE_USER:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_GET_TOKEN_ANOTHER_USER:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_GET_TOKEN_ANOTHER_USER:
            return {
                ...state,
                anotherToken: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_GET_TOKEN_ANOTHER_USER:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.CLEAR_ERROR_COMPONENT:
            return {
                ...state,
                error: null
            }

        default: return {...state}
    }
}