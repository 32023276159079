import IconsCommon from '../../assets/icons/IconsCommon'

import PropTypes from 'prop-types'

import React from 'react'
import {connect} from 'react-redux'

import DialogDelete from "../commons/dialog/DialogDelete";

import * as screenConstants from "../../constants/ScreenConstants";
import {minTwoDigits} from "../utils/TableUtil";
import TemplatesActions from "../../actions/TemplatesActions";
import * as buttonsConstants from "../../constants/ButtonConstants";
import ButtonImage from "../commons/ButtonImage";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";
import history from "../history";

class Templates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 5,
            isSortId: false,
            showDeleteDialog: {
                isShowDeleteDialog: false,
                templateId: -1
            },

            templates: null,
        }

        this.props.onGetTemplates().then()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.templatesState.templates !== prevProps.templatesState.templates) {
            this.setState({
                templates: this.props.templatesState.templates
            })
        }
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <span className="title-text">{this.getLocale().templates.title}</span>
                        </div>
                        <div className="col">
                            <div className="row h-100 align-items-center no-gutters">
                                <ButtonImage text={this.getLocale().templates.create_template} icon={buttonsConstants.ADD} click={this.openAddTemplate} />
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col table-block">
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" style={{width: '25%'}}>
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">ID</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" style={{width: '10%'}}>
                                        <span className="table-header-title">{this.getLocale().organisations.name}</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.templates?.map(item => {
                                            return (
                                                <tr className="table-row" key={item.id}>
                                                    <td><span className="table-ceil">{minTwoDigits(item.id)}</span></td>
                                                    <td onClick={() => this.onRowClick(item.id)}><span className="table-ceil-name">{item.displayName}</span></td>
                                                    <td>
                                                        <div className="table-icn-delete">
                                                            <div className="row no-gutters">
                                                                <div className="col-auto" onClick={() => this.openEditTemplate(item.id)}>
                                                                    {IconsCommon.getIcnEdit()}
                                                                </div>
                                                                <div className="col-auto" style={{marginLeft: '16px'}} onClick={() => this.showDialogDelete(item.id)}>
                                                                    {IconsCommon.getIcnDelete()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row no-gutters justify-content-center load-yet-block">
                        {
                            /*this.props.tasksState.isLoadYet
                            && this.props.tasksState.tasks.length >= commonConstants.TASKS_LIMIT_LOAD
                                ? <div className="col-auto" onClick={() => {
                                    this.loadYetTasks()
                                }}>
                                    <span className="clear-date">{this.getLocale().common.load_yet}</span>
                                </div>
                                : null*/
                        }
                    </div>
                    {
                        this.props.templatesState.error
                            ? <DialogError isShow={true}
                                           type={errorConstants.ERROR_COMPONENT}/>
                            : null
                    }
                    {
                        this.state.showDeleteDialog.isShowDeleteDialog
                            ? <DialogDelete isShow={true} onHide={this.hideDialogDelete} clickYes={this.deleteTemplate} />
                            : null
                    }
                </section>
        )
    }

    showDialogDelete = (templateId) => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = true
        newShowDeleteDialog.templateId = templateId
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    hideDialogDelete = () => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = false
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    openAddTemplate = () => {
        history.push(screenConstants.TEMPLATES)
        window.location.replace('/template/new')
    }

    openEditTemplate = (id) => {
        history.push(screenConstants.TEMPLATES)
        window.location.replace('/template/edit/' + id)
    }

    deleteTemplate = async () => {
        await this.props.onDeleteTemplate(this.state.showDeleteDialog.templateId)
    }

    onRowClick = (id) => {
        history.push(screenConstants.TEMPLATES)
        window.location.replace('/template/select/' + id)
    }

    getLocale() {
        return this.props.appState.locale
    }
}

Templates.propTypes = {
    page: PropTypes.number,   // Page number
}

export default connect(
    state => ({
        templatesState: state.templates,
        appState: state.app
    }),
    dispatch => ({
        onGetTemplates: async () => {
            await TemplatesActions.getTemplates(dispatch)
        },
        onDeleteTemplate: async (id) => {
            await TemplatesActions.deleteTemplate(dispatch, id)
        }
    })
)(Templates);