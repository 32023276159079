import client from "../client";
import {buildUrl} from "../../components/utils/ApiUtil";

export async function getRecheckApi(id) {
    return client.get(
        '/api/rechecks/' + id)
}

export async function getRechecksApi(limit,
                                     offset,
                                     filterBranch,
                                     filterState,
                                     filterInspector,
                                     displayName,
                                     executeSince,
                                     executeTill) {
    return client.get(buildUrl('/api/rechecks',{
        limit: limit,
        offset: offset || 0,
        branch_ids: filterBranch || '',
        states: filterState || '',
        inspector_ids: filterInspector || '',
        display_name: displayName || '',
        execute_since: executeSince || '',
        execute_till: executeTill || ''
    }))
}

export async function addCommentRecheckApi(id, text) {
    return client.post(
        '/api/rechecks/' + id + '/comment',
        {
            text: text
        })
}