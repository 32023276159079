import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    templates: [],
    template: null,
    questions: [],
    process: false,
    error: null,
    isCreatedTemplate: false,
    isUpdatedTemplate: false,
    isDeletedTemplate: false
};

export default function templates(state = initialState, action) {
    switch (action.type) {
        case actionConstants.START_LOAD_TEMPLATES:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_TEMPLATES:
            return {
                ...state,
                templates: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_LOAD_TEMPLATES:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_LOAD_TEMPLATE:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_TEMPLATE:
            return {
                ...state,
                template: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_LOAD_TEMPLATE:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_CREATE_TEMPLATE:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_CREATE_TEMPLATE:
            return {
                ...state,
                isCreatedTemplate: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_CREATE_TEMPLATE:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_UPDATE_TEMPLATE:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_UPDATE_TEMPLATE:
            return {
                ...state,
                isUpdatedTemplate: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_UPDATE_TEMPLATE:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_UPDATE_CUSTOM_FIELDS_TEMPLATE: {
            return {
                ...state,
                process: true,
            };
        }

        case actionConstants.SUCCESS_UPDATE_CUSTOM_FIELDS_TEMPLATE: {
            return {
                ...state,
                isUpdatedTemplate: action.payload.data,
                process: false,
            };
        }

        case actionConstants.ERROR_UPDATE_CUSTOM_FIELDS_TEMPLATE: {
            return {
                ...state,
                error: action.payload.error,
                process: false,
            };
        }

        case actionConstants.START_DELETE_TEMPLATE:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_DELETE_TEMPLATE:
            return {
                ...state,
                isDeletedTemplate: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_DELETE_TEMPLATE:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.UPDATE_TEMPLATE_QUESTIONS:
            return {
                ...state,
                questions: action.payload.data
            }

        case actionConstants.CLEAR_ERROR_COMPONENT:
            return {
                ...state,
                error: null
            }

        default: return {...state}
    }
}
