import React from "react"
import {StaticDateTimePicker} from "@material-ui/pickers"
import {
    Box,
    ClickAwayListener,
    Fade,
    Popper,
    TextField
} from "@material-ui/core";
import DateFns from "@date-io/date-fns";
import {LocalizationProvider} from '@material-ui/pickers';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment";

class DateTimeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchor: null
        }
    }

    render() {
        return <>
            <React.Fragment>
                <Box className="date-range-text-field-box" style={{marginLeft: 0}}>
                    <TextField className="date-time-text-field"
                               onClick={(e) => {
                                    this.setState({
                                        anchor: e.currentTarget,
                                        open: !this.state.open
                                    })}}
                               value={moment(this.props.val).format("DD.MM.YY HH:mm")}/>
                </Box>
            </React.Fragment>
            <Popper open={this.state.open}
                    anchorEl={this.state.anchor}
                    placement={"bottom"}
                    transition
                    className="date-popper">
                {({TransitionProps}) => (
                    <ClickAwayListener onClickAway={() => {
                        this.setState({ open: false })
                    }}>
                        <Fade {...TransitionProps}>
                            <Box>
                                <LocalizationProvider dateAdapter={DateFns}
                                                      locale={this.props.appState.dateFnsLocale}>
                                    <StaticDateTimePicker
                                        showToolbar={false}
                                        value={this.props.val}
                                        onAccept={() => {
                                            this.setState({
                                                open: false,
                                            })
                                        }}
                                        onChange={(date) => {
                                            this.props.setVal(date || new Date())
                                        }}
                                        renderInput={() => <></>}
                                        //inputFormat="dd/mm/yyyy"
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    }

    getLocale() {
        return this.props.appState.locale
    }

}

DateTimeComponent.propTypes = {
    val: PropTypes.object.isRequired,
    setVal: PropTypes.func.isRequired
}

export default connect(
    state => ({
        appState: state.app
    })
)(DateTimeComponent);