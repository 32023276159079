import React from 'react'
import {connect} from 'react-redux'

import TemplateInspectionCreate from "../commons/template/TemplateInspectionCreate";
import TemplatesActions from "../../actions/TemplatesActions";
import SectionsActions from "../../actions/SectionsActions";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";
import * as screenConstants from "../../constants/ScreenConstants";

class TemplateCreate extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.match.params.id)
            this.props.onGetTemplate(this.props.match.params.id).then()

        this.props.onGetSections().then()
    }

    render() {
        return (
            <section className="col white-section" style={{marginBottom: 30}}>
                <TemplateInspectionCreate
                    title={
                        this.props.match.params.id
                        ? this.getLocale().templates.editing_template
                        : this.getLocale().templates.create_template
                    }
                    nameForm={this.props.templatesState.template?.displayName}
                    save={this.saveTemplate}
                    cancel={this.backTemplates}
                />
                {
                    this.props.templatesState.error
                        ? <DialogError isShow={true}
                                       type={errorConstants.ERROR_COMPONENT}/>
                        : null
                }
            </section>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    saveTemplate = async (nameTemplate) => {
        if (!this.props.match.params.id)
            await this.props.onCreateTemplate(nameTemplate,
                this.props.templatesState.questions, this.props.sectionsState.sections)
        else
            await this.props.onUpdateTemplate(this.props.match.params.id, nameTemplate,
                this.props.templatesState.questions, this.props.sectionsState.sections)

        window.location.replace(screenConstants.TEMPLATES)
    }

    backTemplates() {
        window.location.replace(screenConstants.TEMPLATES)
    }
}

export default connect(
    state => ({
        appState: state.app,
        templatesState: state.templates,
        sectionsState: state.sections
    }),
    dispatch => ({
        onCreateTemplate: async (nameTemplate, questions, sections) => {
            await TemplatesActions.createTemplate(dispatch, nameTemplate, questions, sections)
        },
        onUpdateTemplate: async (id, nameTemplate, questions, sections) => {
            await TemplatesActions.updateTemplate(dispatch, id, nameTemplate, questions, sections)
        },
        onGetTemplate: async (id) => {
            await TemplatesActions.getTemplate(dispatch, id)
        },
        onGetSections: async () => {
            await SectionsActions.getSections(dispatch)
        }
    })
)(TemplateCreate);