import IconsCommon from '../../assets/icons/IconsCommon'
import * as screenConstants from '../../constants/ScreenConstants'
import * as sortTypeConstants from '../../constants/SortTypeConstants'
import * as commonConstants from '../../constants/CommonConstants'

import React from 'react'
import {connect} from 'react-redux'

import DialogDelete from "../commons/dialog/DialogDelete";

import TableDropDown from "../commons/dropdown/TableDropDown"
import * as roleConstants from "../../constants/RoleConstants";
import {minTwoDigits} from "../utils/TableUtil";
import TasksActions from "../../actions/TasksActions";
import UsersActions from "../../actions/UsersActions";
import * as statusConstants from "../../constants/StatusConstants";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";
import history from "../history";
import BranchesActions from "../../actions/BranchesActions";
import {sortDate} from "../utils/SortUtil";
import DateRange from "../commons/DateRangeComponent";
import FieldDropDown from "../commons/dropdown/FieldDropDown";
import moment from "moment";

class Tasks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 5,
            countSelect: 0,
            isSortCreated: false,
            isSortFinished: false,
            isSearchNameShow: true,
            showDeleteDialog: {
                isShowDeleteDialog: false,
                taskId: -1
            },

            filterSelect: {
                organisation: TableDropDown.emptyObject,
                branch: TableDropDown.emptyObject,
                status: TableDropDown.emptyObject,
                executor: TableDropDown.emptyObject
            },

            dropdownFilterSelect: {
                typeDate: sortTypeConstants.getTypeDates(this.getLocale())[0],
                isTypeDateError: false,
            },

            tasks: null,
            defaultDateRange: [moment().startOf('month').toDate(), moment().endOf('day').toDate()],
            dateRange: [moment().startOf('month').toDate(), moment().endOf('day').toDate()],
            search: ''
        }

        this.getTasks()
        this.props.onGetBranches()
        this.props.onGetUsers()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tasksState.tasks !== prevProps.tasksState.tasks) {
            this.setState({
                tasks: this.props.tasksState.tasks
            })
        }

        if (this.state.dateRange !== prevState.dateRange
            || this.state.search !== prevState.search) {
            this.getTasks()
        }
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <span className="title-text">{this.getLocale().tasks.title}</span>
                        </div>
                        {/*<div className="col">
                            <div className="row no-gutters">
                                <ButtonImage text={this.getLocale().tasks.download_tasks}
                                             isNotEnabled={!this.state.countSelect}
                                             icon={buttonsConstants.EXCEL}
                                             click={this.downloadTasks} />
                            </div>
                        </div>*/}
                    </div>
                    <div className="row no-gutters search-block">
                        <div className="col-auto">
                            <DateRange val={this.state.dateRange} maxDate={new Date()} childRef={ref => (this.dateRangeRef = ref)} setVal={(date) => {
                                this.setState({
                                    dateRange: date
                                })}} />
                        </div>
                        <div className="col-1" style={{marginLeft: 5}}>
                            <div className="row h-100">
                                <div className="col-auto type-date-label-container">
                                    <span className="type-date-label">
                                        {this.getLocale().tasks.type_date}
                                    </span>
                                </div>
                                <div className="col type-date-field-container" style={{marginBottom: 0}}>
                                    <FieldDropDown type={FieldDropDown.typesFilter.typeDate}
                                                   filterSelect={this.state.dropdownFilterSelect}
                                                   updateFilterSelect={this.updateDropdownFilterSelect}
                                                   placement={"bottom"}
                                                   classNamePopover={"type-date-popover"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-auto" style={{marginLeft: 5}} onClick={() => this.onResetClick()}>
                            <div className="row h-100 no-gutters align-items-center" style={{marginTop: 8}}>
                                <span className="clear-date">{this.getLocale().common.reset_date}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col table-block">
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" >
                                        {
                                            this.getCountSelectHeader()
                                        }
                                    </th>
                                    <th scope="col">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">ID</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        {
                                            <div className="row align-items-center no-gutters">
                                                <div className="col-auto">
                                                    <input onFocus={() => {this.setState({isSearchNameShow: !this.state.isSearchNameShow})}}
                                                           onBlur={() => {this.setState({isSearchNameShow: !this.state.isSearchNameShow})}}
                                                           onChange={this.searchNameTask} type="text" className="input-search"
                                                           placeholder={this.getLocale().tasks.table_header.name} aria-label="Name"
                                                    />
                                                </div>
                                                {
                                                    this.state.isSearchNameShow
                                                        ? <div className="col-auto">
                                                            {IconsCommon.getIcnSearch()}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        }

                                    </th>
                                    {
                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                            ? <TableDropDown type={TableDropDown.typesFilter.organisation}
                                                             filterSelect={this.state.filterSelect}
                                                             updateFilterSelect={this.updateFilterSelect}
                                                             updateData={this.updateData} />
                                            : null
                                    }
                                    <TableDropDown type={TableDropDown.typesFilter.branch}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData}
                                                   customStyle={{width: '10%'}}/>
                                    <th scope="col">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">{this.getLocale().tasks.table_header.created}</span>
                                            </div>
                                            <div className={ 'col-auto item-sort-table' + (this.state.isSortCreated ? ' item-sort-table-open' : '') }
                                                 onClick={this.onSortCreated}>
                                                {IconsCommon.getIcnHeaderArrow()}
                                            </div>

                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">{this.getLocale().tasks.table_header.finished}</span>
                                            </div>
                                            <div className={ 'col-auto item-sort-table' + (this.state.isSortFinished ? ' item-sort-table-open' : '') }
                                                 onClick={this.onSortFinished}>
                                                {IconsCommon.getIcnHeaderArrow()}
                                            </div>

                                        </div>
                                    </th>
                                    <TableDropDown type={TableDropDown.typesFilter.status}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData}
                                                   customStyle={{width: '10%'}}/>
                                    <TableDropDown type={TableDropDown.typesFilter.executor}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData} />
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.tasks?.map((item, index) => {
                                            return (
                                                <tr className="table-row" key={item.id}>
                                                    <td>{item.select}
                                                        <div
                                                            className={(item.select ? 'table-checkbox-active' : 'table-checkbox') + " row  no-gutters"}>
                                                            <input type="radio"
                                                                   defaultChecked={item.select}
                                                                   onClick={(e) => this.onSelectChanged(e, index)}/>
                                                        </div>
                                                    </td>
                                                    <td><span className="table-ceil">{minTwoDigits(item.id)}</span></td>
                                                    <td onClick={() => this.onRowClick(item.id)}><span className="table-ceil-name">{item.displayName}</span></td>
                                                    {
                                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                                            ? <td><span className="table-ceil">{item.organisation.display_name}</span></td>
                                                            : null
                                                    }
                                                    <td><span className="table-ceil">{item.branch?.display_name}</span></td>
                                                    <td><span className="table-ceil">{item.createdAt?.toLocaleString()}</span></td>
                                                    <td><span className="table-ceil">{
                                                        item.state === statusConstants.FINISHED
                                                        || item.state === statusConstants.REJECTED
                                                        || item.state === statusConstants.CANCELLED
                                                            ? item.updatedAt?.toLocaleString()
                                                            : ''
                                                    }</span></td>
                                                    <td><span className="table-ceil">
                                                        {statusConstants.getStatusForApp(item.state, this.getLocale()).displayName}
                                                    </span></td>
                                                    <td><span className="table-ceil">{item.user?.display_name}</span></td>
                                                    <td>
                                                        <div className="table-icn-delete">
                                                            <div className="row">
                                                                {
                                                                    item.state !== statusConstants.CANCELLED
                                                                    && item.state !== statusConstants.FINISHED
                                                                    && item.state !== statusConstants.REJECTED
                                                                        ? <div className="col-auto" onClick={() => this.showDialogDelete(item.id)}>
                                                                                {IconsCommon.getIcnCancel()}
                                                                          </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row no-gutters justify-content-center load-yet-block">
                        {
                            this.props.tasksState.isLoadYet
                            && this.props.tasksState.tasks.length >= commonConstants.TASKS_LIMIT_LOAD
                                ? <div className="col-auto" onClick={() => {
                                    this.loadYetTasks()
                                }}>
                                    <span className="clear-date">{this.getLocale().common.load_yet}</span>
                                </div>
                                : null
                        }
                    </div>
                    {
                        this.props.tasksState.error
                            ? <DialogError isShow={true}
                                           type={errorConstants.ERROR_COMPONENT}/>
                            : null
                    }
                    {
                        this.state.showDeleteDialog.isShowDeleteDialog
                            ? <DialogDelete isShow={true}
                                            cancel
                                            onHide={this.hideDialogDelete}
                                            clickYes={this.cancelTask} />
                            : null
                    }
                </section>
        )
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })
    }

    updateDropdownFilterSelect = (filterSelect) => {
        this.setState({
            dropdownFilterSelect: filterSelect
        })
        this.updateData()
    }

    showDialogDelete = (taskId) => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = true
        newShowDeleteDialog.taskId = taskId
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    hideDialogDelete = () => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = false
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    cancelTask = async () => {
        await this.props.onCancelTask(this.state.showDeleteDialog.taskId)
    }

    downloadTasks() {

    }

    onRowClick = (id) => {
        history.push(screenConstants.TASKS)
        window.location.replace('/task/select/' + id)
    }

    onSelectChanged = (e, index) => {
        const newTasks = this.props.tasksState.tasks.slice()
        if (e.target.checked) {
            const isSelect = !newTasks[index].select
            newTasks[index].select = isSelect
            const countSelect = isSelect ? this.state.countSelect + 1 : this.state.countSelect - 1
            this.setState({
                countSelect: countSelect,
                tasks: newTasks
            })
        }

    }

    getLocale() {
        return this.props.appState.locale
    }

    onResetClick() {
        this.dateRangeRef.handleDateChange(this.state.defaultDateRange)
    }

    getCountSelectHeader() {
        if (this.state.countSelect === 0)
            return  <div className="row justify-content-start">
                        <div className="col-auto">
                            <span className="table-header-title">{this.getLocale().tasks.table_header.choose}</span>
                        </div>
                    </div>

        else
            return (
                <div className="row align-items-center no-gutters">
                    <div className="col-auto" style={{marginRight: '11px'}}>
                        <span className="popover-header-text-another" style={{paddingLeft: '5px'}}>{this.state.countSelect}</span>
                    </div>
                    <div onClick={this.unselectAllTasks} className="col-auto">
                        {IconsCommon.getIcnRemove()}
                    </div>
                </div>
            )
    }

    unselectAllTasks = () => {
        const newTasks = this.props.tasksState.tasks.slice()
        newTasks.forEach((item) => {
            item.select = false
        })
        const countSelect = 0
        this.setState({
            countSelect: countSelect,
            tasks: newTasks
        })
    }

    searchNameTask = (e) => {
        const text = e.target.value
        this.setState({search: text})
    }

    getTasks = () => {
        const branch = this.state.filterSelect.branch.id !== -1 ? this.state.filterSelect.branch.id : ''
        const state = this.state.filterSelect.status.id !== -1 ? statusConstants.getStatusForServer(this.state.filterSelect.status.id) : ''
        const inspector = this.state.filterSelect.executor.id !== -1 ? this.state.filterSelect.executor.id : ''
        const dateSince = moment(this.state.dateRange[0]).startOf('day').toISOString(true)
        const dateTill = moment(this.state.dateRange[1]).endOf('day').toISOString(true)

        let createdSince = ''
        let createdTill = ''
        let finishedSince = ''
        let finishedTill = ''
        switch (this.state.dropdownFilterSelect.typeDate.id) {
            case 0:
                createdSince = dateSince
                createdTill = dateTill
                break
            case 1:
                finishedSince = dateSince
                finishedTill = dateTill
                break
            default:
                createdSince = dateSince
                createdTill = dateTill
                break
        }

        this.props.onGetTasks(branch, state, inspector, this.state.search,
            createdSince, createdTill, finishedSince, finishedTill)
    }

    loadYetTasks = () => {
        const branch = this.state.filterSelect.branch.id !== -1 ? this.state.filterSelect.branch.id : ''
        const state = this.state.filterSelect.status.id !== -1 ? statusConstants.getStatusForServer(this.state.filterSelect.status.id) : ''
        const inspector = this.state.filterSelect.executor.id !== -1 ? this.state.filterSelect.executor.id : ''
        const dateSince = moment(this.state.dateRange[0]).startOf('day').toISOString(true)
        const dateTill = moment(this.state.dateRange[1]).endOf('day').toISOString(true)

        let createdSince = ''
        let createdTill = ''
        let finishedSince = ''
        let finishedTill = ''
        switch (this.state.dropdownFilterSelect.typeDate.id) {
            case 0:
                createdSince = dateSince
                createdTill = dateTill
                break
            case 1:
                finishedSince = dateSince
                finishedTill = dateTill
                break
            default:
                createdSince = dateSince
                createdTill = dateTill
                break
        }

        this.props.onLoadYetTasks(branch, state, inspector, this.state.search,
            createdSince, createdTill, finishedSince, finishedTill)
    }

    updateData = () => {
        this.getTasks()
    }

    onSortCreated = () => {
        const sortArray = sortDate(this.state.tasks, this.state.isSortCreated, sortTypeConstants.CREATED)
        this.setState({
            isSortCreated: !this.state.isSortCreated,
            tasks: sortArray
        })
    }

    onSortFinished = () => {
        const sortArray = sortDate(this.state.tasks, this.state.isSortFinished, sortTypeConstants.UPDATE)
        this.setState({
            isSortFinished: !this.state.isSortFinished,
            tasks: sortArray
        })
    }
}

export default connect(
    state => ({
        tasksState: state.tasks,
        loggedUser: state.users.loggedUser,
        appState: state.app
    }),
    dispatch => ({
        onGetTasks: (filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill) => {
            dispatch(TasksActions.getTasks(commonConstants.TASKS_LIMIT_LOAD, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill))
        },
        onLoadYetTasks: (filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill) => {
            dispatch(TasksActions.loadYetTasks(commonConstants.TASKS_LIMIT_LOAD, filterBranch, filterState, filterExecutor, displayName, createdSince, createdTill, finishedSince, finishedTill))
        },
        onGetBranches: () => {
            dispatch(BranchesActions.getBranches())
        },
        onGetUsers: () => {
            dispatch(UsersActions.getUsers())
        },
        onCancelTask: async (id) => {
            await TasksActions.cancelTask(dispatch, id)
        }
    })
)(Tasks);