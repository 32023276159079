import React from 'react';
import PropTypes from 'prop-types'

import {Modal} from "react-bootstrap";

import InputMask from 'react-input-mask';

import FieldDropDown from "../commons/dropdown/FieldDropDown"
import * as roleConstants from "../../constants/RoleConstants";
import {connect} from "react-redux";
import * as actionConstants from "../../actions/ActionConstants";
import UploadActions from "../../actions/UploadsActions";
import IconsCommon from "../../assets/icons/IconsCommon";
import OrganizationsActions from "../../actions/OrganizationsActions";

class DialogUserEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShow: props.isShow,

            isNameError: false,
            isLastNameError: false,
            isPhoneError: false,

            file: null,

            filterSelect: {
                organisation: this.props.user && this.props.user.organization
                    ? {id: this.props.user.organization.id, displayName: this.props.user.organization.display_name}
                    : {id: -1, displayName: this.getLocale().inspection.user_form.input_org},
                isOrgError: false,
                branch: this.props.user && this.props.user.branch
                    ? {id: this.props.user.branch.id, displayName: this.props.user.branch.display_name}
                    : {id: -1, displayName: this.getLocale().inspection.user_form.input_branch},
                isBranchError: false,
                role: this.props.user && this.props.user.role
                    ? roleConstants.getRoleForApp(this.props.user.role, this.getLocale())
                    : {id: -1, displayName: this.getLocale().users.user_form.input_role},
                isRoleError: false,
            }
        }

        this.isSave = false

        this.nameRef = React.createRef()
        this.lastNameRef = React.createRef()
        this.phoneRef = React.createRef()

        if (this.props.edit)
            this.props.onGetUploads()

        if (this.props.loggedUser?.role === roleConstants.SUPER_USER) {
            this.props.onGetOrganizations().then()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isShow !== prevProps.isShow)
            this.setState({
                isShow: this.state.isShow
            })

        if (this.props.uploadsFileState.file !== prevProps.uploadsFileState.file
            && this.props.uploadsFileState.file !== null) {
            this.setState({
                file: this.props.uploadsFileState.file
            })

            this.props.onClearUpload()
        }

        if (this.props.edit
            && this.props.uploadsFileState.files !== prevProps.uploadsFileState.files
            && this.state.file === null) {
            let file = this.props.uploadsFileState.files.find(file => file.subjectId === this.props.user.id)
            this.setState({
                file: file
            })
        }
    }

    render() {
        let name = ''
        let family = ''
        if (this.props.user) {
            const displayNameSplit = this.props.user.displayName.split(' ')
            name = displayNameSplit[0]
            if (displayNameSplit.length > 1) {
                family = displayNameSplit[1]
            }
        }

        return (
            <Modal dialogClassName="modal-dialog-delete" show={this.state.isShow} onHide={this.hideModal}>
                <form>
                    <Modal.Header className="modal-dialog-header">
                        <div className="row w-100 no-gutters">
                            <div className="col-10">
                                <div className="row">
                                    <div className="col-auto">
                                        <span className="modal-dialog-header-body">
                                            {
                                                this.props.edit
                                                ? this.getLocale().users.edit_user
                                                : this.getLocale().users.add_user
                                            }
                                        </span>
                                    </div>
                                </div>
                                {/*<div className="row no-gutters" style={{marginTop: '16px'}}>
                                    <div className="col-auto" style={{marginRight: '16px'}}>
                                        {IconsButton.getIcnExcelColor()}
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-form-file-title">{this.getLocale().common.download_from_file}</span>
                                    </div>
                                </div>*/}
                            </div>
                            <div className="col-2">
                                <div className="row justify-content-end">
                                    {
                                        this.props.loggedUser?.role === roleConstants.SUPER_USER && !this.props.edit
                                            ? null
                                            : <div className="col-auto">
                                                {
                                                    this.state.file
                                                        ? <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <img className="add-user-photo-img" src={this.state.file.url} alt="" />
                                                            </div>
                                                            <div className="col-auto icn-delete-image"
                                                                 onClick={() => this.deleteFile()}>
                                                                {IconsCommon.getIcnDeleteImage()}
                                                            </div>
                                                        </div>
                                                        : <>
                                                            <label className="add-user-photo-label" htmlFor="upload-photo-two" />
                                                            <input className="input-upload-photo" type="file" name="photo" id="upload-photo-two"
                                                                   onChange={(e) => this.uploadFile(e, 0, this.props.user?.organizationId)} />
                                                        </>
                                                }
                                            </div>
                                    }


                                </div>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group row no-gutters">
                            <div className="col">
                                <div className="row align-items-center row-input-inspection">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <span className="label-inspection">{this.getLocale().users.user_form.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            ref={this.nameRef}
                                            type="text"
                                            placeholder={this.getLocale().users.user_form.input_name}
                                            className={ 'form-control ' + (this.state.isNameError ? 'input-error' : 'input-inspection') }
                                            onChange={(e) => {
                                                this.setState({isNameError: !e.target.value})}
                                            }
                                            maxLength={100}
                                            defaultValue={name}/>
                                    </div>
                                </div>
                                <div className="row align-items-center row-input-inspection">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <span className="label-inspection">{this.getLocale().users.user_form.last_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            ref={this.lastNameRef}
                                            type="text"
                                            placeholder={this.getLocale().users.user_form.input_last_name}
                                            className={ 'form-control ' + (this.state.isLastNameError ? 'input-error' : 'input-inspection') }
                                            onChange={(e) => {
                                                this.setState({isLastNameError: !e.target.value})}
                                            }
                                            maxLength={100}
                                            defaultValue={family}/>
                                    </div>
                                </div>
                                {
                                    this.props.role === roleConstants.SUPER_USER
                                        ? <div className="row align-items-center row-input-inspection">
                                            <div className="col-3">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        <span className="label-inspection">{this.getLocale().users.user_form.org}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <FieldDropDown type={FieldDropDown.typesFilter.organisation}
                                                               filterSelect={this.state.filterSelect}
                                                               updateFilterSelect={this.updateFilterSelect}/>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    this.props.role === roleConstants.SUPER_USER
                                        ? null
                                        :<div className="row align-items-center row-input-inspection">
                                            <div className="col-3">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        <span className="label-inspection">{this.getLocale().users.user_form.branch}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <FieldDropDown type={FieldDropDown.typesFilter.branch}
                                                               filterSelect={this.state.filterSelect}
                                                               updateFilterSelect={this.updateFilterSelect}/>
                                            </div>
                                        </div>
                                }
                                <div className="row align-items-center row-input-inspection">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <span className="label-inspection">{this.getLocale().users.user_form.phone}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <InputMask
                                                   ref={this.phoneRef}
                                                   placeholder=""
                                                   mask="+9 999 999 99 99"
                                                   maskChar=" "
                                                   className={ 'form-control ' + (this.state.isPhoneError ? 'input-error' : 'input-inspection') }
                                                   onChange={(e) => {
                                                       let phone = ''
                                                       if (e.target.value)
                                                           phone = (e.target.value.match(/[\d]/g) || []).join("")
                                                       this.setState({isPhoneError: phone.length < 11})}
                                                   }
                                                   defaultValue={this.props.user?.phone}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center row-input-inspection">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <span className="label-inspection">{this.getLocale().users.user_form.role}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <div className="col">
                                                <div className="row h-100 align-items-center">
                                                    <div className="col-auto">
                                                        <span className={ this.state.filterSelect !== null
                                                            && this.state.filterSelect.isRoleError ? 'text-role-error' : 'text-role' }>
                                                            {this.state.filterSelect.role.displayName}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="row justify-content-end align-items-center">
                                                    <div className="col-auto" style={{marginRight: '15px'}}>
                                                        <FieldDropDown type={FieldDropDown.typesFilter.role}
                                                                       filterSelect={this.state.filterSelect}
                                                                       updateFilterSelect={this.updateFilterSelect}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-dialog-delete-footer">
                        <button type="button" className="button-border-common" onClick={this.hideModal}>
                            {this.getLocale().common.cancel}
                        </button>
                        <button type="button" className="button-common" onClick={this.saveField}>
                            {this.getLocale().common.save}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    uploadFile = (e, fileId, entityId) => {
        this.props.onCreateUpload(e.target.files[0], fileId, entityId)
    }

    deleteFile = () => {
        this.setState({
            file: null
        })
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })

        if (this.isSave && !this.state.isNameError
            && !this.state.isLastNameError
            && !this.state.isPhoneError
            && filterSelect !== null
            && !filterSelect.isRoleError
            && !filterSelect.isOrgError
            && (this.props.role === roleConstants.SUPER_USER || !filterSelect.isBranchError)) {
            this.props.clickSave(
                this.props.user,
                this.nameRef.current.value,
                this.lastNameRef.current.value,
                this.phoneRef.current.value.replace(/\s+/g, '').trim(),
                roleConstants.getRoleForServer(filterSelect.role.id),
                filterSelect.organisation.id,
                filterSelect.branch.id,
                this.state.file?.id
            )
            this.hideModal()
            this.isSave = false
        } else {
            this.isSave = false
        }
    }

    saveField = () => {
        this.checkField()
        this.isSave = true
    }

    checkField() {
        if (!this.nameRef.current.value)
            this.setState({
                isNameError: true
            })

        if (!this.lastNameRef.current.value)
            this.setState({
                isLastNameError: true
            })

        let filterPhone = ''
        if (this.phoneRef.current.value)
            filterPhone = this.phoneRef.current.value.match(/[\d]/g).join("")
        if (!this.phoneRef.current.value || filterPhone.length < 11)
            this.setState({
                isPhoneError: true
            })

        this.props.onCheckFilterSelect()
    }

    hideModal = () => {
        this.setState({
            isShow: false
        })
        this.props.onHide()
    }
}

DialogUserEdit.propTypes = {
    isShow: PropTypes.bool.isRequired,
    clickSave: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
    user: PropTypes.object,
    role: PropTypes.string,
    onHide: PropTypes.func.isRequired
}

export default connect(
    state => ({
        appState: state.app,
        usersState: state.users,
        uploadsFileState: state.uploads,
        loggedUser: state.users.loggedUser
    }),
    dispatch => ({
        onCheckFilterSelect: () => {
            dispatch({
                type: actionConstants.CHECK_FIELD_FILTER_SELECT, payload: {
                    data: true
                }
            })
        },
        onGetUploads: () => {
            dispatch(UploadActions.getUploads())
        },
        onCreateUpload: (file, fileId, entityId) => {
            dispatch(UploadActions.createUpload(file, fileId, null, entityId))
        },
        onClearUpload: () => {
            dispatch({type: actionConstants.CLEAR_UPLOAD, payload: {}})
        },
        onGetOrganizations: async () => {
            await OrganizationsActions.getOrganizations(dispatch)
        }
    })
)(DialogUserEdit);
