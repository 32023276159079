const initialState = {
    error: null
};

export default function globalError(state = initialState, action) {
    const error = action.payload?.error;

    if (error) {
        return {
            ...state,
            error: error
        }
    }

    return {...state};
}