import React from 'react';
import PropTypes from 'prop-types'

import {Modal} from "react-bootstrap";

import * as roleConstants from "../../constants/RoleConstants";
import FieldDropDown from "../commons/dropdown/FieldDropDown";
import {connect} from "react-redux";
import * as actionConstants from "../../actions/ActionConstants";

class DialogBranchesEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: props.isShow,

            isNameError: false,

            filterSelect: {
                organisation: {id: -1, displayName: this.getLocale().inspection.user_form.input_org},
                isOrgError: false
            }
        }

        this.isSave = false

        this.nameRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isShow !== prevProps.isShow)
            this.setState({
                isShow: this.state.isShow
            })
    }

    render() {
        return (
            <Modal dialogClassName="modal-dialog-delete" show={this.state.isShow} onHide={this.hideModal}>
                <form>
                    <Modal.Header className="modal-dialog-header">
                        <div className="row w-100 no-gutters">
                            <div className="col-auto">
                                <span className="modal-dialog-header-body">{this.getLocale().branches.add_branch}</span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group row no-gutters">
                            <div className="col">
                                {
                                    this.props.role === roleConstants.SUPER_USER
                                        ? <div className="row align-items-center row-input-inspection">
                                            <div className="col-3">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        <span className="label-inspection">{this.getLocale().branches.user_form.org}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <FieldDropDown type={FieldDropDown.typesFilter.organisation}
                                                               filterSelect={this.state.filterSelect}
                                                               updateFilterSelect={this.updateFilterSelect}/>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className="row align-items-center row-input-inspection">
                                    <div className="col-3">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <span className="label-inspection">{this.getLocale().branches.user_form.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            ref={this.nameRef}
                                            type="text"
                                            placeholder={this.getLocale().branches.user_form.input_name}
                                            className={ 'form-control ' + (this.state.isNameError ? 'input-error' : 'input-inspection') }
                                            onChange={(e) => {
                                                this.setState({isNameError: !e.target.value})}
                                            }
                                            maxLength={100} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-dialog-delete-footer">
                        <button type="button" className="button-border-common" onClick={this.hideModal}>
                            {this.getLocale().common.cancel}
                        </button>
                        <button type="button" className="button-common" onClick={this.saveField}>
                            {this.getLocale().common.save}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })

        this.checkAndSave(filterSelect)
    }

    saveField = () => {
        this.checkField()
        this.isSave = true
    }

    checkField() {
        if (!this.nameRef.current.value)
            this.setState({
                isNameError: true
            })

        this.props.onCheckFilterSelect()

        if (this.props.role !== roleConstants.SUPER_USER) {
            this.checkAndSave(this.state.filterSelect)
        }
    }

    hideModal = () => {
        this.setState({
            isShow: false
        })
        this.props.onHide()
    }

    checkAndSave(filterSelect) {
        if (!this.state.isNameError && filterSelect !== null
            && !filterSelect.isOrgError) {
            this.props.clickSave(this.nameRef.current.value)
            this.hideModal()
        } else {
            this.isSave = false
        }
    }
}

DialogBranchesEdit.propTypes = {
    isShow: PropTypes.bool.isRequired,
    role: PropTypes.string,
    clickSave: PropTypes.func.isRequired
}

export default connect(
    state => ({
        appState: state.app
    }),
    dispatch => ({
        onCheckFilterSelect: () => {
            dispatch({
                type: actionConstants.CHECK_FIELD_FILTER_SELECT, payload: {}
            })
        }
    })
)(DialogBranchesEdit);
