import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    organizations: [],
    isCreateOrganization: false,
    process: false,
    error: null
};

export default function organizations(state = initialState, action) {
    switch (action.type) {
        case actionConstants.START_LOAD_ORGANIZATIONS:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_LOAD_ORGANIZATIONS:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_CREATE_ORGANIZATION:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_CREATE_ORGANIZATION:
            return {
                ...state,
                isCreateOrganization: action.payload.data,
                process: false
            }
        case actionConstants.ERROR_CREATE_ORGANIZATION:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.CLEAR_ERROR_COMPONENT:
            return {
                ...state,
                error: null
            }

        default: return {...state};
    }
}