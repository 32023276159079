import * as actionConstants from "../../actions/ActionConstants";
import {createOrganizationApi, getOrganizationsApi} from "../api/OrganizationsApi";
import client from "../client";

const organizationsService = {

    async getOrganizations(dispatch) {
        dispatch({type: actionConstants.START_LOAD_ORGANIZATIONS, payload: {}})
        try {
            const responseOrganizations = await getOrganizationsApi()
            const organizations = responseOrganizations.data

            const result = organizations.map(item => { return {
                id: item.id,
                createdAt: new Date(item.created_at),
                displayName: item.display_name,
                isDeleted: item.is_deleted,
                timezone: item.timezone,
                updatedAt: new Date(item.updated_at),
            }})

            dispatch({
                type: actionConstants.SUCCESS_LOAD_ORGANIZATIONS, payload: {
                    data: result
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_ORGANIZATIONS, payload: {
                    error: error
                }
            })
        }
    },

    async createOrganization(dispatch, displayName) {
        dispatch({type: actionConstants.START_CREATE_ORGANIZATION, payload: {}})
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        try {
            const response = await createOrganizationApi(displayName, timezone)
            dispatch({
                type: actionConstants.SUCCESS_CREATE_ORGANIZATION, payload: {
                    data: response.data.success
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_CREATE_ORGANIZATION, payload: {
                    error: error
                }
            })
        }
    },

    async deleteOrganization(dispatch, id) {
        dispatch({type: actionConstants.START_DELETE_ORGANIZATION, payload: {}})
        try {
            const response = await client.delete(
                '/super_user_api/entities/' + id,
            )

            dispatch({
                type: actionConstants.SUCCESS_DELETE_ORGANIZATION, payload: {
                    data: response.data.success
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_DELETE_ORGANIZATION, payload: {
                    error: error
                }
            })
        }
    }
}

export default organizationsService;
