import React from "react";

import Tippy from "@tippyjs/react";
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/dist/tippy.css';

import IconsCommon from "../../../assets/icons/IconsCommon";
import {ListGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class StatisticsDropDown extends React.Component{

    constructor(props) {
        super(props);

        this.typesNameFilter = {
            organisation: this.getLocale().inspections.table_header.organisation,
            branch: this.getLocale().inspections.table_header.branch,
            inspectionList: this.getLocale().common.show,
            lineChart: this.getLocale().common.show,
            circleChart: this.getLocale().common.show,
            barChart: this.getLocale().common.show
        }


        this.state = {
            isPopoverOpen: {
                organisation: false,
                branch: false,
                inspectionList: false,
                lineChart: false,
                circleChart: false,
                barChart: false
            },

            isArrowFilter: {
                organisation: false,
                branch: false,
                inspectionList: false,
                lineChart: false,
                circleChart: false,
                barChart: false
            },

            filterSelect: this.props.filterSelect
        }
    }

    render() {
        return this.getDropdownTypeHeader(this.props.type, this.props.customStyle)
    }

    getLocale() {
        return this.props.appState.locale
    }

    getDropdownTypeHeader(typesFilter, style) {
        return (<div className="col-auto" style={style}>
            <div className="row no-gutters">
                <div className="col-auto" style={{margin: '0px', padding: '0px'}}>
                    <span className="table-header-title">{this.typesNameFilter[typesFilter]}</span>
                </div>
                {
                    this.getDropdownTypeHeaderPopover(typesFilter, this)
                }
            </div>
        </div>)
    }

    getDropdownTypeHeaderPopover(typesFilter) {
        return <Tippy
            arrow={IconsCommon.getIcnPopoverArrow(this.getArrowFilter(typesFilter))}
            content={
                <div
                    className="popover-container"
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                    <ListGroup>
                        {
                            this.getPopoverList(typesFilter).map((value, index) => {
                                if (index === 0)
                                    return (<ListGroup.Item
                                        key={value.id}
                                        className="list-item-custom"
                                        onMouseEnter={() => {
                                            this.setArrowFilter(!this.getArrowFilter(typesFilter) ? true : this.getArrowFilter(typesFilter), typesFilter)
                                        }}
                                        onMouseLeave={() => {
                                            this.setArrowFilter(this.getArrowFilter(typesFilter) ? false : this.getArrowFilter(typesFilter), typesFilter)
                                        }}
                                        onClick={() => {
                                            this.setFilterSelect(value.type, typesFilter)
                                        }}>
                                        {value.type}
                                    </ListGroup.Item>)
                                else
                                    return (
                                        <ListGroup.Item
                                            key={value.id}
                                            className="list-item-custom"
                                            onClick={() => {
                                                this.setFilterSelect(value.type, typesFilter)
                                            }}>
                                            {value.type}
                                        </ListGroup.Item>
                                    )

                            })
                        }
                    </ListGroup>
                </div>
            }
            interactive={true}
            visible={this.getPopoverOpen(typesFilter)}
            placement={'bottom-start'}
            onHide={() => this.setArrowFilter(false, typesFilter)}
            onClickOutside={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
            {
                this.getDropdownTypeHeaderAll(typesFilter, this)
            }
        </Tippy>
    }

    getDropdownTypeHeaderAll(typesFilter) {
        return <div className="col-auto" style={{padding: '0px', cursor: 'pointer'}}>
            <div className="row no-gutters">
                <div className="col-auto"
                     style={{margin: '0px', padding: '0px'}}
                     onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                    <span className="popover-header-text-all">{this.getFilterSelect(typesFilter)
                        ? this.getFilterSelect(typesFilter) : this.getLocale().common.all}
                    </span>
                </div>
                <div className="col-auto">
                    <div className="row h-100 justify-content-center align-items-end no-gutters">
                        {
                            this.getIcon(typesFilter, this)
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    getIcon(typesFilter) {
        switch (typesFilter) {
            case StatisticsDropDown.typesFilter.organisation:
                let result = null
                if (!this.getFilterSelect(typesFilter))
                    result = <div className="col-auto"
                                  style={{marginTop: '2px', marginLeft: '3px'}}
                                  onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                        {IconsCommon.getIcnHeaderArrow()}
                    </div>
                else
                    result = <div className="col-auto"
                           style={{marginLeft: '8px'}}
                           onClick={() => {this.clearFilter(typesFilter)}}>
                        {IconsCommon.getIcnRemove()}
                    </div>
                return result
            case StatisticsDropDown.typesFilter.branch:
                return !this.getFilterSelect(typesFilter)
                    ? <div className="col-auto"
                           style={{marginTop: '2px', marginLeft: '3px'}}
                           onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                        {IconsCommon.getIcnHeaderArrow()}
                    </div>
                    : <div className="col-auto"
                           style={{marginLeft: '8px'}}
                           onClick={() => {this.clearFilter(typesFilter)}}>
                        {IconsCommon.getIcnRemove()}
                    </div>
            default: return <div className="col-auto"
                                 style={{marginTop: '2px', marginLeft: '3px'}}
                                 onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                                {IconsCommon.getIcnHeaderArrow()}
                            </div>
        }
    }

    getPopoverOpen(typesFilter) {
        switch (typesFilter) {
            case StatisticsDropDown.typesFilter.organisation:
                return this.state.isPopoverOpen.organisation
            case StatisticsDropDown.typesFilter.branch:
                return this.state.isPopoverOpen.branch
            case StatisticsDropDown.typesFilter.inspectionList:
                return this.state.isPopoverOpen.inspectionList
            case StatisticsDropDown.typesFilter.lineChart:
                return this.state.isPopoverOpen.lineChart
            case StatisticsDropDown.typesFilter.circleChart:
                return this.state.isPopoverOpen.circleChart
            case StatisticsDropDown.typesFilter.barChart:
                return this.state.isPopoverOpen.barChart
            default: return null
        }
    }

    setPopoverOpen(isOpen, typesFilter) {
        let popoverState = this.state.isPopoverOpen
        switch (typesFilter) {
            case StatisticsDropDown.typesFilter.organisation:
                popoverState.organisation = isOpen
                break
            case StatisticsDropDown.typesFilter.branch:
                popoverState.branch = isOpen
                break
            case StatisticsDropDown.typesFilter.inspectionList:
                popoverState.inspectionList = isOpen
                break
            case StatisticsDropDown.typesFilter.lineChart:
                popoverState.lineChart = isOpen
                break
            case StatisticsDropDown.typesFilter.circleChart:
                popoverState.circleChart = isOpen
                break
            case StatisticsDropDown.typesFilter.barChart:
                popoverState.barChart = isOpen
                break
            default: break
        }

        this.setState({isPopoverOpen: popoverState})
    }

    getPopoverList(typesFilter) {
        switch (typesFilter) {
            case StatisticsDropDown.typesFilter.organisation:
                return this.props.filterData.filterOrganisation
            case StatisticsDropDown.typesFilter.branch:
                return this.props.filterData.filterBranch
            case StatisticsDropDown.typesFilter.inspectionList:
                return this.props.filterData.filterInspectionList
            case StatisticsDropDown.typesFilter.lineChart:
                return this.props.filterData.filterLineChart
            case StatisticsDropDown.typesFilter.circleChart:
                return this.props.filterData.filterCircleChart
            case StatisticsDropDown.typesFilter.barChart:
                return this.props.filterData.filterBarChart
            default: return null
        }
    }

    getArrowFilter(typesFilter) {
        switch (typesFilter) {
            case StatisticsDropDown.typesFilter.organisation:
                return this.state.isArrowFilter.organisation
            case StatisticsDropDown.typesFilter.branch:
                return this.state.isArrowFilter.branch
            case StatisticsDropDown.typesFilter.inspectionList:
                return this.state.isArrowFilter.inspectionList
            case StatisticsDropDown.typesFilter.lineChart:
                return this.state.isArrowFilter.lineChart
            case StatisticsDropDown.typesFilter.circleChart:
                return this.state.isArrowFilter.circleChart
            case StatisticsDropDown.typesFilter.barChart:
                return this.state.isArrowFilter.barChart
            default: return null
        }
    }

    setArrowFilter(filter, typesFilter) {
        let arrowFilterState = this.state.isArrowFilter
        switch (typesFilter) {
            case StatisticsDropDown.typesFilter.organisation:
                arrowFilterState.organisation = filter
                break
            case StatisticsDropDown.typesFilter.branch:
                arrowFilterState.branch = filter
                break
            case StatisticsDropDown.typesFilter.inspectionList:
                arrowFilterState.inspectionList = filter
                break
            case StatisticsDropDown.typesFilter.lineChart:
                arrowFilterState.lineChart = filter
                break
            case StatisticsDropDown.typesFilter.circleChart:
                arrowFilterState.circleChart = filter
                break
            case StatisticsDropDown.typesFilter.barChart:
                arrowFilterState.barChart = filter
                break
            default: break
        }

        this.setState({isArrowFilter: arrowFilterState})

    }

    getFilterSelect(typesFilter) {
        switch (typesFilter) {
            case StatisticsDropDown.typesFilter.organisation:
                return this.state.filterSelect.organisation
            case StatisticsDropDown.typesFilter.branch:
                return this.state.filterSelect.branch
            case StatisticsDropDown.typesFilter.inspectionList:
                return this.state.filterSelect.inspectionList
            case StatisticsDropDown.typesFilter.lineChart:
                return this.state.filterSelect.lineChart
            case StatisticsDropDown.typesFilter.circleChart:
                return this.state.filterSelect.circleChart
            case StatisticsDropDown.typesFilter.barChart:
                return this.state.filterSelect.barChart
            default: return null
        }
    }

    setFilterSelect(filterSelect, typesFilter) {
        let filterSelectState = this.state.filterSelect
        switch (typesFilter) {
            case StatisticsDropDown.typesFilter.organisation:
                filterSelectState.organisation = filterSelect
                break
            case StatisticsDropDown.typesFilter.branch:
                filterSelectState.branch = filterSelect
                break
            case StatisticsDropDown.typesFilter.inspectionList:
                filterSelectState.inspectionList = filterSelect
                break
            case StatisticsDropDown.typesFilter.lineChart:
                filterSelectState.lineChart = filterSelect
                break
            case StatisticsDropDown.typesFilter.circleChart:
                filterSelectState.circleChart = filterSelect
                break
            case StatisticsDropDown.typesFilter.barChart:
                filterSelectState.barChart = filterSelect
                break
            default: break
        }

        this.setState({filterSelect: filterSelectState})
        this.props.updateFilterSelect(filterSelectState)
        this.props.updateData()
    }

    clearFilter = (typesFilter) => {
        this.setFilterSelect('', typesFilter)
    }

}

StatisticsDropDown.typesFilter = {
    organisation: 'organisation',
    branch: 'branch',
    inspectionList: 'inspectionList',
    lineChart: 'lineChart',
    circleChart: 'circleChart',
    barChart: 'barChart'
}

StatisticsDropDown.propTypes = {
    type: PropTypes.string.isRequired,
    filterData: PropTypes.object.isRequired,
    filterSelect: PropTypes.object.isRequired,
    updateFilterSelect: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired
};

export default connect(
    state => ({
        appState: state.app
    })
)(StatisticsDropDown);