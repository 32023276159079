
import PropTypes from 'prop-types'

import React from 'react'
import {connect} from 'react-redux'

import DialogDelete from "../commons/dialog/DialogDelete";

import DialogOrganizationsEdit from "./DialogOrganizationsEdit";
import {minTwoDigits} from "../utils/TableUtil";
import OrganizationsActions from "../../actions/OrganizationsActions";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";

class Organizations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 5,
            isSortId: false,
            showOrganizationsEditDialog: {
                isShowOrganizationsEditDialog: false,
                isEdit: false
            },
            showDeleteDialog: {
                isShowDeleteDialog: false,
                organizationId: -1
            },

            organizations: null,
        }

        this.props.onGetOrganizations().then()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.organizationsState.organizations !== prevProps.organizationsState.organizations) {
            this.setState({
                organizations: this.props.organizationsState.organizations
            })
        }
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <span className="title-text">{this.getLocale().organisations.title}</span>
                        </div>
                        <div className="col">
                            <div className="row h-100 align-items-center no-gutters">
                                <button className="directory-button" onClick={this.showOrganizationsEditDialog}>
                                    {this.getLocale().organisations.add_organisation}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col table-block">
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" style={{width: '15%'}}>
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">ID</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" style={{width: '25%'}}>
                                        <span className="table-header-title">{this.getLocale().organisations.name}</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.organizations?.map(item => {
                                            return (
                                                <tr className="table-row" key={item.id}>
                                                    <td><span className="table-ceil">{minTwoDigits(item.id)}</span></td>
                                                    <td><span className="table-ceil-name-non-pointer">{item.displayName}</span></td>
                                                    {/*<td>
                                                        <div className="table-icn-delete">
                                                            <div className="row">
                                                                <div className="col-auto" onClick={() => this.showDialogDelete(item.id)}>
                                                                    {IconsCommon.getIcnDelete()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>*/}
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        this.state.showDeleteDialog.isShowDeleteDialog
                            ? <DialogDelete isShow={true} onHide={this.hideDialogDelete} clickYes={this.deleteOrganization} />
                            : null
                    }
                    {
                        this.state.showOrganizationsEditDialog.isShowOrganizationsEditDialog
                            ? <DialogOrganizationsEdit isShow={true} onHide={this.hideOrganizationsEditDialog} clickSave={this.saveOrganization} />
                            : null
                    }
                    {
                        this.props.organizationsState.error
                            ? <DialogError isShow={true}
                                           type={errorConstants.ERROR_COMPONENT}/>
                            : null
                    }
                </section>
        )
    }

    showDialogDelete = (id) => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = true
        newShowDeleteDialog.organizationId = id
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    hideDialogDelete = () => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = false
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    showOrganizationsEditDialog = (edit) => {
        let newShowOrganizationsEditDialog = this.state.showOrganizationsEditDialog
        newShowOrganizationsEditDialog.isShowOrganizationsEditDialog = true
        newShowOrganizationsEditDialog.isEdit = !!edit
        this.setState({
            showOrganizationsEditDialog: newShowOrganizationsEditDialog
        })
    }

    hideOrganizationsEditDialog = () => {
        let newShowOrganizationsEditDialog = this.state.showOrganizationsEditDialog
        newShowOrganizationsEditDialog.isShowOrganizationsEditDialog = false
        this.setState({
            showOrganizationsEditDialog: newShowOrganizationsEditDialog
        })
    }

    deleteOrganization = async () => {
        await this.props.onDeleteOrganization(this.state.showDeleteDialog.organizationId)
    }

    saveOrganization = async (displayName) => {
        await this.props.onCreateOrganization(displayName)
    }

    getLocale() {
        return this.props.appState.locale
    }
}

Organizations.propTypes = {
    page: PropTypes.number,   // Page number
}

export default connect(
    state => ({
        organizationsState: state.organizations,
        appState: state.app
    }),
    dispatch => ({
        onGetOrganizations: async () => {
            await OrganizationsActions.getOrganizations(dispatch)
        },
        onCreateOrganization: async (displayName) => {
            await OrganizationsActions.createOrganization(dispatch, displayName)
        },
        onDeleteOrganization: async (id) => {
            await OrganizationsActions.deleteOrganization(dispatch, id)
        }
    })
)(Organizations);