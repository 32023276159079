import * as actionConstants from "../../actions/ActionConstants";
import {
    activateVersionTemplate,
    createTemplateApi,
    getTemplateApi,
    getTemplatesApi,
    updateTemplateApi,
    updateTemplateCustomFieldsApi,
    updateTemplateSectionsApi,
} from "../api/TemplatesApi";
import {getDirItemsWithKindApi} from "../api/DirItemsApi";
import * as dirItemsConstants from "../../constants/DirItemsConstants";
import client from "../client";

const templatesService = {

    async getTemplates(dispatch) {
        dispatch({type: actionConstants.START_LOAD_TEMPLATES, payload: {}})
        try {
            const responseTemplates = await getTemplatesApi()
            const templates = responseTemplates.data

            const result = templates.map(item => { return {
                id: item.id,
                createdAt: new Date(item.created_at),
                currentFormVersion: {
                    createdAt: new Date(item.current_form_version.created_at),
                    entityId: item.current_form_version.entity_id,
                    formId: item.current_form_version.form_id,
                    formVersionCustomFields: item.current_form_version.form_version_custom_fields.map((field) => {
                        return {
                            customField: {
                                branchId: field.custom_field.branch_id,
                                createdAt: new Date(field.custom_field.created_at),
                                displayName: field.custom_field.display_name,
                                entityId: field.custom_field.entity_id,
                                id: field.custom_field.id,
                                isDeleted: field.custom_field.is_deleted,
                                updatedAt: new Date(field.custom_field.updated_at),
                            },
                            id: field.id,
                            position: field.position,
                        };
                    }),
                    id: item.current_form_version.id,
                    isDeleted: item.current_form_version.is_deleted,
                    state: item.current_form_version.state,
                    updatedAt: new Date(item.current_form_version.updatedAt),
                    version: item.current_form_version.version,
                },
                displayName: item.display_name,
                organizationId: item.entity_id,
                isDeleted: item.is_deleted,
                updatedAt: new Date(item.updated_at)
            }})

            dispatch({
                type: actionConstants.SUCCESS_LOAD_TEMPLATES, payload: {
                    data: result
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_TEMPLATES, payload: {
                    error: error
                }
            })
        }
    },

    async getTemplate(dispatch, id) {
        dispatch({type: actionConstants.START_LOAD_TEMPLATE, payload: {}})
        try {
            const responseTemplate = await getTemplateApi(id)
            const template = responseTemplate.data

            const responseDirItems = await getDirItemsWithKindApi(dirItemsConstants.SECTION)
            const sectionsDir = responseDirItems.data

            const result = {
                id: template.id,
                createdAt: new Date(template.created_at),
                currentFormVersion: {
                    createdAt: new Date(template.current_form_version.created_at),
                    entityId: template.current_form_version.entity_id,
                    formId: template.current_form_version.form_id,
                    formVersionCustomFields: template.current_form_version.form_version_custom_fields.map((field) => {
                        return {
                            customField: {
                                branchId: field.custom_field.branch_id,
                                createdAt: new Date(field.custom_field.created_at),
                                displayName: field.custom_field.display_name,
                                entityId: field.custom_field.entity_id,
                                id: field.custom_field.id,
                                isDeleted: field.custom_field.is_deleted,
                                updatedAt: new Date(field.custom_field.updated_at),
                            },
                            id: field.id,
                            position: field.position,
                        };
                    }),
                    id: template.current_form_version.id,
                    isDeleted: template.current_form_version.is_deleted,
                    state: template.current_form_version.state,
                    updatedAt: new Date(template.current_form_version.updatedAt),
                    version: template.current_form_version.version,
                },
                questions: this.getAllQuestions(template.current_form_version.sections, sectionsDir),
                displayName: template.display_name,
                organizationId: template.entity_id,
                isDeleted: template.is_deleted,
                updatedAt: new Date(template.updated_at)
            }

            dispatch({
                type: actionConstants.SUCCESS_LOAD_TEMPLATE, payload: {
                    data: result
                }
            })

            dispatch({
                type: actionConstants.UPDATE_TEMPLATE_QUESTIONS, payload: {
                    data: this.getAllQuestions(template.current_form_version.sections, sectionsDir)
                }
            })

        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_TEMPLATE, payload: {
                    error: error
                }
            })
        }
    },

    async createTemplate(dispatch, nameTemplate, sections) {
        dispatch({type: actionConstants.START_CREATE_TEMPLATE, payload: {}})
        try {
            const responseForms = await createTemplateApi(nameTemplate)
            let form = responseForms.data.model
            let success = responseForms.data.success

            if (sections.length > 0) {
                const responseUpdateForm = await updateTemplateSectionsApi(form.id, sections)
                form = responseUpdateForm.data.model
                success = responseUpdateForm.data.success

                await activateVersionTemplate(form.id, form.current_form_version.id)
            }

            dispatch({
                type: actionConstants.SUCCESS_CREATE_TEMPLATE, payload: {
                    data: success
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_CREATE_TEMPLATE, payload: {
                    error: error
                }
            })
        }
    },

    async updateTemplate(dispatch, id, nameTemplate, sections) {
        dispatch({type: actionConstants.START_UPDATE_TEMPLATE, payload: {}})
        try {
            const responseForms = await updateTemplateApi(id, nameTemplate)
            let form = responseForms.data.model
            let success = responseForms.data.success

            if (sections.length > 0) {
                const responseUpdateForm = await updateTemplateSectionsApi(form.id, sections)
                form = responseUpdateForm.data.model
                success = responseUpdateForm.data.success

                await activateVersionTemplate(form.id, form.current_form_version.id)
            }

            dispatch({
                type: actionConstants.SUCCESS_UPDATE_TEMPLATE, payload: {
                    data: success
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_UPDATE_TEMPLATE, payload: {
                    error: error
                }
            })
        }
    },

    async deleteTemplate(dispatch, id) {
        dispatch({type: actionConstants.START_DELETE_TEMPLATE, payload: {}})
        try {
            const responseForms = await client.delete(
                '/api/forms/' + id
            )
            let success = responseForms.data.success

            dispatch({
                type: actionConstants.SUCCESS_DELETE_TEMPLATE, payload: {
                    data: success
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_DELETE_TEMPLATE, payload: {
                    error: error
                }
            })
        }
    },

    async updateTemplateCustomFields(dispatch, id, customFields) {
        dispatch({
            type: actionConstants.START_UPDATE_CUSTOM_FIELDS_TEMPLATE,
            payload: {},
        });

        try {
            const custom_fields = [
                {
                    custom_field_id: customFields,
                    position: 1,
                },
            ];

            const responseForms = await updateTemplateCustomFieldsApi(id, custom_fields);
            const success = responseForms.data.success;

            dispatch({
                type: actionConstants.SUCCESS_UPDATE_CUSTOM_FIELDS_TEMPLATE,
                payload: {
                    data: success,
                },
            });
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_UPDATE_CUSTOM_FIELDS_TEMPLATE,
                payload: {
                    error,
                },
            });
        }
    },

    getAllQuestions(sections, sectionsDir) {
        let resultQuestions = []
        sections.forEach(section => {
            const sectionDir = sectionsDir.find(item => item.id === section.title_id)
            const questions = this.getQuestions(section, sectionDir)
            if (questions !== null)
                resultQuestions = resultQuestions.concat(questions)
        })

        return resultQuestions
    },

    getQuestions(section, sectionDir) {
        const questions = section.questions
        if (questions.length !== 0) {
            return questions.map((question, index) => {
                return {
                id: question.id,
                name: question.display_name,
                photos: question.uploads.map((upload, index) => {
                        return {id: index, file: {
                            id: upload.id, url: process.env.REACT_APP_HOST_URL + upload.url }
                        }
                    }),
                prompt: question.hint ? question.hint : '',
                section: { id: sectionDir.id, displayName: sectionDir.display_name },
                multiSelect: question.multi_select,
                isRequiredPhoto: question.photo_required,
                isRequiredComment: question.comment_required,
                answers: question.variants.map(variant => { return {
                        id: variant.id,
                        text: variant.display_name,
                        isTrue: variant.is_correct,
                        isCreateTask: variant.task_required,
                        nameTask: variant.task_title ? variant.task_title : ''
                    }
                })
            }})
        } else {
            return null
        }
    }
}

export default templatesService;
