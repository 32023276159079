export default {
    title: "X-Ray",
    inspections: {
        title: "Inspections",
        create_inspection: "Create inspection",
        download_inspection: "Download inspection",
        table_header: {
            choose: 'Choose',
            name: 'Name',
            type: 'Type',
            created: 'Created',
            executed: 'Assigned',
            mode: 'Mode',
            organisation: 'Entity',
            branch: 'Branch',
            status: 'Status',
            history_status: 'Status history',
            inspector: 'Inspector',
            phone: 'Phone number'
        },
        period: {
            on_hours: "for hours",
            on_days: "for days",
            on_weeks: "for weeks"
        },
        filter: {
            up: "Forward",
            back: "Back"
        }
    },

    tasks: {
        title: "Tasks",
        download_tasks: "Download tasks",
        table_header: {
            choose: 'Choose',
            name: 'Name',
            mode: 'Mode',
            organisation: 'Entity',
            branch: 'Branch',
            status: 'Status',
            history_status: 'Status history',
            executor: 'Assignee',
            phone: 'Phone number',
            created: 'Created',
            finished: 'Completed'
        },
        type_date: "Date type"
    },

    task: {
        title: "Task"
    },

    statistics: {
        title: "Stats",
        date: "Date",
        delta: "Change",
        inspections: "Inspections",
        status_inspections: "Inspection status",
        mode: "Mode",
        end: "Completed",
        canceled: "Cancelled",
        completed: "Completed",
        completed_1: "Done",
        canceled_1: "Cancelled",
        during_week: "for the week",
        during_month: "for the month",
        for_three_month: "for three months",
        for_half_year: "for the half-year",
        for_year: "for the year",
        by_days: "by days",
        by_weeks: "by weeks",
        by_months: "by months",
        week: "week",
        year: "year",
        filter: {
            up: "Forward",
            back: "Back"
        }
    },

    users: {
        title: "Users",
        question_delete_user: "Delete user?",
        mail_busy: "Mail is taken",
        add_user: "Add user",
        edit_user: "Edit users",
        search_users: "User search",
        roles: {
            super_user: { id: 0, displayName: "Super-user" },
            admin: "Administrator",
        },
        table_header: {
            name: "Name Surname",
            role: "Role",
            org_name: "Entity",
            branch: "Branch",
            phone: "Phone number",
            last_activity: "Last active",
            start_name: "A",
            end_name: "Z"
        },
        user_form: {
            name: "Name",
            input_name: "Enter name",
            last_name: "Surname",
            input_last_name: "Enter surname",
            org: "Entity",
            input_org: "Not selected",
            branch: "Branch",
            input_branch: "Not selected",
            phone: "Phone number",
            input_phone: "+",
            role: "Role",
            input_role: "Add role"
        }
    },

    login: {
        title: "Login",
        email: "Email",
        phone: "Phone number",
        password: "Enter password",
        continue: "Continue",
        sign_in: "Sign in",
        version: "Version",
        make_in: "Made in",
        code: "Code",
        sent_code: "Code sent to the specified number"
    },

    organisations: {
        title: "Entities",
        name: "Name",
        add_organisation: "Add entity",
        edit_organisation: "Edit entity",
        user_form: {
            name: "Name",
            input_name: "Enter name"
        }
    },

    cancel_reasons: {
        title: "Cancellation reasons",
        add_cancel_reason: "Add cancellation reason",
        add_reason: "Add reason",
        edit_cancel_reason: "Edit cancellation reason",
        table_header: {
            name: "Name",
            org_name: "Entity",
        },
        user_form: {
            name: "Name",
            input_name: "Enter name",
            org: "Entity",
            input_org: "Not specified"
        }
    },

    sections: {
        title: "Sections",
        add_section: "Add section",
        edit_section: "Edit section",
        table_header: {
            name: "Name",
            org_name: "Entity",
        },
        user_form: {
            name: "Name",
            input_name: "Enter name",
            org: "Entity",
            input_org: "Not specified"
        }
    },

    inspection: {
        title: "Check",
        didnt_change: "No changes",
        nothing_found: "Nothing found",
        comment_inspection: "Inspection comment",
        files: "Files",
        client: "Client",
        phone: "Phone number",
        email: "Email",
        address: "Address",
        story_statuses: "Status history",
        export: "Export",
        new_inspection: "New inspection",
        verification_form: "Inspection form",
        check_list_inspection: "Inspection checklist",
        date: "Date",
        load_check_list: "Upload checklist",
        recheck_question: "Changes saved successfully?",
        rejected: "Rejected",
        approved: "Approved",
        user_form: {
            org: "Entity",
            input_org: "Not specified",
            branch: "Branch",
            input_branch: "Not specified",
            inspector: "Inspector",
            input_inspector: "Not specified",
            name_inspection: "Inspecion name",
            input_name_inspection: "",
            section: "Section",
            input_section: "Not specified",
            template: "Select from templates",
            input_template: "Not specified",
            add_question: "Add question",
            question: "Question",
            photo_for_prompt: "Reference photo",
            prompt: "Reference",
            response_parameters: "Answer parameters",
            variant_answer: "Answer",
            name_task: "Task name",
            stuff_must_add_photo: "Staff member must add photo",
            stuff_must_add_comment: "Staff member must add comment",
            multitiple_choice: "Multiple choice",
            only_choice: "Single choice",
            custom_fields: "Add field",
            input_custom_fields: "Not specified",
            input_custom_field_item: "Not specified",
        }
    },

    branches: {
        title: "Branches",
        add_branch: "Add branch",
        edit_branch: "Edit branch",
        table_header: {
            name: "Name",
            org_name: "Entity"
        },
        user_form: {
            name: "Name",
            input_name: "Enter name",
            org: "Entity",
            input_org: "Not specified"
        }
    },

    templates: {
        title: "Templates",
        add_template: "Add template",
        create_template: "Create template",
        save_as_template: "Save as template",
        save_template: "Inspection template save",
        template_creation: "Create template",
        editing_template: "Edit template"
    },

    common: {
        directories: "Directories",
        not_data: "No data",
        delete_question: "Delete?",
        understandably: "Clear",
        cancel: "Cancel",
        apply: "Apply",
        delete: "Delete",
        save: "Save",
        import: "Import",
        replace: "Replace",
        all: "All",
        all_types: "All types",
        selected: "Selected",
        select: "Select",
        select_all: "Select all",
        highlight_all: "Highlight all",
        select_nothing: "Nothing to select",
        add: "Add",
        select_man: "Selected",
        not_select_man: "Not selected",
        not_select_woman: "Not selected",
        not_select_it: "Not selected",
        recovery: "Restore",
        default: "Defaults",
        warning_fill: "Mandatory field",
        yes: "Yes",
        no: "No",
        copied: "Copied",
        edit: "Edit",
        load: "Loading",
        download: "Download",
        exit: "Exit",
        log_off: "Log off",
        cancel_delete: "Undo removal",
        comment: "Comment",
        description: "Description",
        add_comment: "Add description",
        what_delete_element: "Are you sure you want to delete the element?",
        what_cancel_element: "Are you sure you want to cancel the element?",
        input_text: "Enter text",
        download_from_file: "Import from file",
        show_more: "Show more",
        show: "Show",
        create_task_for_wrong_answer: "Create a task for a wrong answer",
        add_answer: "Add answer",
        delete_answer: "Delete answer",
        time: "Time",
        prompt: "Hint",
        change_user: "Change user",
        oh: "Oops..",
        period_from: "From",
        period_to: "Till",
        reset_date: "Reset date",
        load_yet: "Load more"
    },



    pagination: {
        first: "First",
        last: "Last"
    },

    roles: [
        {
            id: 1,
            displayName: "Administrator"
        },
        {
            id: 2,
            displayName: "Inspector"
        },
        {
            id: 3,
            displayName: "Technician"
        }
    ],

    statuses: [
        {
            id: 0,
            displayName: "Created"
        },
        {
            id: 1,
            displayName: "In progress"
        },
        {
            id: 2,
            displayName: "Completed"
        },
        {
            id: 3,
            displayName: "Cancelled"
        },
        {
            id: 4,
            displayName: "Rejected"
        }
    ],

    types: [
        {
            id: 0,
            displayName: "Primary"
        },
        {
            id: 1,
            displayName: "Secondary"
        }
    ],

    errors: {
        notFoundPhone: "Phone number not found.",
        wrongCode: "Incorrect verification code.",
        duplicatedName: "The name already exists.",
        internalError: "Internal server error",
        undefinedError: "Something went wrong. Try reloading the page."
    }


}
