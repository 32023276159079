import IconsCommon from '../../assets/icons/IconsCommon'
import * as screenConstants from '../../constants/ScreenConstants'
import * as roleConstants from '../../constants/RoleConstants'
import * as statusConstants from '../../constants/StatusConstants'
import * as typeConstants from '../../constants/TypeConstants'
import * as commonConstants from '../../constants/CommonConstants'

import React from 'react'
import {connect} from 'react-redux'

import ButtonImage from "../commons/ButtonImage";
import * as buttonsConstants from '../../constants/ButtonConstants'
import DialogDelete from "../commons/dialog/DialogDelete";

import TableDropDown from "../commons/dropdown/TableDropDown"
import {minTwoDigits} from "../utils/TableUtil";
import InspectionsActions from "../../actions/InspectionsActions";
import UsersActions from "../../actions/UsersActions";
import BranchesActions from "../../actions/BranchesActions";
import DialogError from "../commons/dialog/DialogError";
import * as errorConstants from "../../constants/ErrorConstants";

import history from '../history'
import DateRange from "../commons/DateRangeComponent";
import {sortDate} from "../utils/SortUtil";
import * as sortTypeConstants from "../../constants/SortTypeConstants";
import moment from "moment";

class Inspections extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 5,
            countSelect: 0,
            isSortExecuted: false,
            isSearchNameShow: true,
            showDeleteDialog: {
                isShowDeleteDialog: false,
                inspectionId: -1,
                type: ''
            },

            filterSelect: {
                type: TableDropDown.emptyObject,
                mode: TableDropDown.emptyObject,
                inspector: TableDropDown.emptyObject,
                organisation: TableDropDown.emptyObject,
                branch: TableDropDown.emptyObject,
                status: TableDropDown.emptyObject
            },

            inspections: null,
            defaultDateRange: [moment().startOf('month').toDate(), moment().endOf('day').toDate()],
            dateRange: [moment().startOf('month').toDate(), moment().endOf('day').toDate()],
            search: ''
        }

        this.getInspections()
        this.props.onGetBranches()
        this.props.onGetUsers()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.inspectionsState.inspections !== prevProps.inspectionsState.inspections) {
            this.setState({
                inspections: this.props.inspectionsState.inspections
            })
        }

        if (this.state.dateRange !== prevState.dateRange
            || this.state.search !== prevState.search) {
            this.getInspections()
        }
    }

    render() {
        return (
                <section className="col common-section">
                    <div className="row no-gutters title title-block">
                        <div className="col">
                            <span className="title-text">{this.getLocale().inspections.title}</span>
                        </div>
                    </div>
                    <div className="row no-gutters search-block">
                        <div className="col-auto">
                            <DateRange val={this.state.dateRange} maxDate={new Date()} childRef={ref => (this.dateRangeRef = ref)} setVal={(date) => {
                                this.setState({
                                    dateRange: date
                                })}}
                            />
                        </div>
                        <div className="col-auto" onClick={() => this.onResetClick()}>
                            <div className="row h-100 no-gutters align-items-center" style={{marginTop: 8}}>
                                <span className="clear-date">{this.getLocale().common.reset_date}</span>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row h-100 no-gutters justify-content-center" style={{marginTop: 18}}>
                                <div className="col-auto">
                                    <ButtonImage text={this.getLocale().inspections.create_inspection}
                                                 icon={buttonsConstants.ADD}
                                                 click={this.addInspection} />
                                </div>
                                {/*<ButtonImage text={this.getLocale().inspections.download_inspection}
                                             isNotEnabled={!this.state.countSelect}
                                             icon={buttonsConstants.EXCEL}
                                             click={this.downloadInspections} />*/}
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col table-block">
                            <table className="table table-borderless" >
                                <thead>
                                <tr>
                                    <th scope="col" >
                                        {
                                            this.getCountSelectHeader()
                                        }
                                    </th>
                                    <th scope="col">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">ID</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        {
                                            <div className="row align-items-center no-gutters">
                                                <div className="col-auto">
                                                    <input onFocus={() => {this.setState({isSearchNameShow: !this.state.isSearchNameShow})}}
                                                           onBlur={() => {this.setState({isSearchNameShow: !this.state.isSearchNameShow})}}
                                                           onChange={this.searchNameInspection} type="text" className="input-search"
                                                           placeholder={this.getLocale().inspections.table_header.name} aria-label="Name"
                                                    />
                                                </div>
                                                {
                                                    this.state.isSearchNameShow
                                                        ? <div className="col-auto">
                                                            {IconsCommon.getIcnSearch()}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        }

                                    </th>
                                    <TableDropDown type={TableDropDown.typesFilter.type}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData} />
                                    <th scope="col" style={{width: '12%'}}>
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <span className="table-header-title">{this.getLocale().inspections.table_header.executed}</span>
                                            </div>
                                            <div className={ 'col-auto item-sort-table' + (this.state.isSortExecuted ? ' item-sort-table-open' : '') }
                                                 onClick={this.sortOnExecuted}>
                                                {IconsCommon.getIcnHeaderArrow()}
                                            </div>

                                        </div>
                                    </th>
                                    {
                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                            ? <TableDropDown type={TableDropDown.typesFilter.organisation}
                                                             filterSelect={this.state.filterSelect}
                                                             updateFilterSelect={this.updateFilterSelect}
                                                             updateData={this.updateData} />
                                            : null
                                    }
                                    <TableDropDown type={TableDropDown.typesFilter.branch}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData}
                                                   customStyle={{width: '10%'}}/>
                                    <TableDropDown type={TableDropDown.typesFilter.status}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData}
                                                   customStyle={{width: '10%'}}/>
                                    <TableDropDown type={TableDropDown.typesFilter.inspector}
                                                   filterSelect={this.state.filterSelect}
                                                   updateFilterSelect={this.updateFilterSelect}
                                                   updateData={this.updateData} />
                                    <th scope="col"><div className="table-icn-delete" /></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.inspections?.map((item, index) => {
                                            return (
                                                <tr className="table-row" key={item.id}>
                                                    <td>{item.select}
                                                        <div
                                                            className={(item.select ? 'table-checkbox-active' : 'table-checkbox') + " row  no-gutters"}>
                                                            <input type="radio"
                                                                   defaultChecked={item.select}
                                                                   onClick={(e) => this.onSelectChanged(e, index)}/>
                                                        </div>
                                                    </td>
                                                    <td><span className="table-ceil">{minTwoDigits(item.id)}</span></td>
                                                    <td onClick={() => this.onRowClick(item.id, item.type)}><span className="table-ceil-name">{item.displayName}</span></td>
                                                    <td><span className="table-ceil">{typeConstants.getTypeForApp(item.type, this.getLocale()).displayName}</span></td>
                                                    <td><span className="table-ceil">{item.executedAt?.toLocaleString()}</span></td>
                                                    {
                                                        this.props.loggedUser?.role === roleConstants.SUPER_USER
                                                            ? <td><span className="table-ceil">{item.organisation}</span></td>
                                                            : null
                                                    }
                                                    <td><span className="table-ceil">{item.branch?.display_name}</span></td>
                                                    <td><span className="table-ceil">
                                                        {statusConstants.getStatusForApp(item.state, this.getLocale()).displayName}: {item.updatedAt.toLocaleString()}
                                                    </span></td>
                                                    <td><span className="table-ceil">{item.user?.display_name}</span></td>
                                                    <td>
                                                        <div className="table-icn-delete">
                                                            <div className="row">
                                                                {
                                                                    item.state !== statusConstants.CANCELLED
                                                                        && item.state !== statusConstants.FINISHED
                                                                        && item.state !== statusConstants.REJECTED
                                                                    ? <div className="col-auto" onClick={() => this.showDialogDelete(item.id, item.type)}>
                                                                            {IconsCommon.getIcnCancel()}
                                                                      </div>
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row no-gutters justify-content-center load-yet-block">
                        {
                            this.props.inspectionsState.isLoadYet
                            && this.props.inspectionsState.inspections.length >= commonConstants.INSPECTIONS_LIMIT_LOAD
                             ? <div className="col-auto" onClick={() => {
                                    this.loadYetInspections()
                                }}>
                                    <span className="clear-date">{this.getLocale().common.load_yet}</span>
                                </div>
                             : null
                        }
                    </div>
                    {
                        this.props.inspectionsState.error
                            ? <DialogError isShow={true}
                                           type={errorConstants.ERROR_COMPONENT}/>
                            : null
                    }
                    {
                        this.state.showDeleteDialog.isShowDeleteDialog
                            ? <DialogDelete isShow={true}
                                            cancel
                                            onHide={this.hideDialogDelete}
                                            clickYes={this.cancelInspection} />
                            : null
                    }
                </section>
        )
    }

    updateFilterSelect = (filterSelect) => {
        this.setState({
            filterSelect: filterSelect
        })
    }

    showDialogDelete = (inspectionId, type) => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = true
        newShowDeleteDialog.inspectionId = inspectionId
        newShowDeleteDialog.type = type
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    hideDialogDelete = () => {
        let newShowDeleteDialog = this.state.showDeleteDialog
        newShowDeleteDialog.isShowDeleteDialog = false
        this.setState({
            showDeleteDialog: newShowDeleteDialog
        })
    }

    addInspection() {
        history.push(screenConstants.INSPECTIONS)
        window.location.replace('/inspection/new')
    }

    cancelInspection = async () => {
        await this.props.onCancelInspection(this.state.showDeleteDialog.inspectionId, this.state.showDeleteDialog.type)
    }

    onRowClick = (id, type) => {
        history.push(screenConstants.INSPECTIONS)
        if (type === typeConstants.PRIMARY) {
            window.location.replace('/inspection/select_primary/' + id)
        } else if (type === typeConstants.SECONDARY) {
            window.location.replace('/inspection/select_secondary/' + id)
        }
    }

    onSelectChanged = (e, index) => {
        const newInspections = this.props.inspectionsState.inspections.slice()
        if (e.target.checked) {
            const isSelect = !newInspections[index].select
            newInspections[index].select = isSelect
            const countSelect = isSelect ? this.state.countSelect + 1 : this.state.countSelect - 1
            this.setState({
                countSelect: countSelect,
                inspections: newInspections
            })
        }

    }

    getLocale() {
        return this.props.appState.locale
    }

    onResetClick() {
        this.dateRangeRef.handleDateChange(this.state.defaultDateRange)
    }

    getInspections = () => {
        const type = this.state.filterSelect.type.id !== -1 ? typeConstants.getTypeForServer(this.state.filterSelect.type.id) : ''
        const branch = this.state.filterSelect.branch.id !== -1 ? this.state.filterSelect.branch.id : ''
        const state = this.state.filterSelect.status.id !== -1 ? statusConstants.getStatusForServer(this.state.filterSelect.status.id) : ''
        const inspector = this.state.filterSelect.inspector.id !== -1 ? this.state.filterSelect.inspector.id : ''

        this.props.onGetInspections(type, branch, state, inspector, this.state.search,
            moment(this.state.dateRange[0]).startOf('day').toISOString(true),
            moment(this.state.dateRange[1]).endOf('day').toISOString(true))
    }

    loadYetInspections = () => {
        const type = this.state.filterSelect.type.id !== -1 ? typeConstants.getTypeForServer(this.state.filterSelect.type.id) : ''
        const branch = this.state.filterSelect.branch.id !== -1 ? this.state.filterSelect.branch.id : ''
        const state = this.state.filterSelect.status.id !== -1 ? statusConstants.getStatusForServer(this.state.filterSelect.status.id) : ''
        const inspector = this.state.filterSelect.inspector.id !== -1 ? this.state.filterSelect.inspector.id : ''

        this.props.onLoadYetInspections(type, branch, state, inspector, this.state.search,
            moment(this.state.dateRange[0]).startOf('day').toISOString(true),
            moment(this.state.dateRange[1]).endOf('day').toISOString(true))
    }

    getCountSelectHeader() {
        if (this.state.countSelect === 0)
            return  <div className="row justify-content-start">
                        <div className="col-auto">
                            <span className="table-header-title">{this.getLocale().inspections.table_header.choose}</span>
                        </div>
                    </div>
        else
            return (
                <div className="row align-items-center no-gutters">
                    <div className="col-auto" style={{marginRight: '11px'}}>
                        <span className="popover-header-text-another" style={{paddingLeft: '5px'}}>{this.state.countSelect}</span>
                    </div>
                    <div onClick={this.unselectAllInspections} className="col-auto">
                        {IconsCommon.getIcnRemove()}
                    </div>
                </div>
            )
    }

    unselectAllInspections = () => {
        const newinspections = this.props.inspectionsState.inspections.slice()
        newinspections.forEach((item) => {
            item.select = false
        })
        const countSelect = 0
        this.setState({
            countSelect: countSelect,
            inspections: newinspections
        })
    }

    searchNameInspection = (e) => {
        const text = e.target.value
        this.setState({search: text})
    }

    updateData = () => {
        this.getInspections()
    }

    sortOnExecuted = () => {
        const sortArray = sortDate(this.state.inspections, this.state.isSortExecuted, sortTypeConstants.EXECUTED)
        this.setState({
            isSortExecuted: !this.state.isSortExecuted,
            inspections: sortArray
        })
    }

}

export default connect(
    state => ({
        inspectionsState: state.inspections,
        loggedUser: state.users.loggedUser,
        appState: state.app,
        branchesState: state.branches
    }),
    dispatch => ({
        onGetInspections: (filterType, filterBranch, filterState, filterInspector, search, executeSince, executeTill) => {
            dispatch(InspectionsActions.getInspections(commonConstants.INSPECTIONS_LIMIT_LOAD,
                filterType, filterBranch, filterState, filterInspector, search, executeSince, executeTill))
        },
        onLoadYetInspections: (filterType, filterBranch, filterState, filterInspector, search, executeSince, executeTill) => {
            dispatch(InspectionsActions.loadYetInspections(commonConstants.INSPECTIONS_LIMIT_LOAD,
                filterType, filterBranch, filterState, filterInspector, search, executeSince, executeTill))
        },
        onCancelInspection: async (id, type) => {
            await InspectionsActions.cancelInspection(dispatch, id, type)
        },
        onGetBranches: () => {
            dispatch(BranchesActions.getBranches())
        },
        onGetUsers: () => {
            dispatch(UsersActions.getUsers())
        }
    })
)(Inspections);