import {call, select} from "@redux-saga/core/effects";
import {getMeApi} from "../network/api/UsersApi";

export function* checkLoggedUser() {
    let loggedUser = yield select(state => state.users.loggedUser);
    if (!loggedUser) {
        const responseMe = yield call(getMeApi)
        loggedUser = responseMe.data
    }

    return loggedUser
}
