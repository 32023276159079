import * as actionConstants from "./ActionConstants";
import * as localeConstants from "../constants/LocaleConstants";
import {browserLocale} from "../locales/locale";
import localeRu from "../locales/ru";
import localeEn from "../locales/en";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";

const appActions = {

    locale(dispatch) {
        let locale = browserLocale()
        let lang
        let timeLocale
        let dateFnsLocale
        switch (locale) {
            case localeConstants.ruRU || localeConstants.ru:
                lang = localeRu
                timeLocale = localeConstants.ru
                dateFnsLocale = ru
                break;
            case localeConstants.enEN || localeConstants.en:
                lang = localeEn
                timeLocale = localeConstants.en
                dateFnsLocale = en
                break;
            case localeConstants.esES || localeConstants.es:
                lang = localeEn
                timeLocale = localeConstants.es
                dateFnsLocale = es
                break;
            default:
                lang = localeRu
                timeLocale = localeConstants.ru
                dateFnsLocale = ru
        }

        dispatch({
            type: actionConstants.CHECK_LOCAL, payload: {
                data: { locale: lang, timeLocale: timeLocale, dateFnsLocale: dateFnsLocale }
            }
        })
    }
}

export default appActions