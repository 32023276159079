export const LOGIN = '/login'

export const INSPECTIONS = '/inspections'
export const INSPECTION = '/inspection'
export const INSPECTIONS_PAGE = '/inspections/:page'
export const INSPECTION_NEW = '/inspection/new'
export const INSPECTION_SELECT_PRIMARY = '/inspection/select_primary/:id/'
export const INSPECTION_SELECT_SECONDARY = '/inspection/select_secondary/:id/'

export const TASKS = '/tasks'
export const TASKS_PAGE = '/tasks/:page'
export const TASK = '/task'
export const TASK_SELECT = '/task/select/:id'

export const STATISTICS = '/statistics'

export const USERS = '/users'
export const USERS_PAGE = '/users/:page'

export const CANCEL_REASONS = '/cancel_reasons'
export const CANCEL_REASONS_PAGE = '/cancel_reasons/:page'

export const SECTIONS = '/sections'
export const SECTIONS_PAGE = '/sections/:page'

export const BRANCHES = '/branches'
export const BRANCHES_PAGE = '/branches/:page'

export const ORGANIZATIONS = '/org'
export const ORGANIZATIONS_PAGE = '/org/:page'

export const TEMPLATES = '/templates'
export const TEMPLATES_PAGE = '/templates/:page'
export const TEMPLATE = '/template'
export const TEMPLATE_NEW = '/template/new'
export const TEMPLATE_EDIT = '/template/edit/:id'
export const TEMPLATE_SELECT = '/template/select/:id'