import './styles/index.scss'
import history from './components/history'

import React from 'react';
import ReactDOM from 'react-dom';
import {
    Router,
    Route,
    Switch
} from "react-router-dom";

import {Provider} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import reducer from './reducer'

import AuthService from './network/service/AuthService'

import * as screenConstants from './constants/ScreenConstants'

import Inspections from "./components/inspections/Inspections";
import App from "./components/App";
import Login from "./components/Login";
import {Redirect} from "react-router";
import InspectionCreate from "./components/inspections/InspectionCreate";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import InspectionSelect from "./components/inspections/InspectionSelect";
import Users from "./components/users/Users";
import CancelReasons from "./components/directory/CancelReasons";
import Sections from "./components/directory/Sections"
import Branches from "./components/directory/Branches";
import Organizations from "./components/directory/Organizations";
import Tasks from "./components/tasks/Tasks";
import TaskSelect from "./components/tasks/TaskSelect";
import Statistics from "./components/statistics/Statistics";
import Templates from "./components/templates/Templates"
import TemplateSelect from "./components/templates/TemplateSelect";
import TemplateCreate from "./components/templates/TemplateCreate";
import RecheckSelect from "./components/inspections/RecheckSelect";
import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from 'redux-saga'
import {rootSaga} from "./sagas";

const sagaMiddleware = createSagaMiddleware()
const store = createStore(reducer,
    composeWithDevTools(
        applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga)

const RequireAuth = ({ children }) => {
    if (!AuthService.loggedIn()) {
        return <Redirect to={screenConstants.LOGIN} />;
    }

    return children;
};

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <div className="content fill">
                <Route path="/" component={App} />
                <Route path="/login" component={Login}/>
                <RequireAuth>
                    <div className="row h-100 no-gutters general-container-row">
                        <Navigation />
                        <Route path={screenConstants.INSPECTIONS} component={InspectionsRouter}/>
                        <Route exact path={screenConstants.INSPECTION_NEW} component={InspectionCreate} />
                        <Route exact path={screenConstants.INSPECTION_SELECT_PRIMARY} component={InspectionSelect} />
                        <Route exact path={screenConstants.INSPECTION_SELECT_SECONDARY} component={RecheckSelect} />
                        <Route path={screenConstants.TASKS} component={TasksRouter}/>
                        <Route exact path={screenConstants.TASK_SELECT} component={TaskSelect} />
                        <Route exact path={screenConstants.STATISTICS} component={Statistics} />
                        <Route path={screenConstants.USERS} component={UsersRouter}/>
                        <Route path={screenConstants.CANCEL_REASONS} component={CancelReasonsRouter}/>
                        <Route path={screenConstants.SECTIONS} component={SectionsRouter}/>
                        <Route path={screenConstants.BRANCHES} component={BranchesRouter}/>
                        <Route path={screenConstants.ORGANIZATIONS} component={OrganizationsRouter}/>
                        <Route path={screenConstants.TEMPLATES} component={TemplatesRouter}/>
                        <Route exact path={screenConstants.TEMPLATE_NEW} component={TemplateCreate} />
                        <Route exact path={screenConstants.TEMPLATE_EDIT} component={TemplateCreate} />
                        <Route exact path={screenConstants.TEMPLATE_SELECT} component={TemplateSelect}/>
                    </div>
                    <Footer/>
                </RequireAuth>
            </div>
        </Router>
    </Provider>, document.getElementById('root')
);

function InspectionsRouter() {
    return (
        <Switch>
            <Route exact path={screenConstants.INSPECTIONS} component={Inspections}/>
            <Route path={screenConstants.INSPECTIONS_PAGE} component={Inspections}/>
        </Switch>
    );
}

function TasksRouter() {
    return (
        <Switch>
            <Route exact path={screenConstants.TASKS} component={Tasks}/>
            <Route path={screenConstants.TASKS_PAGE} component={Tasks}/>
        </Switch>
    );
}

function UsersRouter() {
    return (
        <Switch>
            <Route exact path={screenConstants.USERS} component={Users}/>
            <Route path={screenConstants.USERS_PAGE} component={Users}/>
        </Switch>
    )
}

function CancelReasonsRouter() {
    return (
        <Switch>
            <Route exact path={screenConstants.CANCEL_REASONS} component={CancelReasons}/>
            <Route path={screenConstants.CANCEL_REASONS_PAGE} component={CancelReasons}/>
        </Switch>
    )
}

function SectionsRouter() {
    return (
        <Switch>
            <Route exact path={screenConstants.SECTIONS} component={Sections}/>
            <Route path={screenConstants.SECTIONS_PAGE} component={Sections}/>
        </Switch>
    )
}

function BranchesRouter() {
    return (
        <Switch>
            <Route exact path={screenConstants.BRANCHES} component={Branches}/>
            <Route path={screenConstants.BRANCHES_PAGE} component={Branches}/>
        </Switch>
    )
}

function OrganizationsRouter() {
    return (
        <Switch>
            <Route exact path={screenConstants.ORGANIZATIONS} component={Organizations}/>
            <Route path={screenConstants.ORGANIZATIONS_PAGE} component={Organizations}/>
        </Switch>
    )
}

function TemplatesRouter() {
    return (
        <Switch>
            <Route exact path={screenConstants.TEMPLATES} component={Templates}/>
            <Route path={screenConstants.TEMPLATES_PAGE} component={Templates}/>
        </Switch>
    );
}
