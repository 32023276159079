import React from "react";

import {connect} from "react-redux";

import InputMask from "react-input-mask";

import IconsLogin from "../assets/icons/IconsLogin"
import AuthActions from "../actions/AuthActions";
import DialogError from "./commons/dialog/DialogError";

import * as errorConstants from "../constants/ErrorConstants";
import * as appInfoConstants from "../constants/AppInfoConstants";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            password: '',
            code: '',
            isNameError: false,
            isPasswordError: false,
            isCodeError: false
        }
    }

    componentDidMount() {
        document.title = this.getLocale().login.title + ' · ' + this.getLocale().title
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.authState.token) {
            window.location.replace('/')
        }
    }

    render() {
        return (
            <div className="row h-100 no-gutters login">
                <div className="col-7 login-image-block">
                    <div className="row h-100 no-gutters align-items-center justify-content-center">
                        <div className="col-auto">
                            {IconsLogin.getIcnLogin()}
                        </div>
                    </div>
                </div>
                <div className="col-5">
                    <div className="row h-100 no-gutters align-items-center justify-content-center">
                        {
                            this.props.authState.sign
                                ? <form
                                    className={'col-auto login-form'}
                                    onSubmit={this.sign}>
                                    <div className="row justify-content-center">
                                        <span className="title-login">{this.getLocale().title}</span>
                                    </div>
                                    <fieldset>
                                        <InputMask
                                            placeholder={this.getLocale().login.code}
                                            mask="9999"
                                            maskChar=""
                                            style={{marginBottom: '19px'}}
                                            className={ 'form-control ' + (this.state.isCodeError ? 'input-login-error' : 'input-login') }
                                            value={this.state.code}
                                            onChange={(e) => {
                                                let code = ''
                                                if (e.target.value)
                                                    code = e.target.value
                                                this.setState({
                                                    code: e.target.value,
                                                    isCodeError: code.length < 4
                                                })}
                                            }
                                        />
                                        <button
                                            className="btn btn-lg btn-primary btn-block"
                                            type="submit">
                                            {this.getLocale().login.sign_in}
                                        </button>
                                    </fieldset>
                                    <div className="row no-gutters justify-content-center">
                                        <div className="col-auto" style={{marginTop: '10px'}}>
                                            <span className="label-sent-code">{this.getLocale().login.sent_code}</span>
                                        </div>
                                    </div>
                                    <div className="row news-block">
                                        <div className="col text-muted">
                                            <span>{this.getLocale().login.version} {appInfoConstants.APP_VERSION}</span>
                                        </div>
                                        {process.env.REACT_APP_HIDE_NERVE != '1' && (
                                            <div className="col text-muted">
                                                <div className="row justify-content-end no-gutters">
                                                    <span>{this.getLocale().login.make_in} <a href={'https://nerve.ai/'}>Nerve.AI</a></span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                                : <form
                                    className={'col-auto login-form'}
                                    onSubmit={this.savePhoneAndPassword}>
                                    <div className="row justify-content-center">
                                        <span className="title-login">{this.getLocale().title}</span>
                                    </div>
                                    <fieldset>
                                        <InputMask
                                            placeholder=""
                                            mask="+9 999 999 99 99"
                                            maskChar=" "
                                            style={{marginBottom: '19px'}}
                                            className={ 'form-control ' + (this.state.isPhoneError ? 'input-login-error' : 'input-login') }
                                            value={this.state.phone}
                                            onChange={(e) => {
                                                let phone = ''
                                                if (e.target.value)
                                                    phone = (e.target.value.match(/[\d]/g) || []).join("")
                                                this.setState({
                                                    phone: phone,
                                                    isPhoneError: phone.length < 11
                                                })}
                                            }
                                        />
                                        {/*<input
                                    ref={this.passRef}
                                    type="password"
                                    className={ 'form-control ' + (this.state.isPasswordError ? 'input-login-error' : 'input-login') }
                                    onChange={(e) => {this.setState({isPasswordError: !e.target.value})}}
                                    style={{marginBottom: '29px'}}
                                    placeholder={this.getLocale().login.password}
                                    maxLength={100}
                                />*/}
                                        <button
                                            className="btn btn-lg btn-primary btn-block"
                                            type="submit">
                                            {this.getLocale().login.continue}
                                        </button>
                                    </fieldset>
                                    {process.env.REACT_APP_HIDE_NERVE != '1' && (
                                        <div className="row news-block">
                                            <div className="col text-muted">
                                                <span>{this.getLocale().login.version} {appInfoConstants.APP_VERSION}</span>
                                            </div>
                                            <div className="col text-muted">
                                                <div className="row justify-content-end no-gutters">
                                                    <span>{this.getLocale().login.make_in} <a href={appInfoConstants.COMPANY_URL}>{appInfoConstants.COMPANY_NAME}</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </form>
                        }
                    </div>
                </div>
                {
                    this.props.authState.error
                        ? <DialogError isShow={true}
                                       type={errorConstants.ERROR_COMPONENT}
                                       body={this.getErrorBody(this.props.authState.error.id)} />
                        : null
                }
            </div>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    getErrorBody(errorId) {
        switch (errorId) {
            case errorConstants.NOT_FOUND_PHONE:
                return this.getLocale().errors.notFoundPhone
            case errorConstants.WRONG_CODE:
                return this.getLocale().errors.wrongCode
            default: return null
        }
    }

    checkPhoneAndPasswordField() {
        if (!this.state.phone)
            this.setState({
                isPhoneError: true
            })

        /*if (!this.passRef.current.value)
            this.setState({
                isPasswordError: true
            })*/
    }

    checkCodeField() {
        if (!this.state.code)
            this.setState({
                isCodeError: true
            })
    }

    savePhoneAndPassword = async (event) => {
        event.preventDefault()

        this.checkPhoneAndPasswordField()

        let phone = this.state.phone
        let clearPhone = phone.replace(/\s+/g, '').trim()
        this.setState({
            phone: clearPhone
        })

        if (!this.state.isPhoneError //&& !this.state.isPasswordError
            && clearPhone /*&& pass*/)
            await this.props.onAuthRequestCode('+' + clearPhone)
    }

    sign = async (event) => {
        event.preventDefault()

        this.checkCodeField()

        let code = this.state.code

        if (this.state.phone && code)
            await this.props.onAuthCreateToken('+' + this.state.phone, this.props.authState.sign, code).then()
    }
}

export default connect(
    state => ({
        authState: state.login,
        appState: state.app
    }),
    dispatch => ({
        onAuthRequestCode: async (phone) => {
            await AuthActions.authRequestCode(dispatch, phone)
        },
        onAuthCreateToken: async (phone, sign, code) => {
            await AuthActions.authCreateToken(dispatch, phone, sign, code)
        }
    })
)(Login);