import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import * as actionConstants from "../actions/ActionConstants";
import AuthService from "../network/service/AuthService";
import {
    createUserApi,
    createUserForSuperUserApi, deleteUserApi, deleteUserForSuperUserApi,
    getMeApi,
    getUserApi,
    getUserForSuperUserApi,
    getUsersApi,
    getUsersForSuperUserApi,
    updateAvatar,
    updateAvatarForSuperUserApi,
    updateUserApi,
    updateUserForSuperUserApi
} from "../network/api/UsersApi";
import {
    getBranchApi,
    getBranchesApi,
    getBranchesForSuperUserApi,
    getBranchForSuperUserApi
} from "../network/api/BranchesApi";
import * as roleConstants from "../constants/RoleConstants";
import {getMyOrganizationApi, getOrganizationApi, getOrganizationsApi} from "../network/api/OrganizationsApi";
import UsersActions from "../actions/UsersActions";
import {getUploadsApi, getUploadsForSuperUserApi} from "../network/api/UploadsApi";
import usersActions from "../actions/UsersActions";
import uploadsActions from "../actions/UploadsActions";
import {checkLoggedUser} from "./helper";

function* getMe() {
    try {
        const responseMe = yield call(getMeApi)
        const me = responseMe.data

        let branch = null
        if (me.branch_id) {
            const responseBranch = yield call(getBranchApi, me.branch_id)
            branch = responseBranch.data
        }

        let organization = null
        if (me.role === roleConstants.SUPER_USER) {
            if (me.entity_id) {
                const organizationResponse = yield call(getOrganizationApi, me.entity_id)
                organization = organizationResponse.data
            }
        } else {
            const organizationResponse = yield call(getMyOrganizationApi)
            organization = organizationResponse.data
        }

        const displayNameSplit = me.display_name.split(' ')
        const name = displayNameSplit[0]
        let family = ''
        if (displayNameSplit.length > 1) {
            family = displayNameSplit[1]
        }

        const result = {
            id: me.id,
            avatar: me.avatar,
            branchId: me.branch_id,
            branch: branch,
            createdAt: new Date(me.created_at),
            name: name,
            family: family,
            organizationId: me.entity_id,
            organization: organization,
            isDeleted: me.is_deleted,
            phone: me.phone,
            role: me.role,
            state: me.state,
            updatedAt: new Date(me.updated_at)
        }

        yield put({
            type: actionConstants.SUCCESS_LOAD_ME, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_ME, payload: {
                error: error
            }
        })
    }
}

function* getLoggedUser() {
    const usersState = yield select(state => state.users)
    if (!usersState.loggedUser && AuthService.loggedIn()) {
        yield put(UsersActions.getMe())
    }
}

function* getUsers() {
    try {
        const loggedUser = yield call(checkLoggedUser)

        let result
        if (loggedUser.role === roleConstants.SUPER_USER) {
            result = yield call(getUsersForSuperUser)
        } else {
            result = yield call(getUsersForJustUser)
        }

        yield put({
            type: actionConstants.SUCCESS_LOAD_USERS, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_USERS, payload: {
                error: error
            }
        })
    }
}

function* getUsersForSuperUser() {
    const responseUsers = yield call(getUsersForSuperUserApi)
    const users = responseUsers.data

    const responseUploads = yield call(getUploadsForSuperUserApi)
    const uploads = responseUploads.data

    const responseBranches = yield call(getBranchesForSuperUserApi)
    const branches = responseBranches.data

    const organizationsResponse = yield call(getOrganizationsApi)
    let organizations = organizationsResponse.data

    return users.map(item => {
        const upload = uploads.find(upload => upload.subject_id === item.id)
        return {
            id: item.id,
            branchId: item.branch_id,
            branch: branches.find(branch => branch.id === item.branch_id),
            createdAt: new Date(item.created_at),
            displayName: item.display_name,
            organizationId: item.entity_id,
            organization: organizations.find(organization => organization.id === item.entity_id),
            isDeleted: item.is_deleted,
            phone: item.phone,
            role: item.role,
            state: item.state,
            uploadId: upload?.id,
            upload: upload ? process.env.REACT_APP_HOST_URL + upload.url : '',
            updatedAt: new Date(item.updated_at),
        }})
}

function* getUsersForJustUser() {
    const responseUsers = yield call(getUsersApi)
    const users = responseUsers.data

    const responseUploads = yield call(getUploadsApi)
    const uploads = responseUploads.data

    const responseBranches = yield call(getBranchesApi)
    const branches = responseBranches.data

    const organizationResponse = yield call(getMyOrganizationApi)
    const organization = organizationResponse.data

    return users.map(item => {
        const upload = uploads.find(upload => upload.subject_id === item.id)
        return {
            id: item.id,
            branchId: item.branch_id,
            branch: branches.find(branch => branch.id === item.branch_id),
            createdAt: new Date(item.created_at),
            displayName: item.display_name,
            organizationId: item.entity_id,
            organization: organization,
            isDeleted: item.is_deleted,
            phone: item.phone,
            role: item.role,
            state: item.state,
            uploadId: upload?.id,
            upload: upload ? process.env.REACT_APP_HOST_URL + upload.url : '',
            updatedAt: new Date(item.updated_at),
        }
    })
}

function* getUser(action) {
    const { id } = action.payload
    try {
        const loggedUser = yield call(checkLoggedUser)

        let result
        if (loggedUser.role === roleConstants.SUPER_USER) {
            result = yield call(getUserForSuperUser, id)
        } else {
            result = yield call(getUserForJustUser, id)
        }

        yield put({
            type: actionConstants.SUCCESS_LOAD_USER, payload: {
                data: result
            }
        })
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_LOAD_USER, payload: {
                error: error
            }
        })
    }
}

function* getUserForSuperUser(id) {
    const responseUser = yield call(getUserForSuperUserApi, id)
    const user = responseUser.data

    const responseBranch = yield call(getBranchForSuperUserApi, user.branch_id)
    const branch = responseBranch.data

    const organizationResponse = yield call(getOrganizationApi, user.entity_id)
    const organization = organizationResponse.data

    const displayNameSplit = user.displayName.split(' ')
    const name = displayNameSplit[0]
    let family = ''
    if (displayNameSplit.length > 1) {
        family = displayNameSplit[1]
    }

    return {
        id: user.id,
        branchId: user.branch_id,
        branch: branch,
        createdAt: new Date(user.created_at),
        name: name,
        family: family,
        organizationId: user.entity_id,
        organization: organization,
        isDeleted: user.is_deleted,
        phone: user.phone,
        role: user.role,
        state: user.state,
        updatedAt: new Date(user.updated_at)
    }
}

function* getUserForJustUser(id) {
    const responseUser = yield call(getUserApi, id)
    const user = responseUser.data

    let branch = null
    if (user.branch_id) {
        const responseBranch = yield call(getBranchApi, user.branch_id)
        branch = responseBranch.data
    }

    const organizationResponse = yield call(getMyOrganizationApi)
    let organization = organizationResponse.data

    const displayNameSplit = user.displayName.split(' ')
    const name = displayNameSplit[0]
    let family = ''
    if (displayNameSplit.length > 1) {
        family = displayNameSplit[1]
    }

    return {
        id: user.id,
        branchId: user.branch_id,
        branch: branch,
        createdAt: new Date(user.created_at),
        name: name,
        family: family,
        organizationId: user.entity_id,
        organization: organization,
        isDeleted: user.is_deleted,
        phone: user.phone,
        role: user.role,
        state: user.state,
        updatedAt: new Date(user.updated_at)
    }
}

function* createUser(action) {
    const { entityId, branchId, displayName, phone, role, uploadId } = action.payload
    try {
        const loggedUser = yield call(checkLoggedUser)

        let result
        if (loggedUser.role === roleConstants.SUPER_USER) {
            result = yield call(createUserForSuperUser, entityId, branchId, displayName, phone, role, uploadId)
        } else {
            result = yield call(createUserForJustUser, branchId, displayName, phone, role, uploadId)
        }

        yield put({
            type: actionConstants.SUCCESS_CREATE_USER, payload: {
                data: result.data.success
            }
        })

        yield put(usersActions.getUsers())
        yield put(uploadsActions.getUploads())
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_CREATE_USER, payload: {
                error: error
            }
        })
    }
}

function* createUserForSuperUser(entityId, branchId, displayName, phone, role, uploadId) {
    const response = yield call(createUserForSuperUserApi, entityId, branchId, displayName, phone, role)
    const userCreated = response.data.model

    if (uploadId)
        yield call(updateAvatarForSuperUserApi, userCreated.id, uploadId)

    return response
}

function* createUserForJustUser(branchId, displayName, phone, role, uploadId) {
    const response = yield call(createUserApi, branchId, displayName, phone, role)

    const userCreated = response.data.model

    if (uploadId)
        yield call(updateAvatar, userCreated.id, uploadId)

   return response
}

function* updateUser(action) {
    const { user, entityId, branchId, displayName, phone, role, uploadId } = action.payload
    try {
        const loggedUser = yield call(checkLoggedUser)

        let result
        if (loggedUser.role === roleConstants.SUPER_USER) {
            result = yield call(updateUserForSuperUser, user, entityId, branchId, displayName, phone, role, uploadId)
        } else {
            result = yield call(updateUserForJustUser, user, branchId, displayName, phone, role, uploadId)
        }

        yield put({
            type: actionConstants.SUCCESS_UPDATE_USER, payload: {
                data: result.data.success
            }
        })

        yield put(usersActions.getUsers())
        yield put(uploadsActions.getUploads())
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_UPDATE_USER, payload: {
                error: error
            }
        })
    }
}

function* updateUserForSuperUser(user, entityId, branchId, displayName, phone, role, uploadId) {
    const response = yield call(updateUserForSuperUserApi, user, entityId, branchId, displayName, phone, role)

    if (uploadId && user.uploadId !== uploadId) {
        yield call(updateAvatarForSuperUserApi, user.id, uploadId)
    }

    return response
}

function* updateUserForJustUser(user, branchId, displayName, phone, role, uploadId) {
    const response = yield call(updateUserApi, user, branchId, displayName, phone, role)

    if (uploadId && user.uploadId !== uploadId)
        yield call(updateAvatar, user.id, uploadId)

    return response
}

function* deleteUser(action) {
    const { id } = action.payload
    try {
        const loggedUser = yield call(checkLoggedUser)

        let result
        if (loggedUser.role === roleConstants.SUPER_USER) {
            result = yield call(deleteUserForSuperUserApi, id)
        } else {
            result = yield call(deleteUserApi, id)
        }

        yield put({
            type: actionConstants.SUCCESS_DELETE_USER, payload: {
                data: result.data.success
            }
        })

        yield put(usersActions.getUsers())
    } catch(error) {
        yield put({
            type: actionConstants.ERROR_DELETE_USER, payload: {
                error: error
            }
        })
    }
}

export default function* usersSaga() {
    yield takeEvery(actionConstants.GET_LOGGED_USER, getLoggedUser)
    yield takeEvery(actionConstants.START_LOAD_ME, getMe)
    yield takeEvery(actionConstants.START_LOAD_USERS, getUsers)
    yield takeEvery(actionConstants.START_LOAD_USER, getUser)
    yield takeEvery(actionConstants.START_CREATE_USER, createUser)
    yield takeEvery(actionConstants.START_UPDATE_USER, updateUser)
    yield takeEvery(actionConstants.START_DELETE_USER, deleteUser)
}