import React from "react";

import Tippy from "@tippyjs/react";
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/dist/tippy.css';

import IconsCommon from "../../../assets/icons/IconsCommon";
import {ListGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as roleConstants from "../../../constants/RoleConstants";

class TableDropDown extends React.Component {
    constructor(props) {
        super(props);

        this.typesNameFilter = {
            type: this.getLocale().inspections.table_header.type,
            role: this.getLocale().users.table_header.role,
            inspector: this.getLocale().inspections.table_header.inspector,
            mode: this.getLocale().inspections.table_header.mode,
            organisation: this.getLocale().inspections.table_header.organisation,
            branch: this.getLocale().inspections.table_header.branch,
            status: this.getLocale().inspections.table_header.status,
            executor: this.getLocale().tasks.table_header.executor
        }

        this.state = {
            isPopoverOpen: {
                type: false,
                role: false,
                inspector: false,
                mode: false,
                organisation: false,
                branch: false,
                status: false,
                executor: false
            },

            isArrowFilter: {
                type: false,
                role: false,
                inspector: false,
                mode: false,
                organisation: false,
                branch: false,
                status: false,
                executor: false
            },

            filterSelect: this.props.filterSelect
        }
    }

    render() {
        return this.getTableTypeHeader(this.props.type, this.props.customStyle)
    }

    getLocale() {
        return this.props.appState.locale
    }

    getTableTypeHeader(typesFilter, style) {
        return (<th scope="col" style={style}>
            <div className="row no-gutters">
                <div className="col-auto" style={{margin: '0px', padding: '0px'}}>
                    <span className="table-header-title">{this.typesNameFilter[typesFilter]}</span>
                </div>
                {
                    !this.getFilterSelect(typesFilter)
                        ? this.getTableTypeHeaderPopover(typesFilter, this)
                        : null
                }
            </div>
            <div className="row no-gutters">
                {
                    this.getFilterSelect(typesFilter)
                        ? this.getTableTypeHeaderPopover(typesFilter, this)
                        : null
                }
            </div>
        </th>)
    }

    getTableTypeHeaderPopover(typesFilter) {
        return <Tippy
            arrow={IconsCommon.getIcnPopoverArrow(this.getArrowFilter(typesFilter))}
            content={
                <div
                    className="popover-container"
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                    <ListGroup>
                        {
                            this.getPopoverList(typesFilter).map((value, index) => {
                                if (index === 0)
                                    return (<ListGroup.Item
                                        key={value.id}
                                        className="list-item-custom"
                                        onMouseEnter={() => {this.setArrowFilter(!this.getArrowFilter(typesFilter) ? true : this.getArrowFilter(typesFilter) , typesFilter)}}
                                        onMouseLeave={() => {this.setArrowFilter(this.getArrowFilter(typesFilter)  ? false : this.getArrowFilter(typesFilter) , typesFilter)}}
                                        onClick={() => {this.setFilterSelect(value, typesFilter)}}>
                                        {value.displayName}
                                    </ListGroup.Item>)
                                else
                                    return (
                                        <ListGroup.Item
                                            key={value.id}
                                            className="list-item-custom"
                                            onClick={() => {this.setFilterSelect(value, typesFilter)}}>
                                            {value.displayName}
                                        </ListGroup.Item>
                                    )

                            })
                        }
                    </ListGroup>
                </div>
            }
            interactive={true}
            visible={this.getPopoverOpen(typesFilter)}
            placement={'bottom-start'}
            onHide={() => this.setArrowFilter(false, typesFilter)}
            onClickOutside={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter) }>
            {
                !this.getFilterSelect(typesFilter)
                    ? this.getTableTypeHeaderAll(typesFilter, this)
                    : this.getTableTypeHeaderAnother(typesFilter, this)

            }
        </Tippy>
    }

    getTableTypeHeaderAll(typesFilter) {
        return <div className="col-auto" style={{padding: '0px', cursor: 'pointer'}}
                    onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
            <div className="row no-gutters">
                <div className="col-auto" style={{margin: '0px', padding: '0px'}}>
                    <span className="popover-header-text-all">{this.getLocale().common.all}</span>
                </div>
                <div className="col-auto">
                    <div className="row h-100 justify-content-center align-items-end no-gutters">
                        <div className="col-auto" style={{marginTop: '2px', marginLeft: '3px'}} >
                            {IconsCommon.getIcnHeaderArrow()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    getTableTypeHeaderAnother(typesFilter) {
        return <div className="col-auto" style={{padding: '0px'}}>
            <div className="row no-gutters">
                <div className="col-auto" style={{cursor: 'pointer'}} onClick={() => this.setPopoverOpen(!this.getPopoverOpen(typesFilter), typesFilter)}>
                    <span className="popover-header-text-another">{this.getFilterSelect(typesFilter)}</span>
                </div>
                <div className="col-auto" style={{marginLeft: '8px'}} onClick={() => {this.clearFilter(typesFilter)}}>
                    {IconsCommon.getIcnRemove()}
                </div>
            </div>
        </div>
    }

    getPopoverOpen(typesFilter) {
        switch (typesFilter) {
            case TableDropDown.typesFilter.role:
                return this.state.isPopoverOpen.role
            case TableDropDown.typesFilter.type:
                return this.state.isPopoverOpen.type
            case TableDropDown.typesFilter.inspector:
                return this.state.isPopoverOpen.inspector
            case TableDropDown.typesFilter.organisation:
                return this.state.isPopoverOpen.organisation
            case TableDropDown.typesFilter.branch:
                return this.state.isPopoverOpen.branch
            case TableDropDown.typesFilter.status:
                return this.state.isPopoverOpen.status
            case TableDropDown.typesFilter.executor:
                return this.state.isPopoverOpen.executor
            default: return null
        }
    }

    setPopoverOpen(isOpen, typesFilter) {
        let popoverState = this.state.isPopoverOpen
        switch (typesFilter) {
            case TableDropDown.typesFilter.role:
                popoverState.role = isOpen
                break
            case TableDropDown.typesFilter.type:
                popoverState.type = isOpen
                break
            case TableDropDown.typesFilter.inspector:
                popoverState.inspector = isOpen
                break
            case TableDropDown.typesFilter.organisation:
                popoverState.organisation = isOpen
                break
            case TableDropDown.typesFilter.branch:
                popoverState.branch = isOpen
                break
            case TableDropDown.typesFilter.status:
                popoverState.status = isOpen
                break
            case TableDropDown.typesFilter.executor:
                popoverState.executor = isOpen
                break
            default: break
        }

        this.setState({isPopoverOpen: popoverState})

    }

    getPopoverList(typesFilter) {
        switch (typesFilter) {
            case TableDropDown.typesFilter.role:
                return this.getLocale().roles
            case TableDropDown.typesFilter.type:
                return this.getLocale().types
            case TableDropDown.typesFilter.inspector:
                return this.props.usersState.users.filter(user => user.role === roleConstants.INSPECTOR)
            case TableDropDown.typesFilter.organisation:
                return this.props.organizationsState?.organizations
            case TableDropDown.typesFilter.branch:
                return this.props.branchesState?.branches
            case TableDropDown.typesFilter.status:
                return this.getLocale().statuses
            case TableDropDown.typesFilter.executor:
                return this.props.usersState.users.filter(user => user.role === roleConstants.TECH)
            default: return null
        }
    }

    getArrowFilter(typesFilter) {
        switch (typesFilter) {
            case TableDropDown.typesFilter.role:
                return this.state.isArrowFilter.role
            case TableDropDown.typesFilter.type:
                return this.state.isArrowFilter.type
            case TableDropDown.typesFilter.inspector:
                return this.state.isArrowFilter.inspector
            case TableDropDown.typesFilter.organisation:
                return this.state.isArrowFilter.organisation
            case TableDropDown.typesFilter.branch:
                return this.state.isArrowFilter.branch
            case TableDropDown.typesFilter.status:
                return this.state.isArrowFilter.status
            case TableDropDown.typesFilter.executor:
                return this.state.isArrowFilter.executor
            default: return null
        }
    }

    setArrowFilter(filter, typesFilter) {
        let arrowFilterState = this.state.isArrowFilter
        switch (typesFilter) {
            case TableDropDown.typesFilter.role:
                arrowFilterState.role = filter
                break
            case TableDropDown.typesFilter.type:
                arrowFilterState.type = filter
                break
            case TableDropDown.typesFilter.inspector:
                arrowFilterState.inspector = filter
                break
            case TableDropDown.typesFilter.organisation:
                arrowFilterState.organisation = filter
                break
            case TableDropDown.typesFilter.branch:
                arrowFilterState.branch = filter
                break
            case TableDropDown.typesFilter.status:
                arrowFilterState.status = filter
                break
            case TableDropDown.typesFilter.executor:
                arrowFilterState.executor = filter
                break
            default: break
        }

        this.setState({isArrowFilter: arrowFilterState})

    }

    getFilterSelect(typesFilter) {
        switch (typesFilter) {
            case TableDropDown.typesFilter.role:
                return this.state.filterSelect.role.displayName
            case TableDropDown.typesFilter.type:
                return this.state.filterSelect.type.displayName
            case TableDropDown.typesFilter.inspector:
                return this.state.filterSelect.inspector.displayName
            case TableDropDown.typesFilter.organisation:
                return this.state.filterSelect.organisation.displayName
            case TableDropDown.typesFilter.branch:
                return this.state.filterSelect.branch.displayName
            case TableDropDown.typesFilter.status:
                return this.state.filterSelect.status.displayName
            case TableDropDown.typesFilter.executor:
                return this.state.filterSelect.executor.displayName
            default: return null
        }
    }

    setFilterSelect(filterSelect, typesFilter) {
        let filterSelectState = this.state.filterSelect
        switch (typesFilter) {
            case TableDropDown.typesFilter.role:
                filterSelectState.role = filterSelect
                break
            case TableDropDown.typesFilter.type:
                filterSelectState.type = filterSelect
                break
            case TableDropDown.typesFilter.inspector:
                filterSelectState.inspector = filterSelect
                break
            case TableDropDown.typesFilter.organisation:
                filterSelectState.organisation = filterSelect
                break
            case TableDropDown.typesFilter.branch:
                filterSelectState.branch = filterSelect
                break
            case TableDropDown.typesFilter.status:
                filterSelectState.status = filterSelect
                break
            case TableDropDown.typesFilter.executor:
                filterSelectState.executor = filterSelect
                break
            default: break
        }

        this.setState({filterSelect: filterSelectState})

        this.props.updateData()
    }

    clearFilter(typesFilter) {
        this.setFilterSelect(TableDropDown.emptyObject, typesFilter)
    }
}

TableDropDown.typesFilter = {
    type: 'type',
    role: 'role',
    inspector: 'inspector',
    mode: 'mode',
    organisation: 'organisation',
    branch: 'branch',
    status: 'status',
    executor: 'executor'
}

TableDropDown.propTypes = {
    type: PropTypes.string.isRequired,
    customStyle: PropTypes.object,
    filterSelect: PropTypes.object.isRequired,
    updateFilterSelect: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired
}

TableDropDown.emptyObject = {id: -1, displayName: ''}


export default connect(
    state => ({
        appState: state.app,
        branchesState: state.branches,
        organizationsState: state.organizations,
        usersState: state.users
    })
)(TableDropDown);
