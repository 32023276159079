import * as actionConstants from '../actions/ActionConstants'

const initialState = {
    branches: [],
    branch: null,
    isCreateBranch: false,
    isDeleteBranch: false,
    process: null,
    error: null
};

export default function branches(state = initialState, action) {
    switch (action.type) {
        case actionConstants.START_LOAD_BRANCHES:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_BRANCHES:
            return {
                ...state,
                branches: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_LOAD_BRANCHES:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_LOAD_BRANCH:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_LOAD_BRANCH:
            return {
                ...state,
                branch: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_LOAD_BRANCH:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_CREATE_BRANCH:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_CREATE_BRANCH:
            return {
                ...state,
                isCreateBranch: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_CREATE_BRANCH:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.START_DELETE_BRANCH:
            return {
                ...state,
                process: true
            }
        case actionConstants.SUCCESS_DELETE_BRANCH:
            return {
                ...state,
                isDeleteBranch: action.payload.data,
                process: false
            };
        case actionConstants.ERROR_DELETE_BRANCH:
            return {
                ...state,
                error: action.payload.error,
                process: false
            }

        case actionConstants.CLEAR_ERROR_COMPONENT:
            return {
                ...state,
                error: null
            }

        default: return {...state}
    }
}