import * as actionConstants from "../../actions/ActionConstants";
import * as dirItemsConstants from "../../constants/DirItemsConstants";
import {getMyOrganizationApi} from "../api/OrganizationsApi";
import client from "../client";
import {deleteDirItemApi, getDirItemApi, getDirItemsWithKindApi} from "../api/DirItemsApi";

const sectionsService = {

    async getSections(dispatch) {
        dispatch({type: actionConstants.START_LOAD_SECTIONS, payload: {}})
        try {
            const responseDirItemsApi = await getDirItemsWithKindApi(dirItemsConstants.SECTION)
            const sections = responseDirItemsApi.data

            const organizationResponse = await getMyOrganizationApi()
            const organization = organizationResponse.data

            const result = sections.map(item => { return {
                id: item.id,
                createdAt: new Date(item.created_at),
                displayName: item.display_name,
                organizationId: item.entity_id,
                organization: organization,
                isDeleted: item.is_deleted,
                kind: item.kind,
                updatedAt: new Date(item.updated_at),
                value: item.value
            }})

            dispatch({
                type: actionConstants.SUCCESS_LOAD_SECTIONS, payload: {
                    data: result
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_SECTIONS, payload: {
                    error: error?.response
                }
            })
        }
    },

    async getSection(dispatch, id) {
        dispatch({type: actionConstants.START_LOAD_SECTION, payload: {}})
        try {
            const responseDirItem = await getDirItemApi(id)
            const section = responseDirItem.data

            const organizationResponse = await getMyOrganizationApi()
            const organization = organizationResponse.data

            const result = {
                id: section.id,
                createdAt: new Date(section.created_at),
                displayName: section.display_name,
                organizationId: section.entity_id,
                organization: organization,
                isDeleted: section.is_deleted,
                kind: section.kind,
                updatedAt: new Date(section.updated_at),
                value: section.value
            }

            dispatch({
                type: actionConstants.SUCCESS_LOAD_SECTION, payload: {
                    data: result
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_SECTION, payload: {
                    error: error?.response
                }
            })
        }
    },

    async createSection(dispatch, name) {
        dispatch({type: actionConstants.START_CREATE_SECTION, payload: {}})
        try {
            const response = await client.post(
                '/api/dir_items',
                {
                    kind: dirItemsConstants.SECTION,
                    value: name,
                    display_name: name,
                })

            dispatch({
                type: actionConstants.SUCCESS_CREATE_SECTION, payload: {
                    data: response.data.success
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_CREATE_SECTION, payload: {
                    error: error?.response
                }
            })
        }
    },

    async deleteSection(dispatch, id) {
        dispatch({type: actionConstants.START_DELETE_SECTION, payload: {}})
        try {
            const response = await deleteDirItemApi(id)

            dispatch({
                type: actionConstants.SUCCESS_DELETE_SECTION, payload: {
                    data: response.data.success
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_DELETE_SECTION, payload: {
                    error: error?.response
                }
            })
        }
    }
}

export default sectionsService;
