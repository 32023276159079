export default {
    title: "X-Ray",
    inspections: {
        title: "Проверки",
        create_inspection: "Создать проверку",
        download_inspection: "Скачать проверки",
        table_header: {
            choose: 'Выбор',
            name: 'Название',
            type: 'Тип',
            created: 'Создана',
            executed: 'Назначена',
            mode: 'Режим',
            organisation: 'Организация',
            branch: 'Филиал',
            status: 'Статус',
            history_status: 'История статусов',
            inspector: 'Инженер/электромонтер',
            phone: 'Телефон'
        },
        period: {
            on_hours: "по часам",
            on_days: "по дням",
            on_weeks: "по неделям"
        },
        filter: {
            up: "Вперед",
            back: "Назад"
        }
    },

    tasks: {
        title: "Задачи",
        download_tasks: "Скачать задачи",
        table_header: {
            choose: 'Выбор',
            name: 'Название',
            mode: 'Режим',
            organisation: 'Организация',
            branch: 'Филиал',
            status: 'Статус',
            history_status: 'История статусов',
            executor: 'Исполнитель',
            phone: 'Телефон',
            created: 'Создана',
            finished: 'Завершена'
        },
        type_date: "Тип даты"
    },

    task: {
        title: "Задача"
    },

    statistics: {
        title: "Статистика",
        date: "Дата",
        delta: "Изменение",
        inspections: "Проверок",
        status_inspections: "Статус проверок",
        mode: "Режим",
        end: "завершенные",
        canceled: "Отмененные",
        completed: "Выполненные",
        completed_1: "Выполнено",
        canceled_1: "Отменено",
        during_week: "за неделю",
        during_month: "за месяц",
        for_three_month: "за 3 месяца",
        for_half_year: "за пол года",
        for_year: "за год",
        by_days: "по дням",
        by_weeks: "по неделям",
        by_months: "по месяцам",
        week: "неделя",
        year: "год",
        filter: {
            up: "Вперед",
            back: "Назад"
        }
    },

    users: {
        title: "Пользователи",
        question_delete_user: "Удалить пользователя?",
        mail_busy: "Почта занята",
        add_user: "Добавить пользователя",
        edit_user: "Редактировать пользователя",
        search_users: "Поиск пользователей",
        roles: {
            super_user: { id: 0, displayName: "Супер пользователь" },
            admin: "Мастер",
        },
        table_header: {
            name: "Имя Фамилия",
            role: "Роль",
            org_name: "Организация",
            branch: "Филиал",
            phone: "Телефон",
            last_activity: "Последняя активность",
            start_name: "А",
            end_name: "Я"
        },
        user_form: {
            name: "Имя",
            input_name: "Введите имя",
            last_name: "Фамилия",
            input_last_name: "Введите фамилию",
            org: "Организация",
            input_org: "Не выбрана",
            branch: "Филиал",
            input_branch: "Не выбран",
            phone: "Телефон",
            input_phone: "+",
            role: "Роль",
            input_role: "Добавить роль"
        }
    },

    login: {
        title: "Вход",
        email: "Электр. почта",
        phone: "Телефон",
        password: "Введите пароль",
        continue: "Продолжить",
        sign_in: "Войти",
        version: "Версия",
        make_in: "Сделано в",
        code: "Код",
        sent_code: "Код отправлен на указанный номер"
    },

    organisations: {
        title: "Организации",
        name: "Название",
        add_organisation: "Добавить организацию",
        edit_organisation: "Редактировать организацию",
        user_form: {
            name: "Название",
            input_name: "Введите название"
        }
    },

    cancel_reasons: {
        title: "Причины отмены",
        add_cancel_reason: "Добавить причину отмены",
        add_reason: "Добавить причину",
        edit_cancel_reason: "Редактировать причину отмены",
        table_header: {
            name: "Название",
            org_name: "Организация",
        },
        user_form: {
            name: "Название",
            input_name: "Введите название",
            org: "Организация",
            input_org: "Не выбрана"
        }
    },

    sections: {
        title: "Разделы",
        add_section: "Добавить раздел",
        edit_section: "Редактировать раздел",
        table_header: {
            name: "Название",
            org_name: "Организация",
        },
        user_form: {
            name: "Название",
            input_name: "Введите название",
            org: "Организация",
            input_org: "Не выбрана"
        }
    },

    inspection: {
        title: "Проверка",
        didnt_change: "Не было изменений",
        nothing_found: "Ничего не найдено",
        comment_inspection: "Комментарий к проверке",
        files: "Файлы",
        client: "Клиент",
        phone: "Телефон",
        email: "Эл. почта",
        address: "Адрес",
        story_statuses: "История статусов",
        export: "Экспорт",
        new_inspection: "Новая проверка",
        verification_form: "Анкета проверки",
        check_list_inspection: "Чек-лист проверки",
        date: "Дата",
        load_check_list: "Загрузить чек-лист",
        recheck_question: "Изменения успешно внесены?",
        rejected: "Отклонены",
        approved: "Одобрены",
        user_form: {
            org: "Организация",
            input_org: "Не выбрана",
            branch: "Филиал",
            input_branch: "Не выбран",
            inspector: "Инженер/электромонтер",
            input_inspector: "Не выбран",
            name_inspection: "Название проверки",
            input_name_inspection: "",
            section: "Раздел",
            input_section: "Не выбран",
            template: "Выбрать из шаблонов",
            input_template: "Не выбран",
            add_question: "Добавить вопрос",
            question: "Вопрос",
            photo_for_prompt: "Фото для подсказки",
            prompt: "Подсказка",
            response_parameters: "Параметры ответа",
            variant_answer: "Вариант ответа",
            name_task: "Название задачи",
            stuff_must_add_photo: "Сотрудник должен прикрепить фото",
            stuff_must_add_comment: "Сотрудник должен прикрепить комментарий",
            multitiple_choice: "Множественный выбор",
            only_choice: "Единственный выбор",
            custom_fields: "Добавить поле",
            input_custom_fields: "Не выбрано",
            input_custom_field_item: "Не выбрано",
        }
    },

    branches: {
        title: "Филиалы",
        add_branch: "Добавить филиал",
        edit_branch: "Редактровать филиал",
        table_header: {
            name: "Название",
            org_name: "Организация"
        },
        user_form: {
            name: "Название",
            input_name: "Введите название",
            org: "Организация",
            input_org: "Не выбрана"
        }
    },

    templates: {
        title: "Шаблоны",
        add_template: "Добавить шаблон",
        create_template: "Создать шаблон",
        save_as_template: "Сохранить как шаблон",
        save_template: "Сохранение шаблона проверки",
        template_creation: "Создание шаблона",
        editing_template: "Редактирование шаблона"
    },

    common: {
        directories: "Справочники",
        not_data: "Нет данных",
        delete_question: "Удалить?",
        understandably: "Понятно",
        cancel: "Отменить",
        apply: "Применить",
        delete: "Удалить",
        save: "Сохранить",
        import: "Импортировать",
        replace: "Заменить",
        all: "Все",
        all_types: "Все типы",
        selected: "Выбрано",
        select: "Выберите",
        select_all: "Выбрать все",
        highlight_all: "Выделить все",
        select_nothing: "Выбирать нечего",
        add: "Добавить",
        select_man: "Выбран",
        not_select_man: "Не выбран",
        not_select_woman: "Не выбрана",
        not_select_it: "Не выбрано",
        recovery: "Восстановить",
        default: "По умолчанию",
        warning_fill: "Обязательно заполнить",
        yes: "Да",
        no: "Нет",
        copied: "Скопировано",
        edit: "Редактировать",
        load: "Загрузка",
        download: "Скачать",
        exit: "Выход",
        log_off: "Выйти",
        cancel_delete: "Отменить удаление",
        comment: "Комментарий",
        description: "Описание",
        add_comment: "Добавить комментарий",
        what_delete_element: "Вы действительно хотите удалить элемент?",
        what_cancel_element: "Вы действительно хотите отменить элемент?",
        input_text: "Введите текст",
        download_from_file: "Загрузить из файла",
        show_more: "Показать больше",
        show: "Показать",
        create_task_for_wrong_answer: "Создать задачу для неверного ответа",
        add_answer: "Добавить ответ",
        delete_answer: "Удалить ответ",
        time: "Время",
        prompt: "Подсказка",
        change_user: "Сменить пользователя",
        oh: "Ой..",
        period_from: "Период с",
        period_to: "Период по",
        reset_date: "Сбросить дату",
        load_yet: "Загрузить ещё"
    },



    pagination: {
        first: "Первая",
        last: "Последняя"
    },

    roles: [
        {
            id: 1,
            displayName: "Мастер"
        },
        {
            id: 2,
            displayName: "Инженер/электромонтер"
        },
        {
            id: 3,
            displayName: "Технолог"
        }
    ],

    statuses: [
        {
            id: 0,
            displayName: "Создана"
        },
        {
            id: 1,
            displayName: "В процессе"
        },
        {
            id: 2,
            displayName: "Пройдена"
        },
        {
            id: 3,
            displayName: "Отменена"
        },
        {
            id: 4,
            displayName: "Отклонена"
        }
    ],

    types: [
        {
            id: 0,
            displayName: "Первичная"
        },
        {
            id: 1,
            displayName: "Вторичная"
        }
    ],

    errors: {
        notFoundPhone: "Номер телефона не найден.",
        wrongCode: "Неправильный код подтверждения.",
        duplicatedName: "Такое название уже существует.",
        internalError: "Внутренняя ошибка сервера",
        undefinedError: "Что то пошло не так. Попробуйте перезагрузить страницу."
    }


}
