import * as actionConstants from "../../actions/ActionConstants";
import * as dirItemsConstants from "../../constants/DirItemsConstants";
import {getMyOrganizationApi} from "../api/OrganizationsApi";
import client from "../client";
import {deleteDirItemApi, getDirItemApi, getDirItemsWithKindApi} from "../api/DirItemsApi";

const cancelReasonsService = {

    async getCancelReasons(dispatch) {
        dispatch({type: actionConstants.START_LOAD_CANCEL_REASONS, payload: {}})
        try {
            const responseDirItemsApi = await getDirItemsWithKindApi(dirItemsConstants.CANCEL_REASON)
            const cancelReasons = responseDirItemsApi.data

            const organizationResponse = await getMyOrganizationApi()
            const organization = organizationResponse.data

            const cancelReasonsFiltered = cancelReasons.filter(cancelReason => cancelReason.value !== dirItemsConstants.DEFAULT_CANCEL_ADMIN)

            const result = cancelReasonsFiltered.map(item => { return {
                id: item.id,
                createdAt: new Date(item.created_at),
                displayName: item.display_name,
                organizationId: item.entity_id,
                organization: organization,
                isDeleted: item.is_deleted,
                kind: item.kind,
                updatedAt: new Date(item.updated_at),
                value: item.value
            }})

            dispatch({
                type: actionConstants.SUCCESS_LOAD_CANCEL_REASONS, payload: {
                    data: result
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_CANCEL_REASONS, payload: {
                    error: error
                }
            })
        }
    },

    async getCancelReason(dispatch, id) {
        dispatch({type: actionConstants.START_LOAD_CANCEL_REASON, payload: {}})
        try {
            const responseDirItem = await getDirItemApi(id)
            const cancelReason = responseDirItem.data

            const organizationResponse = await getMyOrganizationApi()
            let organization = organizationResponse.data

            const result = {
                id: cancelReason.id,
                createdAt: new Date(cancelReason.created_at),
                displayName: cancelReason.display_name,
                organizationId: cancelReason.entity_id,
                organization: organization,
                isDeleted: cancelReason.is_deleted,
                kind: cancelReason.kind,
                updatedAt: new Date(cancelReason.updated_at),
                value: cancelReason.value
            }

            dispatch({
                type: actionConstants.SUCCESS_LOAD_CANCEL_REASON, payload: {
                    data: result
                }
            })
        } catch(error) {
            dispatch({
                type: actionConstants.ERROR_LOAD_CANCEL_REASON, payload: {
                    error: error
                }
            })
        }
    },

    async createCancelReason(dispatch, name) {
        dispatch({type: actionConstants.START_CREATE_CANCEL_REASON, payload: {}})
        try {
            const response = await client.post(
                '/api/dir_items',
                {
                    kind: dirItemsConstants.CANCEL_REASON,
                    value: name,
                    display_name: name,
                })

            dispatch({
                type: actionConstants.SUCCESS_CREATE_CANCEL_REASON, payload: {
                    data: response.data.success
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_CREATE_CANCEL_REASON, payload: {
                    error: error
                }
            })
        }
    },

    async deleteCancelReason(dispatch, id) {
        dispatch({type: actionConstants.START_DELETE_CANCEL_REASON, payload: {}})
        try {
            const response = await deleteDirItemApi(id)

            dispatch({
                type: actionConstants.SUCCESS_DELETE_CANCEL_REASON, payload: {
                    data: response.data.success
                }
            })
        } catch (error) {
            dispatch({
                type: actionConstants.ERROR_DELETE_CANCEL_REASON, payload: {
                    error: error
                }
            })
        }
    }
}

export default cancelReasonsService;
