import React from 'react';
import PropTypes from 'prop-types'

import {Modal, Form} from "react-bootstrap";
import {connect} from "react-redux";

class DialogComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: props.isShow
        }

        this.commentRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isShow !== prevProps.isShow)
            this.setState({
                isShow: this.state.isShow
            })
    }

    render() {
        return (
            <Modal dialogClassName="modal-dialog-delete" show={this.state.isShow} onHide={this.hideModal}>
                <Modal.Header className="modal-dialog-header"><span className="modal-dialog-header-body">{this.getLocale().common.add_comment}</span></Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control
                            ref={this.commentRef}
                            as="textarea"
                            rows="8"
                            placeholder={this.getLocale().common.input_text}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="modal-dialog-delete-footer">
                    <button type="button" className="button-border-common" onClick={this.hideModal}>
                        {this.getLocale().common.cancel}
                    </button>
                    <button type="button" className="button-common" onClick={this.clickSave}>
                        {this.getLocale().common.save}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    getLocale() {
        return this.props.appState.locale
    }

    clickSave = () => {
        this.props.clickSave(this.commentRef.current.value)
        this.hideModal()
    }

    hideModal = () => {
        this.setState({
            isShow: false
        })
        this.props.onHide()
    }

}

DialogComment.propTypes = {
    isShow: PropTypes.bool,
    clickSave: PropTypes.func
}

export default connect(
    state => ({
        appState: state.app
    })
)(DialogComment);
