import Cookies from 'universal-cookie';

const axios = require('axios');

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST_URL,
    headers: {'Content-Type' : 'application/json', 'Authorization': 'Bearer ' + getAuthorization()}
});

function getAuthorization() {
    const cookies = new Cookies()
    const user = cookies.get('XrayLoggedUser')

    if (user) {
        return user.token
    }

    return ''
}

export default axiosInstance;