export const PRIMARY = 'primary'
export const SECONDARY = 'secondary'

export const TYPES = [
    {
        id: 0,
        type: PRIMARY,
    },
    {
        id: 1,
        type: SECONDARY
    }
]

export function getTypeForServer(id) {
    return TYPES.find(type => type.id === id).type
}

export function getTypeForApp(type, locale) {
    const id = TYPES.find(item => item.type === type)?.id
    if (id !== null && id !== undefined) {
        return locale.types.find(item => item.id === id)
    } else {
        return {id: -1, displayName: ''}
    }

}