import UsersService from "../network/service/UsersService";
import * as actionConstants from "../actions/ActionConstants"

const usersActions = {

    getUsers() {
        return {
            type: actionConstants.START_LOAD_USERS, payload: {}
        }
    },

    getUser(id) {
        return {
            type: actionConstants.START_LOAD_USER, payload: {
                id: id
            }
        }
    },

    createUser(name, family, phone, role, entityId, branchId, uploadId) {
        const displayName = name + ' ' + family;
        return {
            type: actionConstants.START_CREATE_USER, payload: {
                entityId: entityId,
                branchId: branchId,
                displayName: displayName,
                phone: phone,
                role: role,
                uploadId: uploadId
            }
        }
    },

    updateUser(user, name, family, phone, role, entityId, branchId, uploadId) {
        const displayName = name + ' ' + family;
        return {
            type: actionConstants.START_UPDATE_USER, payload: {
                user: user,
                entityId: entityId,
                branchId: branchId,
                displayName: displayName,
                phone: phone,
                role: role,
                uploadId: uploadId
            }
        }
    },

    deleteUser(id) {
        return {
            type: actionConstants.START_DELETE_USER, payload: {
                id: id
            }
        }
    },

    async getTokenAnotherUser(dispatch, id) {
        await UsersService.getTokenAnotherUser(dispatch, id)
        window.location.replace('/users')
    },

    getLoggedUser() {
        return {
            type: actionConstants.GET_LOGGED_USER, payload: {}
        }
    },

    getMe() {
        return {
            type: actionConstants.START_LOAD_ME, payload: {}
        }
    }
}

export default usersActions
